import { useEffect, useMemo, useState } from 'react';
import { HomeFooter, HomeHeader } from 'components';
import { useParams } from 'react-router-dom';
import { newProperties } from 'utils/properties';
import { Carousel } from 'react-responsive-carousel';
import { ChevronDownIcon, ChevronUpIcon } from '@primer/octicons-react';
import PropertyTitles from 'components/PropertyTitles';
import PropertyDetails from 'components/PropertyDetails';
import Calculator from 'components/Calculator';
import VisitSection from 'components/VisitSection';
import PropertyEnviroment from 'components/PropertyEnviroment';
import PropertyForm from 'components/PropertyForm';
import check from '../images/property/icons/check.svg';
import VisitModal from 'components/VisitModal';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import NotFoundRoute from 'routes/notFound.route';

const useProperty = (keyAccess) => {
  const [property, setProperty] = useState(null);
  const propertyDoc = useMemo(() => {
    return property?.archiveIntern?.find((doc) => {
      return (
        doc.typology === 'qualityDocumentation' || doc.typology === 'saleFlyer'
      );
    });
  }, [property]);

  useEffect(() => {
    setProperty(
      newProperties.find((p) => {
        return p.url === keyAccess;
      }),
    );
  }, [keyAccess]);

  return { property, propertyDoc };
};

const usePreview = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const handleShow2 = () => {
    setShow2((prev) => !prev);
  };

  return { show, show2, handleShow, handleShow2 };
};

const useVisitTimes = (actualDate) => {
  const timeVisit = useMemo(() => {
    if (actualDate.getHours() + 8 >= 24) {
      return `10:00 hrs`;
    } else {
      return `${actualDate.getHours() + 8}:00 hrs`;
    }
  }, [actualDate]);

  const dateVisit = useMemo(() => {
    if (actualDate.getHours() + 8 >= 24) {
      return `mañana`;
    } else {
      return `hoy`;
    }
  }, [actualDate]);

  return { timeVisit, dateVisit };
};

const useModalVisit = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  return { open, handleOpen };
};

const numberFormat = (value) =>
  new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

const NewPropertyRoute = () => {
  const { keyAccess } = useParams();
  const actualDate = new Date();
  const { property, propertyDoc } = useProperty(keyAccess);
  const { show, show2, handleShow, handleShow2 } = usePreview();
  const { timeVisit, dateVisit } = useVisitTimes(actualDate);
  const { open, handleOpen } = useModalVisit();

  if (!property) {
    return <NotFoundRoute />;
  }

  return (
    property && (
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-lg p-responsive width-full d-flex flex-justify-center flex-column">
          <HomeHeader />
          <div className="d-lg-flex flex-row d-inline-block border-top color-border-muted">
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              infiniteLoop={true}
              autoPlay={false}
              className="d-flex flex-justify-center flex-column rounded"
            >
              {property.images.map((image, i) => (
                <div key={i} style={{ height: '700px' }}>
                  <img
                    src={image.url}
                    alt={image.alt}
                    className="img-fluid rounded width-fit"
                    style={{ height: '700px', objectFit: 'cover' }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <PropertyTitles property={property} numberFormat={numberFormat} />
          <div
            className="container-sm d-flex flex-justify-center flex-column mt-5"
            style={{ gap: '.4rem' }}
          >
            {property.details.map((detail, i) => (
              <div
                key={i}
                className="d-flex flex-row flex-items-center "
                style={{ gap: '.7rem' }}
              >
                <img src={check} alt="Check icon" />
                <p className="f2">{detail}</p>
              </div>
            ))}
          </div>
          <div className="d-flex flex-justify-center pt-3">
            <button className="btn-octicon" type="button" onClick={handleShow}>
              {!show ? (
                <>
                  <ChevronDownIcon />
                  Ver más
                </>
              ) : (
                <>
                  <ChevronUpIcon />
                  Ver menos
                </>
              )}
            </button>
          </div>
          {show && (
            <>
              <PropertyDetails property={property} />
              <div
                className="d-flex flex-justify-center flex-column mt-5"
                style={{
                  gap: '.4rem',
                  width: '70%',
                  alignSelf: 'center',
                  textAlign: 'center',
                }}
              >
                {property.descriptionsPresentation.map((description, i) => (
                  <div
                    key={i}
                    className="d-flex flex-row"
                    style={{ gap: '.7rem' }}
                  >
                    <p
                      className="f3"
                      dangerouslySetInnerHTML={{ __html: description.text }}
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          {propertyDoc && (
            <div className="d-flex flex-justify-center mt-4">
              <a
                className="f4"
                style={{ fontWeight: '500' }}
                href={propertyDoc.url}
                target="_blank"
                rel="noreferrer"
              >
                Ver documentación
              </a>
            </div>
          )}
          <VisitSection
            town={property.address.town}
            place={property.place}
            handleOpen={handleOpen}
            timeVisit={timeVisit}
            dateVisit={dateVisit}
          />
          {open && (
            <VisitModal
              property={property}
              numberFormat={numberFormat}
              open={open}
              handleOpen={handleOpen}
              timeVisit={timeVisit}
              dateVisit={dateVisit}
            />
          )}
          <Calculator property={property} numberFormat={numberFormat} />
          <PropertyEnviroment property={property} />
          <div className="d-flex flex-justify-center pt-3">
            <button className="btn-octicon" type="button" onClick={handleShow2}>
              {!show2 ? (
                <>
                  <ChevronDownIcon />
                  Ver más
                </>
              ) : (
                <>
                  <ChevronUpIcon />
                  Ver menos
                </>
              )}
            </button>
          </div>
          {show2 && (
            <div
              className="d-flex flex-justify-center  flex-column mt-5"
              style={{
                gap: '.4rem',
                width: '70%',
                alignSelf: 'center',
                textAlign: 'center',
              }}
            >
              {property.environment.map((env, i) => (
                <div
                  key={i}
                  className="d-flex flex-row"
                  style={{ gap: '.7rem' }}
                >
                  <p
                    className="f3"
                    dangerouslySetInnerHTML={{ __html: env.description }}
                  />
                </div>
              ))}
            </div>
          )}
          <VisitSection
            town={property.address.town}
            place={property.place}
            handleOpen={handleOpen}
            timeVisit={timeVisit}
            dateVisit={dateVisit}
          />
          <PropertyForm />
        </div>
        <HomeFooter />
      </div>
    )
  );
};

export default NewPropertyRoute;
