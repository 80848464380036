export default function Comissions({ benefits }) {
  const beneficios = parseFloat(benefits);

  const calculateValueOfPorcentage = (porcentage) => {
    return parseFloat(beneficios * porcentage);
  };

  return (
    <div className="width-full">
      <div className="py-6 color-bg-default border-top">
        <h2 className="f0-light pb-5 text-center">
          Honorarios para esta propiedad
        </h2>
        <div className="d-flex col-12 flex-justify-center align-items-center">
          <div className="Box p-4 text-center col-4 mx-2">
            <h1 className="color-fg-subtle">Captador</h1>
            <p className="color-fg-subtle pb-5 ">20% de la comisión</p>
            <h3>{calculateValueOfPorcentage(0.2)} €</h3>
          </div>
          <div className="Box p-4 text-center col-4 mx-2">
            <h1 className="color-fg-subtle">Prescriptor</h1>
            <p className="color-fg-subtle pb-5">40% de la comisión</p>
            <h3>{calculateValueOfPorcentage(0.4)} €</h3>
          </div>
          <div className="Box p-4 text-center col-4 mx-2">
            <h1 className="color-fg-subtle">Vendedor</h1>
            <p className="color-fg-subtle pb-5">20% de la comisión</p>
            <h3>{calculateValueOfPorcentage(0.2)} €</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
