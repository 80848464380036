import { Routes, Route, Outlet } from 'react-router-dom';
import { RequireAuth, RequireVerificatedEmail } from 'components';
import LoginRoute from 'routes/login.route';
import RecoverPasswordRoute from 'routes/recover-password';
import PasswordResetRoute from 'routes/password-reset';
import SignupRoute from 'routes/buyer/signup.route';
import NotFoundRoute from 'routes/notFound.route';
import HomeRoute from 'routes/owner/home.route';
import VerifyEmailRoute from 'routes/verifyEmail.route';
import VisitsRoutes from 'routes/owner/visits';
import MyVisitsRoute from 'routes/owner/visits/my-visits.route';
import VisitOwnerView from 'routes/owner/visits/visit';
import NotificationsRoute from 'routes/owner/notifications';
import OwnerLayout from 'components/OwnerLayout/OwnerLayout';
import PropertiesLayout from 'routes/owner/properties';
import PropertiesRoute from 'routes/owner/properties/properties.route';
import MyPropertiesRoute from 'routes/owner/properties/my-properties-route';
import FavoritesRoute from 'routes/owner/properties/favorites.route';
import PropertyViewRoute from 'routes/owner/properties/view.route';

export default function OwnerRoutes() {
  return (
    <Routes>
      <Route
        element={
          <RequireAuth isRequired={false}>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/recover-password" element={<RecoverPasswordRoute />} />
        <Route path="/password-reset" element={<PasswordResetRoute />} />
        <Route path="/signup/:visit" element={<SignupRoute />} />
      </Route>
      <Route
        element={
          <RequireAuth>
            <OwnerLayout />
          </RequireAuth>
        }
      >
        <Route
          path="/verify-email"
          element={
            <RequireVerificatedEmail isRequired={false}>
              <VerifyEmailRoute />
            </RequireVerificatedEmail>
          }
        />
        <Route
          element={
            <RequireVerificatedEmail>
              <Outlet />
            </RequireVerificatedEmail>
          }
        >
          <Route path="*" element={<NotFoundRoute />} />
          <Route path="/" element={<HomeRoute />} />
          <Route path="/properties" element={<PropertiesLayout />}>
            <Route
              path="*"
              element={
                <div className="container-md width-full">
                  <div className="p-responsive py-6 color-bg-default rounded-2 border">
                    <NotFoundRoute />
                  </div>
                </div>
              }
            />
            <Route path="/properties" element={<PropertiesRoute />} />
            <Route
              path="/properties/my-properties"
              element={<MyPropertiesRoute />}
            />
            <Route path="/properties/favorites" element={<FavoritesRoute />} />
            <Route
              path="/properties/:propertyId"
              element={<PropertyViewRoute />}
            />
          </Route>

          <Route path="/visits" element={<VisitsRoutes />}>
            <Route
              path="*"
              element={
                <div className="container-md width-full">
                  <div className="p-responsive py-6 color-bg-default rounded-2 border">
                    <NotFoundRoute />
                  </div>
                </div>
              }
            />
            <Route path="/visits" element={<MyVisitsRoute />} />
            <Route path="/visits/:visitId" element={<VisitOwnerView />} />
          </Route>
          <Route path="/notifications" element={<NotificationsRoute />} />
        </Route>
      </Route>
    </Routes>
  );
}
