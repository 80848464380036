import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { CREATE_PROPERTY_PREFERENCES_MUTATION } from 'apollo/mutations';
import { useMutation } from '@apollo/client';
import { ChevronDownIcon, ChevronUpIcon } from '@primer/octicons-react';
import { Slider } from '@mui/material';
import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Typography,
} from '@mui/material';

const useFormConsult = () => {
  const { addToast } = useToasts();
  const [state, setState] = useState({
    accept: false,
    ubication: '',
    habitaciones: '',
    baños: '',
    priceRange: [0, 100000000],
    deliveredSelect: '',
    email: '',
  });

  const onCompleted = useCallback(
    (data) => {
      addToast(
        `Hemos enviado tus preferecias a un colaborador para que te contacte lo antes posible si encuentra una propiedad que se ajuste a tus necesidades.`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        setState({
          accept: false,
          ubication: '',
          habitaciones: '',
          baños: '',
          priceRange: [0, 100000000],
          deliveredSelect: '',
          email: '',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast],
  );

  const onError = useCallback(
    (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: false });
    },
    [addToast],
  );

  const [createPropertyPreferencesRequest, { loading }] = useMutation(
    CREATE_PROPERTY_PREFERENCES_MUTATION,
    {
      onCompleted: onCompleted,
      onError,
    },
  );

  const validateFields = () => {
    if (!state.accept) {
      addToast('Debes aceptar los términos y condiciones', {
        appearance: 'error',
        autoDismiss: false,
      });
      return true;
    }

    if (!state.ubication) {
      addToast('Debes ingresar una ubicación', {
        appearance: 'error',
        autoDismiss: false,
      });
      return true;
    }

    if (!state.habitaciones) {
      addToast('Debes ingresar un número de habitaciones', {
        appearance: 'error',
        autoDismiss: false,
      });
      return true;
    }

    if (!state.baños) {
      addToast('Debes ingresar un número de baños', {
        appearance: 'error',
        autoDismiss: false,
      });
      return true;
    }

    if (!state.priceRange) {
      addToast('Debes ingresar un rango de precios', {
        appearance: 'error',
        autoDismiss: false,
      });
      return true;
    }

    if (!state.deliveredSelect) {
      addToast('Debes ingresar una fecha de entrega', {
        appearance: 'error',
        autoDismiss: false,
      });
      return true;
    }

    if (!state.email) {
      addToast('Debes ingresar un email', {
        appearance: 'error',
        autoDismiss: false,
      });
      return true;
    }

    return false;
  };

  const sendConsult = (e) => {
    e.preventDefault();
    const err = validateFields();
    if (err) return;
    createPropertyPreferencesRequest({
      variables: {
        ubication: state.ubication,
        habs: state.habitaciones,
        baths: state.baños,
        priceRange: state.priceRange,
        delivery: state.deliveredSelect,
        emailClient: state.email,
      },
    });
  };

  return { state, setState, sendConsult, loading };
};

export default function PropertyForm() {
  const { state, setState, sendConsult, loading } = useFormConsult();
  const [visible, setVisible] = useState(false);

  const handleVisible = () => {
    setVisible((prev) => !prev);
  };

  return (
    <div style={{ gap: '1.5rem', backgroundColor: '#F4FDF6' }}>
      <div
        className="container-sm d-flex flex-column p-5 md-py-5"
        style={{ gap: '1rem' }}
      >
        <h3
          className="p-3 px-4 text-center container-md lh-condensed"
          style={{ fontWeight: 'bolder', fontSize: '1.8rem' }}
        >
          ¿No es lo que buscas?
          <span className="d-block">Te ayudamos a encontrarlo</span>
          <div className="d-flex flex-justify-center pt-3">
            <button
              className="btn-octicon"
              type="button"
              onClick={handleVisible}
            >
              {!visible ? (
                <h5>
                  <ChevronDownIcon />
                  Ver más
                </h5>
              ) : (
                <h5>
                  <ChevronUpIcon />
                  Ver menos
                </h5>
              )}
            </button>
          </div>
        </h3>
        <div
          style={{
            display: visible ? 'block' : 'none',
          }}
        >
          <div>
            <p className="f2">Ubicación</p>
            <input
              required
              type="text"
              className="width-full"
              onChange={(e) => {
                setState({ ...state, ubication: e.target.value });
              }}
            />
          </div>

          <div>
            <p className="f2">Habitaciones</p>
            <ToggleButtonGroup
              exclusive
              value={state.habitaciones}
              onChange={(e, format) => {
                setState({ ...state, habitaciones: format });
              }}
              sx={{ border: 'none' }}
              fullWidth
            >
              <ToggleButton
                value="indiferente"
                aria-label="indiferente"
                className="p-3 border border-gray-light"
              >
                Indiferente
              </ToggleButton>
              <ToggleButton
                value="1"
                aria-label="1"
                className="p-3 border border-gray-light"
              >
                1
              </ToggleButton>
              <ToggleButton
                value="2"
                aria-label="2"
                className="p-3 border border-gray-light"
              >
                2
              </ToggleButton>
              <ToggleButton
                value="3"
                aria-label="3"
                className="p-3 border border-gray-light"
              >
                3
              </ToggleButton>
              <ToggleButton
                value="4"
                aria-label="4"
                className="p-3 border border-gray-light"
              >
                4
              </ToggleButton>
              <ToggleButton
                value="5"
                aria-label="5"
                className="p-3 border border-gray-light"
              >
                5
              </ToggleButton>
              <ToggleButton
                value="+5"
                aria-label="+5"
                className="p-3 border border-gray-light"
              >
                +5
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div>
            <p className="f2">Baños</p>
            <ToggleButtonGroup
              fullWidth
              exclusive
              value={state.baños}
              onChange={(e, format) => {
                setState({ ...state, baños: format });
              }}
              sx={{ border: 'none' }}
            >
              <ToggleButton
                value="indiferente"
                aria-label="indiferente"
                className="p-3 border border-gray-light"
              >
                Indiferente
              </ToggleButton>
              <ToggleButton
                value="1"
                aria-label="1"
                className="p-3 border border-gray-light"
              >
                1
              </ToggleButton>
              <ToggleButton
                value="2"
                aria-label="2"
                className="p-3 border border-gray-light"
              >
                2
              </ToggleButton>
              <ToggleButton
                value="3"
                aria-label="3"
                className="p-3 border border-gray-light"
              >
                3
              </ToggleButton>
              <ToggleButton
                value="4"
                aria-label="4"
                className="p-3 border border-gray-light"
              >
                4
              </ToggleButton>
              <ToggleButton
                value="5"
                aria-label="5"
                className="p-3 border border-gray-light"
              >
                5
              </ToggleButton>
              <ToggleButton
                value="+5"
                aria-label="+5"
                className="p-3 border border-gray-light"
              >
                +5
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div>
            <p className="f2">Rango de precio</p>
            <Slider
              getAriaLabel={() => 'Rango de precio'}
              value={state.priceRange}
              onChange={(e, format) => {
                setState({ ...state, priceRange: format });
              }}
              min={0}
              max={1000001}
              step={50000}
              marks={[
                {
                  value: 0,
                  label: '0€',
                },
                {
                  value: 1000001,
                  label: '+1M€',
                },
              ]}
              valueLabelDisplay="auto"
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  variant="body2"
                  onClick={() => {
                    setState({ ...state, priceRange: 0 });
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  0€
                </Typography>
                <Typography
                  variant="body2"
                  onClick={() => {
                    setState({ ...state, priceRange: 1000001 });
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  + 1M€
                </Typography>
              </Box>{' '}
            </Slider>
          </div>

          <div>
            <p className="f2">Entrega antes de</p>
            <ToggleButtonGroup
              exclusive
              value={state.deliveredSelect}
              onChange={(e, format) => {
                setState({ ...state, deliveredSelect: format });
              }}
              sx={{ gap: '0.5rem', border: 'none' }}
            >
              <ToggleButton
                value="inmediato"
                aria-label="inmediato"
                className="p-3 border border-gray-light"
              >
                Inmediato
              </ToggleButton>
              <ToggleButton
                value="enero"
                aria-label="enero"
                className="p-3 border border-gray-light"
              >
                Ene 24
              </ToggleButton>
              <ToggleButton
                value="febrero"
                aria-label="febrero"
                className="p-3 border border-gray-light"
              >
                Feb 24
              </ToggleButton>
              <ToggleButton
                value="marzo"
                aria-label="marzo"
                className="p-3 border border-gray-light"
              >
                Mar 24
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div>
            <p className="f2">Te avisamos</p>
            <div className="d-flex">
              <input
                required
                type="email"
                placeholder="Tu email"
                onChange={(e) => {
                  setState({ ...state, email: e.target.value });
                }}
                className="width-full"
              />
              <button
                type="submit"
                className="py-2 px-5 color-bg-accent-emphasis color-fg-on-emphasis border-0"
                onClick={sendConsult}
                disabled={loading}
              >
                {loading ? 'Enviando...' : 'Enviar'}
              </button>
            </div>
          </div>

          <div className="d-flex flex-row" style={{ gap: '1rem' }}>
            <input
              type="checkbox"
              value={state.accept}
              id="formCheckbox"
              onChange={(e) => {
                setState({ ...state, accept: e.target.checked });
              }}
            />
            <label htmlFor="formCheckbox" className="text-normal">
              Acepto compartir mi email para que me comuniquen si encuentran
              alguna propiedad que cumpla mis expectativas
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
