import { Box } from '@mui/material';
import ImgNexunAI from '../../images/home/ImgNexunAI.webp';

export default function NexunAI() {
  return (
    <>
      <div className="d-flex flex-column">
        <h2
          className="gradient-text text-center text-md-left pb-2"
          style={{ fontWeight: '700', fontSize: '2rem' }}
        >
          Descubre NexunAI®
        </h2>
        <p className="f4 text-bold col-md-8 pt-2 dark-text">
          Nuestra tecnología procesa millones de datos para ofrecer información
          precisa y oportuna.
        </p>
        <p className="f4 col-md-8 dark-text">
          Adaptada a cada usuario, Nexun AI® encuentra las condiciones que
          máximizan la compra y venta de inmuebles.
        </p>
      </div>

      <img src={ImgNexunAI} alt="NexunAI®" style={{ objectFit: 'contain' }} />
    </>
  );
}
