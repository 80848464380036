import NexunLogo from 'components/NexunLogo';

export default function Loading() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
        <NexunLogo
          className="d-flex flex-justify-center anim-rotate"
          onlyIcon={true}
        />
        <label className="text-center">Cargando...</label>
      </div>
    </div>
  );
}
