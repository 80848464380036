import { NexunLogo } from 'components';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { SignInIcon, ThreeBarsIcon } from '@primer/octicons-react';

export default function HomeHeader() {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  return (
    <header
      className={
        !openMobileMenu
          ? 'Header Details px-3 px-md-3 px-lg-5 flex-wrap flex-md-nowrap color-bg-subtle'
          : 'Header Details px-3 px-md-4 px-lg-5 flex-wrap flex-md-nowrap open Details--on color-bg-subtle'
      }
    >
      {/* Desktop logo */}
      <div className="Header-item mt-n1 mb-n1 d-none d-md-flex color-bg-subtle">
        <NavLink to="/" className="no-underline color-fg-default order-1">
          <NexunLogo />
        </NavLink>
      </div>
      <div role="none" className="d-none d-md-flex mr-4" />
      {/* Mobile open menu */}
      <div className="Header-item d-md-none">
        <button
          type="button"
          className="Header-link btn-link"
          onClick={() => setOpenMobileMenu((prev) => !prev)}
        >
          <ThreeBarsIcon />
        </button>
      </div>
      {/* Desktop navigator */}
      <div className="Header-item Header-item--full flex-column flex-md-justify-end flex-md-row width-full flex-order-2 flex-md-order-none mr-0 mr-md-3 mt-3 mt-md-0 Details-content--hidden-not-important d-md-flex">
        <nav
          className="d-flex flex-column flex-md-row flex-self-stretch flex-md-self-auto"
          aria-label="Global"
        >
          <NavLink
            to="/about-us"
            className="Header-item dropdown Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
          >
            Conócenos
          </NavLink>

          <NavLink
            to="/technology"
            className="Header-item dropdown Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
          >
            Tecnología
          </NavLink>

          <a
            href="http://blog.nexun.es/"
            target="blank"
            className="Header-item dropdown Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
          >
            Blog
          </a>

          <NavLink
            to="/contact"
            className="Header-item dropdown Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
          >
            Contacta
          </NavLink>

          <div className="Header-item dropdown Header-link mt-md-n3 mb-md-n3 py-2 py-md-3 px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade">
            <details className="details-reset details-overlay">
              <summary className="Header-link">
                Servicios
                <span className="dropdown-caret ml-1" />
              </summary>
              <div
                className="dropdown-menu dropdown-menu-sw d-flex flex-column pb-0 mb-0 border-bottom-0"
                role="menu"
              >
                <NavLink
                  to="/properties"
                  className="Header-link SideNav-item py-2  px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
                >
                  Propiedades
                </NavLink>

                <NavLink
                  to="/buy"
                  className="Header-link SideNav-item py-2  px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
                >
                  Comprar
                </NavLink>

                <NavLink
                  to="/sell"
                  className="Header-link SideNav-item py-2  px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
                >
                  Vender
                </NavLink>

                <NavLink
                  to="/agent"
                  className="Header-link SideNav-item py-2  px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
                >
                  Agente
                </NavLink>

                <NavLink
                  to="/faq"
                  className="Header-link SideNav-item py-2  px-0 px-md-2 mr-0 mr-md-3 border-top border-md-top-0 border-white-fade"
                >
                  FAQ
                </NavLink>
              </div>
            </details>
          </div>
          <NavLink
            to="/login"
            className="btn btn-md btn-brand mt-3 col-12 text-center p-2 p-md-0 col-md-4 flex-self-center d-md-none"
          >
            <SignInIcon className="mr-1" /> Iniciar sesión
          </NavLink>
        </nav>
      </div>
      {/* Mobile logo */}
      <div className="Header-item Header-item--full flex-justify-center d-md-none position-relative">
        <NavLink to="/" className="no-underline color-fg-default order-1">
          <NexunLogo />
        </NavLink>
      </div>

      {/* Desktop login */}
      <div className="Header-item position-relative mr-0 flex-order-4 d-none d-md-flex ">
        <NavLink
          to="/login"
          className="d-flex flex-items-center btn btn-md btn-brand"
        >
          <SignInIcon className="mr-1" /> Iniciar sesión
        </NavLink>
      </div>
    </header>
  );
}
