import { useQuery } from '@apollo/client';
import { SearchIcon, XIcon } from '@primer/octicons-react';
import { LANGUAGES_QUERY } from 'apollo/queries';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import escapeRegex from 'utils/escapeRegex';
import { Popper } from '@mui/material';
import { useRef } from 'react';

export default function MultipleLanguageSelector({
  values,
  onSelect,
  onRemove,
  disabled,
}) {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const { data } = useQuery(LANGUAGES_QUERY, {
    variables: {
      filter: {
        _operators: {
          _id: { nin: values.map(({ _id }) => _id) },
          name:
            debouncedSearch.trim().length > 0
              ? { regex: `/${escapeRegex(debouncedSearch)}/i` }
              : undefined,
        },
      },
    },
  });

  return (
    <div className="Box">
      <div className="Box-header">
        <h3 className="Box-title">Seleccione los idiomas que habla</h3>
      </div>
      <div className="Box-body">
        {values.length === 0 && 'No hay idiomas seleccionados.'}
        {values.map((language) => (
          <div
            key={language._id}
            className="Label d-inline-flex flex-items-center m-1"
          >
            <span className="px-2">{language.flag}</span> {language.name}{' '}
            <button
              type="button"
              className="btn-octicon btn-octicon-danger"
              onClick={() => onRemove(language)}
            >
              <XIcon />
            </button>
          </div>
        ))}
      </div>
      <div className="Box-footer">
        <div className="input-group">
          <div className="position-relative">
            <div className="autocomplete-body">
              <div
                ref={anchorRef}
                className="form-control autocomplete-embedded-icon-wrap"
              >
                <SearchIcon className="color-fg-subtle" />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Buscar idioma"
                  aria-label="Buscar idioma"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  onFocus={() => setOpen(true)}
                  disabled={disabled}
                />
              </div>
              <span />
              <Popper
                open={(open || debouncedSearch.length > 0) && !!data}
                anchorEl={anchorRef.current}
                placement="top-start"
                className="autocomplete-results my-3"
                disablePortal
              >
                {data?.languages.length === 0 && (
                  <div className="py-2 px-3">No hay coincidencias.</div>
                )}
                {data?.languages.map((language) => (
                  <div
                    key={language._id}
                    role="button"
                    className="autocomplete-item py-2"
                    onClick={() => {
                      onSelect(language);
                      setSearch('');
                      setOpen(false);
                    }}
                  >
                    <span className="px-2">{language.flag}</span>{' '}
                    <span className="text-bold">{language.name}</span>
                  </div>
                ))}
              </Popper>
            </div>
          </div>
          <span className="input-group-button input-group-button--autocomplete-embedded-icon">
            <button
              type="button"
              className="btn"
              disabled={search.length === 0 && !open}
              onClick={() => {
                setSearch('');
                setOpen(false);
              }}
            >
              Cerrar
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

MultipleLanguageSelector.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      flag: PropTypes.string.isRequired,
    }),
  ),
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
