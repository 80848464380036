import { ListAgent } from 'components';
import { useState } from 'react';

export default function MyPropertiesRoute() {
  // TODO: SEARCH QUERY MY-PROPERTIES
  const [myproperties, setMyProperties] = useState([]);

  return myproperties ? (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead">
          <h2 className="Subhead-heading">Mis propiedades</h2>
          <div className="Subhead-description">
            Gestiona tus viviendas en nexun Inmobiliaria
          </div>
        </div>
        <p className="color-fg-muted text-center">
          Gestiona tus propiedades en nexun Inmobiliaria y encuentra a los
          mejores clientes.
        </p>
      </div>
    </div>
  ) : (
    <div>
      <div className="Box Box--spacious p-4">
        <div className="Subhead">
          <h2 className="Subhead-heading">Mis propiedades</h2>
          <div className="Subhead-description">
            Gestiona tus propiedades en nexun Inmobiliaria y encuentra a los
            mejores clientes.
          </div>
        </div>
      </div>
    </div>
  );
}
