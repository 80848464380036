import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth.context';

export default function RequireAuth({ children, isRequired = true }) {
  const auth = useAuth();
  const location = useLocation();

  /**
   * If the user is not logged in and the route is protected,
   * redirect to the login page.
   */
  if (isRequired && !auth.actor) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  /**
   * If the user is logged in and the route is not protected,
   * redirect to the home page.
   */
  if (!isRequired && !!auth.actor) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
