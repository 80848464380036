import { PeopleIcon } from '@primer/octicons-react';
import { useAuth } from 'context/auth.context';
import { NavLink, Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { VISITS_COUNT_FILTER_QUERY } from 'apollo/queries';
import { useEffect, useState } from 'react';

export default function VisitsRoutes() {
  const { actor } = useAuth();
  const [myVisits, setMyVisits] = useState(0);

  const counter = useQuery(VISITS_COUNT_FILTER_QUERY, {
    variables: {
      filter: {
        agent: actor._id,
      },
    },
  });

  useEffect(() => {
    setMyVisits(counter?.data?.propertyVisitsCount);
  }, [counter]);

  return (
    <>
      <div className=""></div>
      <div className="Layout Layout--flowRow-until-md Layout--sidebarPosition-start Layout--sidebarPosition-flowRow-start">
        <div className="Layout-sidebar">
          <div
            className="position-md-sticky color-bg-default rounded-2 border"
            style={{ top: 24 }}
          >
            <ul className="ActionList">
              <NavLink
                to="/visits"
                className={({ isActive }) =>
                  `ActionList-item ActionList-content ActionList-content--visual16 ${
                    isActive ? 'ActionList-item--navActive' : ''
                  }`
                }
                end
              >
                <span className="ActionList-item-visual ActionList-item-visual--leading">
                  <PeopleIcon />
                </span>
                <span className="ActionList-item-label">
                  Mis visitas
                  <span className="Counter ml-1">{myVisits}</span>
                </span>
              </NavLink>
            </ul>
          </div>
        </div>
        <div className="Layout-main flex-1">
          <div className="Layout-main">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
