import { HomeFooter, HomeHeader } from 'components';
import { NavLink } from 'react-router-dom';
import { cards } from 'utils/home-data';
export default function Greetings() {
  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle">
      <div className="container-lg p-responsive width-full">
        <HomeHeader />
      </div>
      <div className="container-lg p-responsive width-full height-full py-5">
        <div className="container align-items-center height-full">
          <div className="Box Box--spacious mx-auto text-center d-flex flex-column flex-md-row flex-items-center px-5 py-3 my-5">
            <div className="px-5">
              <h1 className="h4-mktg">¡Proceso completado!</h1>
              <p className="mt-2 h6-mktg color-fg-muted">
                Gracias por confiar en nosotros para encontrar tu nuevo hogar.
              </p>
              <p className="mt-2 h6-mktg color-fg-muted">
                En breve recibirás un correo con los detalles de tu nueva
                visita.
              </p>
              <NavLink to="/" className="btn btn-md btn-brand mt-4">
                Volver al inicio
              </NavLink>
            </div>
            <div className="col-10">
              <img src={cards[0].img} alt="feria" className="width-full" />
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
}
