import MapPlatform from 'components/MapPlatform';
import key1 from 'images/property/icons/envkey1.png';
import key2 from 'images/property/icons/envkey2.png';

export default function PropertyEnviroment({ property }) {
  return (
    <div className="mt-5 container-md">
      {property.environment.map((env, i) => {
        return (
          <div
            key={i}
            className="d-flex flex-column flex-justify-center flex-items-center"
            style={{ gap: '1.5rem' }}
          >
            <h2
              className="px-3 text-center container-md lh-condensed"
              style={{ fontWeight: 'bolder', fontSize: '2rem' }}
            >
              {env.title}
            </h2>
            <h3 className="f3 text-center text-normal">{env.subtitle}</h3>

            <div
              className="container-sm d-flex flex-column"
              style={{ gap: '1rem' }}
            >
              <div
                className="d-flex flex-row flex-items-center"
                style={{ gap: '.7rem' }}
              >
                <img src={key1} />
                <p className="f4">{env.key1}</p>
              </div>
              <div
                className="d-flex flex-row flex-items-center"
                style={{ gap: '.7rem' }}
              >
                <img src={key2} />
                <p className="f4">{env.key2}</p>
              </div>
              <div
                className="d-flex flex-row flex-items-center"
                style={{ gap: '.7rem' }}
              >
                <img src={key2} />
                <p className="f4">{env.key3}</p>
              </div>
            </div>
            <MapPlatform
              location={{
                lat: property.place.geometry.location.lat,
                lng: property.place.geometry.location.lng,
              }}
              range={5}
              zoom={16}
              height="20rem"
              width="90%"
            />

            <input
              type="search"
              placeholder="Dirección de destino"
              className="width-full border-bottom border-gray-light input-sm form-control"
            />
          </div>
        );
      })}
    </div>
  );
}
