import { useState } from 'react';
import { useAuth } from 'context/auth.context';
import { UPDATE_AGENT_MUTATION } from 'apollo/mutations';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';

export default function ContractRoute() {
  const { actor } = useAuth();
  const { addToast } = useToasts();
  const [checked, setChecked] = useState(false);
  const [signedAt] = useState(
    actor.contractSignedAt ? new Date(actor.contractSignedAt) : new Date(),
  );

  const [updateAgent, { loading }] = useMutation(UPDATE_AGENT_MUTATION, {
    refetchQueries: ['Actor'],
    onError: (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    },
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    updateAgent({
      variables: {
        filter: { _id: actor._id },
        record: {
          hasAcceptedContract: true,
          contractSignedAt: signedAt.toISOString(),
        },
      },
    });
  };

  if (!actor.isProfileFulfilled) {
    return (
      <div className="container-md width-full">
        <div className="flash flash-warn">
          Es necesario que completes los campos de tu perfil público antes de
          firmar el contrato.
        </div>
      </div>
    );
  }

  return !actor.hasAcceptedContract ? (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead mb-0">
          <h2 className="Subhead-heading">
            Contrato de Prestación de Servicios Inmobiliarios
          </h2>
        </div>

        <form onSubmit={handleFormSubmit}>
          <div className="clearfix gutter-condensed d-flex flex-shrink-0 flex-column-reverse flex-md-row">
            <div className="col-12 col-md-12">
              <div className="form-group">
                <div
                  className="text-light pb-2 border overflow-y-scroll rounded-2"
                  style={{ height: 700 }}
                >
                  <div className=" px-6 pt-3">
                    <div>
                      <p>
                        El presente Contrato se firma en Madrid el{' '}
                        {`${signedAt.getDate()}/${
                          signedAt.getMonth() + 1
                        }/${signedAt.getFullYear()}`}
                        , por las siguientes
                      </p>
                      <h3 className="text-center p-2">PARTES</h3>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center">1)</p>
                        <div className="col-11">
                          <p className="pb-1">
                            NEXUN PLATAFORMA INMOBILIARIA SL, sociedad de
                            nacionalidad española, con domicilio social en Calle
                            Balbina Valverde 21, Bajo E, inscrita en el Registro
                            Mercantil de Madrid al tomo 44018, Folio 161, Hoja
                            776454, y provista del número de identificación
                            fiscal B72476898.
                          </p>
                          <p>
                            Nexun se encuentra debidamente representada en este
                            acto por Eduardo Clavijo Pujol, de nacionalidad
                            española, mayor de edad, con domicilio profesional
                            en Calle Balbina Valverde 21, Bajo E, y titular de
                            documento nacional de identidad número 28791922Q en
                            su condición de Secretario del Consejo en virtud de
                            los poderos otorgados en la consitución de la
                            sociedad.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-4">
                        <p className="col-1 text-center">2)</p>
                        <div className="col-11 pb-3">
                          <p>
                            <strong>{actor.fullName}</strong>, de nacionalidad{' '}
                            <strong>{actor.country?.name}</strong>, mayor de
                            edad, con domicilio en{' '}
                            <strong>{actor.address?.formattedAddress}</strong> y
                            titular de documento nacional de identidad número{' '}
                            <strong>{actor.nif}</strong>( el “Agente”). Actúa en
                            su propio nombre y derecho.
                          </p>
                        </div>
                      </div>
                      <p>
                        En adelante, Nexun y el Agente podrán ser referidos
                        conjuntamente como las “Partes” y cada uno de ellos,
                        individualmente considerado, como una “Parte”.
                      </p>

                      <h3 className="text-center pb-2">EXPONEN</h3>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center">(A)</p>
                        <div className="col-11">
                          <p className="pb-1">
                            Que Nexun es titular de una plataforma digital
                            inmobiliaria que optimiza tanto la oferta como la
                            demanda de propiedades, y como tal dispone de una
                            cartera de inmuebles que gestiona (la “Plataforma”).
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center">(B)</p>
                        <div className="col-11">
                          <p className="pb-1">
                            Que el Agente desarrolla una parte de su actividad
                            en el mercado inmobiliario, mediante la prestación
                            de servicios de gestión inmobiliaria.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center">(C)</p>
                        <div className="col-11">
                          <p className="pb-1">
                            Que, en consecuencia, el Agente tiene experiencia y
                            competencias en la prestación de servicios de
                            gestión inmobiliaria y es capaz de prestar los
                            mismos de conformidad con las disposiciones del
                            presente Contrato y, sobre esa base, Nexun ha
                            decidido contratar al Agente para que preste los
                            servicios requeridos en los términos del presente
                            Contrato.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-4">
                        <p className="col-1 text-center">(D)</p>
                        <div className="col-11">
                          <p className="pb-1">
                            Que, por lo expuesto, las Partes, reconociéndose
                            mutuamente la capacidad legal necesaria, acuerdan
                            celebrar el presente Contrato con arreglo a las
                            siguientes
                          </p>
                        </div>
                      </div>
                      <h3 className="text-center pb-2">CLÁUSULAS</h3>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">1</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">OBJETO DEL CONTRATO</p>
                          <p className="pb-2">
                            El objeto del presente Contrato es la prestación de
                            Servicios (tal y como este término se define en la
                            Cláusula 2) por parte del Agente a favor de Nexun.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">2</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">SERVICIOS</p>
                          <p className="pb-2">
                            El alcance de los servicios a prestar en virtud del
                            presente Contrato, según lo acordado por las Partes,
                            es el siguiente (los “Servicios”):
                          </p>
                          <div className="d-flex flex-column pb-2">
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">a)</p>
                              <p className="col-11">
                                Promocionar la venta de los inmuebles publicados
                                en la Plataforma, tanto de aquellas que que le
                                sean asignados en función de la Plataforma como
                                en general de cualquier Propiedad disponible a
                                través de la misma.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">b)</p>
                              <p className="col-11">
                                Captar nuevos inmuebles a incluir en la
                                Plataforma para su venta.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">c)</p>
                              <p className="col-11">
                                Realizar acciones publicitarias a través de los
                                medios que el Agente considere más adecuados al
                                objeto de localizar a potenciales compradores y
                                vendedores de inmuebles.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">d)</p>
                              <p className="col-11">
                                Mostrar los inmuebles a los potenciales
                                compradores que le sean asignados a través de la
                                Plataforma, haciéndoles conocer las condiciones
                                de negociación, así como redactar las
                                correspondientes hojas de control de visitas con
                                todos los datos identificativos de los
                                potenciales compradores que visiten los
                                inmuebles, las cuales deberá remitir a Nexun.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">e)</p>
                              <p className="col-11">
                                Brindar a los potenciales compradores toda la
                                información que estos requieran sobre los
                                inmuebles.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">f)</p>
                              <p className="col-11">
                                Presentar a Nexun todas las ofertas que reciba
                                sobre los inmuebles (se ajusten o no a las
                                condiciones preestablecidas), siendo Nexun quien
                                en su caso tramite dichas ofertas a los
                                propietarios de los inmuebles.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">g)</p>
                              <p className="col-11">
                                Colaborar con Nexun hasta el otorgamiento de la
                                escritura pública de compraventa del inmueble, o
                                hasta la firma del contrato privado de
                                compraventa en caso de que la venta no se
                                formalice en escritura pública.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">h)</p>
                              <p className="col-11">
                                Guardar discreción de las gestiones confiadas e
                                instrucciones recibidas de Nexun, así como
                                realizar su labor con la diligencia profesional
                                debida.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">i)</p>
                              <p className="col-11">
                                Mantener periódicamente informado a Nexun de la
                                marcha de su actividad bajo el presente
                                Contrato.
                              </p>
                            </div>
                            <div className="d-flex col-12 pb-2">
                              <p className="col-1 text-start">j)</p>
                              <p className="col-11">
                                Cumplir los protocolos establecidos por Nexun,
                                seguir los cursos de formación obligatoria y
                                mantener actualizada la información para el
                                mejor funcionamiento de la misma.
                              </p>
                            </div>
                          </div>
                          <p className="pb-2">
                            La prestación de los Servicios incluirá la
                            realización de cualesquiera actividades, funciones y
                            servicios que sean razonablemente necesarios para
                            poder prestar los Servicios tal y como se establecen
                            en el presente Contrato.
                          </p>
                          <p className="pb-2">
                            Toda asignación que reciba un Agente se hará en
                            beneficio de Nexun y conforme a la información
                            recibida por este, no pudiendo el Agente hacer uso
                            de la misma sin el consentimiento de Nexun.
                          </p>
                          <p className="pb-2">
                            El Agente tendrá exclusividad sobre cada operación
                            asignada por un tiempo de 30 días extensibles a 30
                            más en 2 prórrogas consecutivas de 15 días cada una
                            bajo acción comercial justificada.
                          </p>
                          <p className="pb-2">
                            En todo caso, al presente Contrato se anexarán los
                            sucesivos encargos de venta que sean asignados en
                            cada caso al Agente por la Plataforma y en donde se
                            detallará el listado de precios y honorarios,
                            documentación comercial del Inmueble en cuestión y
                            demás medios necesarios para el ejercicio de su
                            actividad de promoción (en adelante el “Anexo de la
                            operación”) y que formará parte indivisible de este
                            Contrato.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">3</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">
                            PRECIO DE VENTA Y SITUACIÓN DEL INMUEBLE
                          </p>
                          <p className="pb-2">
                            El precio de venta de los inmuebles así como la
                            situación de los mismos serán las previamente
                            comunicadas por Nexun al Agente. El precio de venta
                            comunicado por Nexun lo será sólo a efectos de
                            promocionar y publicitar el inmueble en cuestión.
                            Este precio sólo podrá modificarse mediante
                            instrucciones por escrito de Nexun.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">4</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">HONORARIOS Y PAGO</p>
                          <p className="pb-2">
                            El Agente tendrá derecho al cobro de sus honorarios
                            cuando la compraventa del inmueble con vendedor o
                            con comprador localizado por éste y como
                            consecuencia de sus gestiones se acabe
                            perfeccionando mediante la firma de la
                            correspondiente escritura pública de compraventa (o
                            del contrato privado de compraventa en caso de que
                            la venta no se formalice en escritura pública), y
                            aunque la operación se acabase perfeccionando aun
                            después de finalizada la duración del presente
                            Contrato.
                          </p>
                          <p className="pb-2">
                            En todo caso el devengo de los honorarios del Agente
                            estará supeditado al previo pago por el vendedor del
                            inmueble de los honorarios devengados a favor de
                            Nexun.
                          </p>
                          <p className="pb-2">
                            El Agente no tendrá derecho al cobro de los
                            honorarios en caso de que la venta de los inmuebles
                            concluida con su intermediación no haya sido
                            totalmente ejecutada por causas o circunstancias
                            totalmente ajenas a Nexun.
                          </p>
                          <p className="pb-2">
                            El Agente no tendrá derecho al reembolso de los
                            gastos que le haya originado el ejercicio de su
                            actividad bajo el presente Contrato.
                          </p>
                          <p className="pb-2">
                            Los honorarios serán facturados por el Agente dentro
                            de los quince (15) días (Hábiles,
                            naturales?)naturales siguientes al cobro por Nexun
                            de los honorarios devengados por la compraventa del
                            inmueble, que debe enviar la factura a Nexun a la
                            siguiente dirección de correo electrónico:{' '}
                            {actor.email}.
                          </p>
                          <p className="pb-2">
                            La factura emitida por el Agente deberá ser pagada
                            por Nexun en la cuenta bancaria indicada por el
                            Agente a Nexun a tales efectos en un plazo máximo de
                            veinticuatro (24) horas naturales desde su
                            recepción. Revisar lo de los 15 días, quizá es poco
                            si luego hay mucho volumen de negocio.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">5</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">DURACIÓN</p>
                          <p className="pb-2">
                            El presente Contrato se celebra por tiempo
                            indefinido, pudiendo cualquiera de las Partes dar
                            por finalizado el mismo en cualquier momento,
                            comunicándolo a la otra Parte por escrito con una
                            antelación de veinte (20) días. Si bien, el Agente
                            se compromete a continuar prestando los Servicios
                            respecto de las operaciones que a la fecha de
                            resolución del presente Contrato se encuentren
                            pendientes de perfeccionamiento o ejecución.
                          </p>
                          <p className="pb-2">
                            A la fecha de extinción o resolución del presente
                            Contrato por cualquier causa, los clientes y la
                            cartera de inmuebles permanecerán siendo propiedad
                            de Nexun, no pudiendo por tanto el Agente hacer uso
                            de tales clientes o inmuebles una vez finalizado el
                            presente Contrato.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">6</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">USO DE LA PLATAFORMA</p>
                          <p className="pb-2">
                            A los efectos de que el Agente pueda prestar los
                            Servicios de manera adecuada, el Agente se obliga a
                            la firma del presente Contrato, facilitando a Nexun
                            toda la información y documentación requerida para
                            ello.
                          </p>
                          <p className="pb-2">
                            El Agente garantiza que toda la información que
                            incluya en la Plataforma será veraz, exacta e
                            íntegra, comprometiéndose a mantener los datos
                            actualizados en todo momento. El Agente será el
                            único responsable de la veracidad de los datos
                            facilitados, por lo que responderá en exclusiva ante
                            cualquier responsabilidad o daño que pudiera
                            derivarse al respecto.
                          </p>
                          <p className="pb-2">
                            Asimismo, el Agente será completamente responsable
                            del acceso y correcto uso de su perfil y demás
                            contenidos de la Plataforma con sujeción a la
                            legalidad vigente y al presente Contrato.
                          </p>
                          <p className="pb-2">
                            El Agente se abstendrá de usar su perfil y el resto
                            de los contenidos de la Plataforma, incluidos los
                            datos personales de propietarios y clientes, con
                            fines o efectos ilícitos y que sean lesivos de los
                            derechos e intereses de terceros, o que de cualquier
                            forma puedan dañar, inutilizar, afectar o deteriorar
                            la Plataforma, sus contenidos y sus servicios,
                            eximiendo a Nexun de toda responsabilidad, daño o
                            perjuicio que pudiera derivarse de dicho acceso o
                            uso ilícito
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">7</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">PUBLICIDAD</p>
                          <p className="pb-2">
                            Nexun autoriza al Agente a que el Inmueble se
                            publicite través de los canales que considere
                            siempre que se respeten las restricciones que
                            imponga la propia Nexun o directamente por el
                            propietario para la canalización de las promociones.
                          </p>
                          <p className="pb-2">
                            El Agente podrá así realizar cualquier actuación en
                            materia de marketing o publicidad online que
                            entienda necesaria a los fines del presente
                            contrato.
                          </p>
                          <p className="pb-2">
                            Los costes e inversión en materia de marketing y
                            publicidad serán asumidos por el Agente.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">8</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">
                            PROPIEDAD INTELECTUAL E INDUSTRIAL
                          </p>
                          <p className="pb-2">
                            Nexun es el titular o licenciatario de todos los
                            derechos de propiedad intelectual e industrial
                            incluidos en la Plataforma, así como sobre los
                            contenidos accesibles a través de la misma. Los
                            derechos de propiedad intelectual de la Plataforma,
                            así como los textos, imágenes, fotografías, diseño
                            gráfico, estructura de navegación, información y
                            demás contenidos que se recogen en ella son
                            titularidad de Nexun, a quien corresponde el
                            ejercicio exclusivo de los derechos de explotación
                            de los mismos en cualquier forma y, en especial, los
                            derechos de reproducción, distribución, comunicación
                            pública y transformación, de conformidad con la
                            legislación española de derechos de propiedad
                            intelectual e industrial. La autorización al Agente
                            para el acceso a la Plataforma no supone renuncia,
                            transmisión, licencia o cesión total ni parcial
                            sobre derechos de propiedad intelectual o industrial
                            por parte de Nexun.
                          </p>
                          <p className="pb-2">
                            El Agente no podrá en ningún caso suprimir, eludir o
                            manipular de ningún modo los contenidos de la
                            Plataforma. Asimismo, tendrá prohibido modificar,
                            copiar, reutilizar, explotar, reproducir, comunicar
                            públicamente, hacer segundas o posteriores
                            publicaciones, cargar archivos, enviar por correo,
                            transmitir, usar, tratar o distribuir de cualquier
                            forma la totalidad o parte de los contenidos
                            incluidos en la Plataforma para propósitos ajenos al
                            cumplimiento del presente Contrato, si no se cuenta
                            con la autorización expresa y por escrito de Nexun
                            o, en su caso, del titular de los derechos a que
                            corresponda.
                          </p>
                          <p className="pb-2">
                            El Agente garantiza que ostentará los derechos
                            necesarios para compartir cualquier tipo de
                            contenido a través de la Plataforma, quedando exento
                            Nexun de cualquier responsabilidad sobre el
                            contenido y legalidad de la información ofrecida. La
                            facilitación de contenidos por parte del Agente a
                            través de la Plataforma supondrá la cesión a Nexun,
                            con carácter gratuito, y con la máxima amplitud
                            permitida por la legislación igente, de los derechos
                            de explotación de propiedad intelectual o industrial
                            derivados de tales contenidos.
                          </p>
                          <p className="pb-2">
                            El Agente se obliga a usar los contenidos de forma
                            diligente, correcta y lícita y, en particular, se
                            compromete a abstenerse de suprimir, eludir o
                            manipular el &quot;copyright&quot; y demás datos
                            identificativos de los derechos de Nexun o de sus
                            titulares incorporados a los contenidos, así como a
                            los dispositivos técnicos de protección o
                            cualesquiera mecanismos de información que pudieren
                            incluirse en los contenidos.
                          </p>
                          <p className="pb-2">
                            Nexun informa al Agente que no concede licencia o
                            autorización implícita alguna sobre los derechos de
                            propiedad intelectual y/o industrial o sobre
                            cualquier otro derecho o propiedad relacionada,
                            directa o indirectamente, con los contenidos
                            incluidos en la Plataforma.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">9</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">PROTECCIÓN DE DATOS</p>
                          <p className="pb-2">
                            Las Partes de este Contrato conocen y se obligan a
                            cumplir el Reglamento (UE) 2016/679 del Parlamento
                            Europeo y del Consejo, de 27 de abril de 2016 (RGPD)
                            relativo a la protección de las personas físicas en
                            lo que respecta al tratamiento de datos personales,
                            así como la Ley Orgánica 3/2018, de 5 de diciembre,
                            de Protección de Datos Personales y garantía de los
                            derechos digitales.
                          </p>
                          <p className="pb-2">
                            De esta forma, las Partes son conscientes de que
                            mediante la firma de este Contrato consienten que
                            sus datos personales recogidos en el presente
                            Contrato, así como aquellos que se pudiesen recoger
                            en el futuro para poder dar cumplimiento o una
                            correcta ejecución de este mismo, podrían ser
                            incorporados por la otra Parte a su propio fichero
                            automatizado o no de recogida de datos con el fin de
                            ejecutar correctamente la relación contractual y,
                            eventualmente, para una gestión administrativa y/o
                            comercial.
                          </p>
                          <p className="pb-2">
                            En todo caso, las Partes se comprometen a que estos
                            datos personales no serán comunicados en ningún caso
                            a terceros, aunque, si se diese el caso de que fuera
                            a realizarse algún tipo de comunicación de datos
                            personales, se comprometen siempre y de forma
                            previa, a solicitar el consentimiento expreso,
                            informado e inequívoco de la Parte que es titular de
                            dichos datos de carácter personal.
                          </p>
                          <p className="pb-2">
                            De esta Cláusula no resulta ninguna limitación o
                            restricción para las Partes en cuanto al ejercicio
                            de los derechos de acceso, rectificación, supresión,
                            limitación, portabilidad u oposición con los que
                            pudieran contar.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">10</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">CONFIDENCIALIDAD</p>
                          <p className="pb-2">
                            Cada una de las Partes tratará de forma confidencial
                            y no divulgará ni utilizará para fines distintos de
                            los aquí acordados ninguna información recibida u
                            obtenida como consecuencia de la celebración y/o
                            ejecución del presente Contrato. Esta obligación de
                            confidencialidad permanecerá en vigor durante la
                            vigencia del presente Contrato y durante los cinco
                            (5) años siguientes a su expiración o resolución por
                            cualquier motivo. La obligación de confidencialidad
                            no aplicará en aquellos casos en que la información
                            confidencial deba ser revelada por ley o por una
                            orden judicial o administrativa, en cuyo caso la
                            Parte obligada a revelar la información confidencial
                            deberá notificarlo a la otra Parte tan pronto como
                            sea posible.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">11</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">CESIÓN</p>
                          <p className="pb-2">
                            Salvo disposición expresa en otro sentido prevista
                            en el presente Contrato, ninguna de las Partes podrá
                            ceder los derechos y obligaciones resultantes del
                            presente Contrato sin el previo consentimiento por
                            escrito de la otra Parte.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">12</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">NULIDAD PARCIAL</p>
                          <p className="pb-2">
                            En caso de que cualquier disposición del presente
                            Contrato fuera declarada total o parciamente nula,
                            inválida o ineficaz, dicha disposición se aplicará
                            con cualquier supresión o modificación que proceda a
                            efectos de asegurar su legalidad, validez y eficacia
                            y dar efecto al acuerdo comercial de las Partes.
                          </p>
                          <p className="pb-2">
                            En la medida en que no fuera posible suprimir o
                            modificar la disposición afectada total o
                            parcialmente, de conformidad con lo dispuesto en el
                            párrafo anterior, se entenderá que dicha disposición
                            (o, en su caso, aquella parte de la misma que
                            hubiera sido declarada nula, inválida o ineficaz) no
                            forma parte del presente Contrato, sin que ello
                            perjudique la legalidad, validez o eficacia de las
                            restantes disposiciones del presente Contrato, con
                            sujeción a cualquier supresión o modificación
                            realizada al amparo del párrafo anterior.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">13</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">
                            INTEGRIDAD DEL CONTRATO
                          </p>
                          <p className="pb-2">
                            El presente Contrato constituye la totalidad del
                            acuerdo entre las Partes con respecto a su objeto a
                            esta fecha, y sustituye y reemplaza a cualesquiera
                            acuerdos, negociaciones y compromisos, en cada caso
                            orales o escritos, que pudieran haber existido
                            anteriormente entre todas las Partes y cualesquiera
                            otras partes de tales instrumentos, en relación con
                            las cuestiones que se regulan en el presente
                            Contrato.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2">
                        <p className="col-1 text-center fw-bold">14</p>
                        <div className="col-11">
                          <p className="pb-1 fw-bold">
                            LEY APLICABLE Y SOMETIMIENTO A JURISDICCIÓN
                          </p>
                          <p className="pb-2">
                            El presente Contrato, junto con cualesquiera
                            obligaciones extracontractuales que pudieran
                            derivarse de o surgir en relación con el mismo, se
                            regirá e interpretará de conformidad con el derecho
                            común español.
                          </p>
                          <p className="pb-2">
                            Las Partes se someten con carácter exclusivo a la
                            jurisdicción de los juzgados y tribunales de la
                            ciudad de Madrid (España) para la resolución de
                            cualesquiera controversias que pudieran derivarse
                            del Contrato o en relación con el mismo (incluyendo
                            su propia validez, la validez de esta Cláusula, así
                            como cualesquiera obligaciones extracontractuales
                            que pudieran derivarse del mismo).
                          </p>
                        </div>
                      </div>
                      <div className="d-flex p-2">
                        <p className="pb-2">
                          <strong>Y en prueba de conformidad,</strong> las
                          Partes suscriben el presente Contrato en dos
                          ejemplares, en el lugar y fecha señalados en el
                          encabezamiento.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-checkbox">
                  <label htmlFor="checkbox" className="cursor-pointer">
                    <input
                      id="checkbox"
                      type="checkbox"
                      aria-describedby="help-text-for-checkbox"
                      checked={checked}
                      onChange={() => setChecked((prev) => !prev)}
                      required
                    />
                    Estoy de acuerdo con el contrato recibido.
                    <p className="note" id="help-text-for-checkbox">
                      Con la firma del presente contrato aceptas las condiciones
                      de uso de la plataforma. Si no estás de acuerdo con las
                      condiciones de uso, no firmes el contrato. Si tienes
                      alguna duda, puedes contactar con nosotros en el correo
                      electrónico info@nexun.es.
                    </p>
                  </label>
                </div>
                <div className="d-flex flex-justify-end">
                  <button
                    type="submit"
                    className="btn btn-primary btn-md text-center"
                    disabled={!checked || loading}
                  >
                    Firmar contrato
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead">
          <h2 className="Subhead-heading">
            Contrato de Prestación de Servicios Inmobiliarios
          </h2>
        </div>
        <p>
          El contrato fue firmado y validado por ambas partes el día{' '}
          {`${signedAt.getDate()}/${
            signedAt.getMonth() + 1
          }/${signedAt.getFullYear()}`}
          .
        </p>
        <div className="clearfix gutter-condensed d-flex flex-shrink-0 flex-column-reverse flex-md-row">
          <div className="col-12 col-md-12">
            <div className="form-group">
              <div
                className="border rounded-2 overflow-y-scroll px-6 py-3"
                style={{ height: 500 }}
              >
                {/*  TODO: EL CONTRATO FIRMADO APARECERÁ AQUÍ */}
                <div className=" px-6 pt-3">
                  <div>
                    <p>
                      El presente Contrato se firma en Madrid el{' '}
                      {`${signedAt.getDate()}/${
                        signedAt.getMonth() + 1
                      }/${signedAt.getFullYear()}`}
                      , por las siguientes
                    </p>
                    <h3 className="text-center p-2">PARTES</h3>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center">1)</p>
                      <div className="col-11">
                        <p className="pb-1">
                          NEXUN PLATAFORMA INMOBILIARIA SL, sociedad de
                          nacionalidad española, con domicilio social en Calle
                          Balbina Valverde 21, Bajo E, inscrita en el Registro
                          Mercantil de Madrid al tomo 44018, Folio 161, Hoja
                          776454, y provista del número de identificación fiscal
                          B72476898.
                        </p>
                        <p>
                          Nexun se encuentra debidamente representada en este
                          acto por Eduardo Clavijo Pujol, de nacionalidad
                          española, mayor de edad, con domicilio profesional en
                          Calle Balbina Valverde 21, Bajo E, y titular de
                          documento nacional de identidad número 28791922Q en su
                          condición de Secretario del Consejo en virtud de los
                          poderos otorgados en la consitución de la sociedad.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-4">
                      <p className="col-1 text-center">2)</p>
                      <div className="col-11 pb-3">
                        <p>
                          <strong>{actor.fullName}</strong>, de nacionalidad{' '}
                          <strong>{actor.country?.name}</strong>, mayor de edad,
                          con domicilio en{' '}
                          <strong>{actor.address?.formattedAddress}</strong> y
                          titular de documento nacional de identidad número{' '}
                          <strong>{actor.nif}</strong>( el “Agente”). Actúa en
                          su propio nombre y derecho.
                        </p>
                      </div>
                    </div>
                    <p>
                      En adelante, Nexun y el Agente podrán ser referidos
                      conjuntamente como las “Partes” y cada uno de ellos,
                      individualmente considerado, como una “Parte”.
                    </p>

                    <h3 className="text-center pb-2">EXPONEN</h3>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center">(A)</p>
                      <div className="col-11">
                        <p className="pb-1">
                          Que Nexun es titular de una plataforma digital
                          inmobiliaria que optimiza tanto la oferta como la
                          demanda de propiedades, y como tal dispone de una
                          cartera de inmuebles que gestiona (la “Plataforma”).
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center">(B)</p>
                      <div className="col-11">
                        <p className="pb-1">
                          Que el Agente desarrolla una parte de su actividad en
                          el mercado inmobiliario, mediante la prestación de
                          servicios de gestión inmobiliaria.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center">(C)</p>
                      <div className="col-11">
                        <p className="pb-1">
                          Que, en consecuencia, el Agente tiene experiencia y
                          competencias en la prestación de servicios de gestión
                          inmobiliaria y es capaz de prestar los mismos de
                          conformidad con las disposiciones del presente
                          Contrato y, sobre esa base, Nexun ha decidido
                          contratar al Agente para que preste los servicios
                          requeridos en los términos del presente Contrato.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-4">
                      <p className="col-1 text-center">(D)</p>
                      <div className="col-11">
                        <p className="pb-1">
                          Que, por lo expuesto, las Partes, reconociéndose
                          mutuamente la capacidad legal necesaria, acuerdan
                          celebrar el presente Contrato con arreglo a las
                          siguientes
                        </p>
                      </div>
                    </div>
                    <h3 className="text-center pb-2">CLÁUSULAS</h3>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">1</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">OBJETO DEL CONTRATO</p>
                        <p className="pb-2">
                          El objeto del presente Contrato es la prestación de
                          Servicios (tal y como este término se define en la
                          Cláusula 2) por parte del Agente a favor de Nexun.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">2</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">SERVICIOS</p>
                        <p className="pb-2">
                          El alcance de los servicios a prestar en virtud del
                          presente Contrato, según lo acordado por las Partes,
                          es el siguiente (los “Servicios”):
                        </p>
                        <div className="d-flex flex-column pb-2">
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">a)</p>
                            <p className="col-11">
                              Promocionar la venta de los inmuebles publicados
                              en la Plataforma, tanto de aquellas que que le
                              sean asignados en función de la Plataforma como en
                              general de cualquier Propiedad disponible a través
                              de la misma.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">b)</p>
                            <p className="col-11">
                              Captar nuevos inmuebles a incluir en la Plataforma
                              para su venta.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">c)</p>
                            <p className="col-11">
                              Realizar acciones publicitarias a través de los
                              medios que el Agente considere más adecuados al
                              objeto de localizar a potenciales compradores y
                              vendedores de inmuebles.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">d)</p>
                            <p className="col-11">
                              Mostrar los inmuebles a los potenciales
                              compradores que le sean asignados a través de la
                              Plataforma, haciéndoles conocer las condiciones de
                              negociación, así como redactar las
                              correspondientes hojas de control de visitas con
                              todos los datos identificativos de los potenciales
                              compradores que visiten los inmuebles, las cuales
                              deberá remitir a Nexun.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">e)</p>
                            <p className="col-11">
                              Brindar a los potenciales compradores toda la
                              información que estos requieran sobre los
                              inmuebles.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">f)</p>
                            <p className="col-11">
                              Presentar a Nexun todas las ofertas que reciba
                              sobre los inmuebles (se ajusten o no a las
                              condiciones preestablecidas), siendo Nexun quien
                              en su caso tramite dichas ofertas a los
                              propietarios de los inmuebles.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">g)</p>
                            <p className="col-11">
                              Colaborar con Nexun hasta el otorgamiento de la
                              escritura pública de compraventa del inmueble, o
                              hasta la firma del contrato privado de compraventa
                              en caso de que la venta no se formalice en
                              escritura pública.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">h)</p>
                            <p className="col-11">
                              Guardar discreción de las gestiones confiadas e
                              instrucciones recibidas de Nexun, así como
                              realizar su labor con la diligencia profesional
                              debida.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">i)</p>
                            <p className="col-11">
                              Mantener periódicamente informado a Nexun de la
                              marcha de su actividad bajo el presente Contrato.
                            </p>
                          </div>
                          <div className="d-flex col-12 pb-2">
                            <p className="col-1 text-start">j)</p>
                            <p className="col-11">
                              Cumplir los protocolos establecidos por Nexun,
                              seguir los cursos de formación obligatoria y
                              mantener actualizada la información para el mejor
                              funcionamiento de la misma.
                            </p>
                          </div>
                        </div>
                        <p className="pb-2">
                          La prestación de los Servicios incluirá la realización
                          de cualesquiera actividades, funciones y servicios que
                          sean razonablemente necesarios para poder prestar los
                          Servicios tal y como se establecen en el presente
                          Contrato.
                        </p>
                        <p className="pb-2">
                          Toda asignación que reciba un Agente se hará en
                          beneficio de Nexun y conforme a la información
                          recibida por este, no pudiendo el Agente hacer uso de
                          la misma sin el consentimiento de Nexun.
                        </p>
                        <p className="pb-2">
                          El Agente tendrá exclusividad sobre cada operación
                          asignada por un tiempo de 30 días extensibles a 30 más
                          en 2 prórrogas consecutivas de 15 días cada una bajo
                          acción comercial justificada.
                        </p>
                        <p className="pb-2">
                          En todo caso, al presente Contrato se anexarán los
                          sucesivos encargos de venta que sean asignados en cada
                          caso al Agente por la Plataforma y en donde se
                          detallará el listado de precios y honorarios,
                          documentación comercial del Inmueble en cuestión y
                          demás medios necesarios para el ejercicio de su
                          actividad de promoción (en adelante el “Anexo de la
                          operación”) y que formará parte indivisible de este
                          Contrato.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">3</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">
                          PRECIO DE VENTA Y SITUACIÓN DEL INMUEBLE
                        </p>
                        <p className="pb-2">
                          El precio de venta de los inmuebles así como la
                          situación de los mismos serán las previamente
                          comunicadas por Nexun al Agente. El precio de venta
                          comunicado por Nexun lo será sólo a efectos de
                          promocionar y publicitar el inmueble en cuestión. Este
                          precio sólo podrá modificarse mediante instrucciones
                          por escrito de Nexun.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">4</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">HONORARIOS Y PAGO</p>
                        <p className="pb-2">
                          El Agente tendrá derecho al cobro de sus honorarios
                          cuando la compraventa del inmueble con vendedor o con
                          comprador localizado por éste y como consecuencia de
                          sus gestiones se acabe perfeccionando mediante la
                          firma de la correspondiente escritura pública de
                          compraventa (o del contrato privado de compraventa en
                          caso de que la venta no se formalice en escritura
                          pública), y aunque la operación se acabase
                          perfeccionando aun después de finalizada la duración
                          del presente Contrato.
                        </p>
                        <p className="pb-2">
                          En todo caso el devengo de los honorarios del Agente
                          estará supeditado al previo pago por el vendedor del
                          inmueble de los honorarios devengados a favor de
                          Nexun.
                        </p>
                        <p className="pb-2">
                          El Agente no tendrá derecho al cobro de los honorarios
                          en caso de que la venta de los inmuebles concluida con
                          su intermediación no haya sido totalmente ejecutada
                          por causas o circunstancias totalmente ajenas a Nexun.
                        </p>
                        <p className="pb-2">
                          El Agente no tendrá derecho al reembolso de los gastos
                          que le haya originado el ejercicio de su actividad
                          bajo el presente Contrato.
                        </p>
                        <p className="pb-2">
                          Los honorarios serán facturados por el Agente dentro
                          de los quince (15) días (Hábiles, naturales?)naturales
                          siguientes al cobro por Nexun de los honorarios
                          devengados por la compraventa del inmueble, que debe
                          enviar la factura a Nexun a la siguiente dirección de
                          correo electrónico: {actor.email}.
                        </p>
                        <p className="pb-2">
                          La factura emitida por el Agente deberá ser pagada por
                          Nexun en la cuenta bancaria indicada por el Agente a
                          Nexun a tales efectos en un plazo máximo de
                          veinticuatro (24) horas naturales desde su recepción.
                          Revisar lo de los 15 días, quizá es poco si luego hay
                          mucho volumen de negocio.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">5</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">DURACIÓN</p>
                        <p className="pb-2">
                          El presente Contrato se celebra por tiempo indefinido,
                          pudiendo cualquiera de las Partes dar por finalizado
                          el mismo en cualquier momento, comunicándolo a la otra
                          Parte por escrito con una antelación de veinte (20)
                          días. Si bien, el Agente se compromete a continuar
                          prestando los Servicios respecto de las operaciones
                          que a la fecha de resolución del presente Contrato se
                          encuentren pendientes de perfeccionamiento o
                          ejecución.
                        </p>
                        <p className="pb-2">
                          A la fecha de extinción o resolución del presente
                          Contrato por cualquier causa, los clientes y la
                          cartera de inmuebles permanecerán siendo propiedad de
                          Nexun, no pudiendo por tanto el Agente hacer uso de
                          tales clientes o inmuebles una vez finalizado el
                          presente Contrato.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">6</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">USO DE LA PLATAFORMA</p>
                        <p className="pb-2">
                          A los efectos de que el Agente pueda prestar los
                          Servicios de manera adecuada, el Agente se obliga a la
                          firma del presente Contrato, facilitando a Nexun toda
                          la información y documentación requerida para ello.
                        </p>
                        <p className="pb-2">
                          El Agente garantiza que toda la información que
                          incluya en la Plataforma será veraz, exacta e íntegra,
                          comprometiéndose a mantener los datos actualizados en
                          todo momento. El Agente será el único responsable de
                          la veracidad de los datos facilitados, por lo que
                          responderá en exclusiva ante cualquier responsabilidad
                          o daño que pudiera derivarse al respecto.
                        </p>
                        <p className="pb-2">
                          Asimismo, el Agente será completamente responsable del
                          acceso y correcto uso de su perfil y demás contenidos
                          de la Plataforma con sujeción a la legalidad vigente y
                          al presente Contrato.
                        </p>
                        <p className="pb-2">
                          El Agente se abstendrá de usar su perfil y el resto de
                          los contenidos de la Plataforma, incluidos los datos
                          personales de propietarios y clientes, con fines o
                          efectos ilícitos y que sean lesivos de los derechos e
                          intereses de terceros, o que de cualquier forma puedan
                          dañar, inutilizar, afectar o deteriorar la Plataforma,
                          sus contenidos y sus servicios, eximiendo a Nexun de
                          toda responsabilidad, daño o perjuicio que pudiera
                          derivarse de dicho acceso o uso ilícito
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">7</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">PUBLICIDAD</p>
                        <p className="pb-2">
                          Nexun autoriza al Agente a que el Inmueble se
                          publicite través de los canales que considere siempre
                          que se respeten las restricciones que imponga la
                          propia Nexun o directamente por el propietario para la
                          canalización de las promociones.
                        </p>
                        <p className="pb-2">
                          El Agente podrá así realizar cualquier actuación en
                          materia de marketing o publicidad online que entienda
                          necesaria a los fines del presente contrato.
                        </p>
                        <p className="pb-2">
                          Los costes e inversión en materia de marketing y
                          publicidad serán asumidos por el Agente.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">8</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">
                          PROPIEDAD INTELECTUAL E INDUSTRIAL
                        </p>
                        <p className="pb-2">
                          Nexun es el titular o licenciatario de todos los
                          derechos de propiedad intelectual e industrial
                          incluidos en la Plataforma, así como sobre los
                          contenidos accesibles a través de la misma. Los
                          derechos de propiedad intelectual de la Plataforma,
                          así como los textos, imágenes, fotografías, diseño
                          gráfico, estructura de navegación, información y demás
                          contenidos que se recogen en ella son titularidad de
                          Nexun, a quien corresponde el ejercicio exclusivo de
                          los derechos de explotación de los mismos en cualquier
                          forma y, en especial, los derechos de reproducción,
                          distribución, comunicación pública y transformación,
                          de conformidad con la legislación española de derechos
                          de propiedad intelectual e industrial. La autorización
                          al Agente para el acceso a la Plataforma no supone
                          renuncia, transmisión, licencia o cesión total ni
                          parcial sobre derechos de propiedad intelectual o
                          industrial por parte de Nexun.
                        </p>
                        <p className="pb-2">
                          El Agente no podrá en ningún caso suprimir, eludir o
                          manipular de ningún modo los contenidos de la
                          Plataforma. Asimismo, tendrá prohibido modificar,
                          copiar, reutilizar, explotar, reproducir, comunicar
                          públicamente, hacer segundas o posteriores
                          publicaciones, cargar archivos, enviar por correo,
                          transmitir, usar, tratar o distribuir de cualquier
                          forma la totalidad o parte de los contenidos incluidos
                          en la Plataforma para propósitos ajenos al
                          cumplimiento del presente Contrato, si no se cuenta
                          con la autorización expresa y por escrito de Nexun o,
                          en su caso, del titular de los derechos a que
                          corresponda.
                        </p>
                        <p className="pb-2">
                          El Agente garantiza que ostentará los derechos
                          necesarios para compartir cualquier tipo de contenido
                          a través de la Plataforma, quedando exento Nexun de
                          cualquier responsabilidad sobre el contenido y
                          legalidad de la información ofrecida. La facilitación
                          de contenidos por parte del Agente a través de la
                          Plataforma supondrá la cesión a Nexun, con carácter
                          gratuito, y con la máxima amplitud permitida por la
                          legislación igente, de los derechos de explotación de
                          propiedad intelectual o industrial derivados de tales
                          contenidos.
                        </p>
                        <p className="pb-2">
                          El Agente se obliga a usar los contenidos de forma
                          diligente, correcta y lícita y, en particular, se
                          compromete a abstenerse de suprimir, eludir o
                          manipular el &quot;copyright&quot; y demás datos
                          identificativos de los derechos de Nexun o de sus
                          titulares incorporados a los contenidos, así como a
                          los dispositivos técnicos de protección o cualesquiera
                          mecanismos de información que pudieren incluirse en
                          los contenidos.
                        </p>
                        <p className="pb-2">
                          Nexun informa al Agente que no concede licencia o
                          autorización implícita alguna sobre los derechos de
                          propiedad intelectual y/o industrial o sobre cualquier
                          otro derecho o propiedad relacionada, directa o
                          indirectamente, con los contenidos incluidos en la
                          Plataforma.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">9</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">PROTECCIÓN DE DATOS</p>
                        <p className="pb-2">
                          Las Partes de este Contrato conocen y se obligan a
                          cumplir el Reglamento (UE) 2016/679 del Parlamento
                          Europeo y del Consejo, de 27 de abril de 2016 (RGPD)
                          relativo a la protección de las personas físicas en lo
                          que respecta al tratamiento de datos personales, así
                          como la Ley Orgánica 3/2018, de 5 de diciembre, de
                          Protección de Datos Personales y garantía de los
                          derechos digitales.
                        </p>
                        <p className="pb-2">
                          De esta forma, las Partes son conscientes de que
                          mediante la firma de este Contrato consienten que sus
                          datos personales recogidos en el presente Contrato,
                          así como aquellos que se pudiesen recoger en el futuro
                          para poder dar cumplimiento o una correcta ejecución
                          de este mismo, podrían ser incorporados por la otra
                          Parte a su propio fichero automatizado o no de
                          recogida de datos con el fin de ejecutar correctamente
                          la relación contractual y, eventualmente, para una
                          gestión administrativa y/o comercial.
                        </p>
                        <p className="pb-2">
                          En todo caso, las Partes se comprometen a que estos
                          datos personales no serán comunicados en ningún caso a
                          terceros, aunque, si se diese el caso de que fuera a
                          realizarse algún tipo de comunicación de datos
                          personales, se comprometen siempre y de forma previa,
                          a solicitar el consentimiento expreso, informado e
                          inequívoco de la Parte que es titular de dichos datos
                          de carácter personal.
                        </p>
                        <p className="pb-2">
                          De esta Cláusula no resulta ninguna limitación o
                          restricción para las Partes en cuanto al ejercicio de
                          los derechos de acceso, rectificación, supresión,
                          limitación, portabilidad u oposición con los que
                          pudieran contar.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">10</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">CONFIDENCIALIDAD</p>
                        <p className="pb-2">
                          Cada una de las Partes tratará de forma confidencial y
                          no divulgará ni utilizará para fines distintos de los
                          aquí acordados ninguna información recibida u obtenida
                          como consecuencia de la celebración y/o ejecución del
                          presente Contrato. Esta obligación de confidencialidad
                          permanecerá en vigor durante la vigencia del presente
                          Contrato y durante los cinco (5) años siguientes a su
                          expiración o resolución por cualquier motivo. La
                          obligación de confidencialidad no aplicará en aquellos
                          casos en que la información confidencial deba ser
                          revelada por ley o por una orden judicial o
                          administrativa, en cuyo caso la Parte obligada a
                          revelar la información confidencial deberá notificarlo
                          a la otra Parte tan pronto como sea posible.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">11</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">CESIÓN</p>
                        <p className="pb-2">
                          Salvo disposición expresa en otro sentido prevista en
                          el presente Contrato, ninguna de las Partes podrá
                          ceder los derechos y obligaciones resultantes del
                          presente Contrato sin el previo consentimiento por
                          escrito de la otra Parte.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">12</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">NULIDAD PARCIAL</p>
                        <p className="pb-2">
                          En caso de que cualquier disposición del presente
                          Contrato fuera declarada total o parciamente nula,
                          inválida o ineficaz, dicha disposición se aplicará con
                          cualquier supresión o modificación que proceda a
                          efectos de asegurar su legalidad, validez y eficacia y
                          dar efecto al acuerdo comercial de las Partes.
                        </p>
                        <p className="pb-2">
                          En la medida en que no fuera posible suprimir o
                          modificar la disposición afectada total o
                          parcialmente, de conformidad con lo dispuesto en el
                          párrafo anterior, se entenderá que dicha disposición
                          (o, en su caso, aquella parte de la misma que hubiera
                          sido declarada nula, inválida o ineficaz) no forma
                          parte del presente Contrato, sin que ello perjudique
                          la legalidad, validez o eficacia de las restantes
                          disposiciones del presente Contrato, con sujeción a
                          cualquier supresión o modificación realizada al amparo
                          del párrafo anterior.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">13</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">INTEGRIDAD DEL CONTRATO</p>
                        <p className="pb-2">
                          El presente Contrato constituye la totalidad del
                          acuerdo entre las Partes con respecto a su objeto a
                          esta fecha, y sustituye y reemplaza a cualesquiera
                          acuerdos, negociaciones y compromisos, en cada caso
                          orales o escritos, que pudieran haber existido
                          anteriormente entre todas las Partes y cualesquiera
                          otras partes de tales instrumentos, en relación con
                          las cuestiones que se regulan en el presente Contrato.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pb-2">
                      <p className="col-1 text-center fw-bold">14</p>
                      <div className="col-11">
                        <p className="pb-1 fw-bold">
                          LEY APLICABLE Y SOMETIMIENTO A JURISDICCIÓN
                        </p>
                        <p className="pb-2">
                          El presente Contrato, junto con cualesquiera
                          obligaciones extracontractuales que pudieran derivarse
                          de o surgir en relación con el mismo, se regirá e
                          interpretará de conformidad con el derecho común
                          español.
                        </p>
                        <p className="pb-2">
                          Las Partes se someten con carácter exclusivo a la
                          jurisdicción de los juzgados y tribunales de la ciudad
                          de Madrid (España) para la resolución de cualesquiera
                          controversias que pudieran derivarse del Contrato o en
                          relación con el mismo (incluyendo su propia validez,
                          la validez de esta Cláusula, así como cualesquiera
                          obligaciones extracontractuales que pudieran derivarse
                          del mismo).
                        </p>
                      </div>
                    </div>
                    <div className="d-flex p-2">
                      <p className="pb-2">
                        <strong>Y en prueba de conformidad,</strong> las Partes
                        suscriben el presente Contrato en dos ejemplares, en el
                        lugar y fecha señalados en el encabezamiento.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
