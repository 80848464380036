import { Routes, Route, Outlet } from 'react-router-dom';
import { AgentLayout, RequireAuth, RequireVerificatedEmail } from 'components';
import NotFoundRoute from 'routes/notFound.route';
import VerifyEmailRoute from 'routes/verifyEmail.route';
import HomeRoute from './home.route';
import PropertiesLayout from './properties';
import SettingsRoute from './settings';
import VisitsRoutes from './visits';
import MyVisitsRoute from 'routes/agent/visits/my-visits.route';
import ComisionsRoutes from './comisions';
import MyComisionsRoute from 'routes/agent/comisions/my-comisions.route';
import TrainingRoutes from 'routes/agent/training';
import ExploreRoute from 'routes/agent/training/explore.route';
import MyCoursesRoute from 'routes/agent/training/my-courses.route';
import FavoriteCourseRoute from 'routes/agent/training/favorites.route';
import CompletedCourseRoute from 'routes/agent/training/completed.route';
import ContractRoute from 'routes/agent/settings/contract.route';
import InviteRoute from 'routes/agent/invite';
import DocumentationRoute from 'routes/agent/settings/documentation.route';
import NotificationRoute from 'routes/agent/settings/notifications.route';
import ProfileSettingsRoute from 'routes/agent/settings/profile.route';
import AvailableSettingsRoute from 'routes/agent/settings/available.route';
import PropertiesRoute from './properties/properties.route';
import MyPropertiesRoute from './properties/my-properties-route';
import NotificationsRoute from './notifications';
import FavoritesRoute from './properties/favorites.route';
import PolicyAgentRoute from 'routes/agent/policy.route';
import VisitAgentView from 'routes/agent/visits/visit';

import PropertyViewRoute from './properties/view.route';
import LoginRoute from 'routes/login.route';
import RecoverPasswordRoute from 'routes/recover-password';
import PasswordResetRoute from 'routes/password-reset';

export default function AgentRoutes() {
  return (
    <Routes>
      <Route
        element={
          <RequireAuth isRequired={false}>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/recover-password" element={<RecoverPasswordRoute />} />
        <Route path="/password-reset" element={<PasswordResetRoute />} />
      </Route>
      <Route
        element={
          <RequireAuth>
            <AgentLayout />
          </RequireAuth>
        }
      >
        <Route
          path="/verify-email"
          element={
            <RequireVerificatedEmail isRequired={false}>
              <VerifyEmailRoute />
            </RequireVerificatedEmail>
          }
        />
        <Route
          element={
            <RequireVerificatedEmail>
              <Outlet />
            </RequireVerificatedEmail>
          }
        >
          <Route path="*" element={<NotFoundRoute />} />
          <Route path="/" element={<HomeRoute />} />
          <Route path="/properties" element={<PropertiesLayout />}>
            <Route
              path="*"
              element={
                <div className="container-md width-full">
                  <div className="p-responsive py-6 color-bg-default rounded-2 border">
                    <NotFoundRoute />
                  </div>
                </div>
              }
            />
            <Route path="/properties" element={<PropertiesRoute />} />
            <Route
              path="/properties/my-properties"
              element={<MyPropertiesRoute />}
            />
            <Route path="/properties/favorites" element={<FavoritesRoute />} />
            <Route
              path="/properties/:propertyId"
              element={<PropertyViewRoute />}
            />
          </Route>
          <Route path="/settings" element={<SettingsRoute />}>
            <Route
              path="*"
              element={
                <div className="container-md width-full">
                  <div className="p-responsive py-6 color-bg-default rounded-2 border">
                    <NotFoundRoute />
                  </div>
                </div>
              }
            />
            <Route path="/settings" element={<ProfileSettingsRoute />} />
            <Route
              path="/settings/available"
              element={<AvailableSettingsRoute />}
            />
            <Route
              path="/settings/notifications"
              element={<NotificationRoute />}
            />
            <Route path="/settings/contract" element={<ContractRoute />} />
            <Route
              path="/settings/documents"
              element={<DocumentationRoute />}
            />
          </Route>
          <Route path="/visits" element={<VisitsRoutes />}>
            <Route
              path="*"
              element={
                <div className="container-md width-full">
                  <div className="p-responsive py-6 color-bg-default rounded-2 border">
                    <NotFoundRoute />
                  </div>
                </div>
              }
            />
            <Route path="/visits" element={<MyVisitsRoute />} />
            <Route path="/visits/:visitId" element={<VisitAgentView />} />
          </Route>
          <Route path="/commissions" element={<ComisionsRoutes />}>
            <Route
              path="*"
              element={
                <div className="container-md width-full">
                  <div className="p-responsive py-6 color-bg-default rounded-2 border">
                    <NotFoundRoute />
                  </div>
                </div>
              }
            />
            <Route path="/commissions" element={<MyComisionsRoute />} />
          </Route>
          <Route path="/training" element={<TrainingRoutes />}>
            <Route
              path="*"
              element={
                <div className="container-md width-full">
                  <div className="p-responsive py-6 color-bg-default rounded-2 border">
                    <NotFoundRoute />
                  </div>
                </div>
              }
            />
            <Route path="/training" element={<ExploreRoute />} />
            <Route path="/training/my-formation" element={<MyCoursesRoute />} />
            <Route
              path="/training/favorites"
              element={<FavoriteCourseRoute />}
            />
            <Route
              path="/training/completed"
              element={<CompletedCourseRoute />}
            />
          </Route>
          <Route path="/invite" element={<InviteRoute />} />
          <Route path="/notifications" element={<NotificationsRoute />} />
          <Route path="/privacy" element={<PolicyAgentRoute />} />
        </Route>
      </Route>
    </Routes>
  );
}
