import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HomeHeader, ShapeDivider, HomeFooter, NexunAI } from 'components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Container } from '@mui/material';
import { ChevronLeftIcon, ChevronRightIcon } from '@primer/octicons-react';
import {
  imgIcons,
  imagesFeria,
  testimonies,
  steps,
  cards,
  textBoxes,
  ourGoals,
  propertyPlaces,
} from 'utils/home-data';
import mainbg from '../images/home/mainbgn.webp';
import Laptop from '../images/home/Laptop.png';

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate');
      }
    });
  },
  { threshold: 0.5 },
);

export default function HomeRoute() {
  useEffect(() => {
    const elements = document.querySelectorAll('.slide-x-left');
    elements.forEach((element) => {
      observer.observe(element);
    });
  }, []);

  return (
    <div className="d-flex flex-column flex-1 color-bg-default">
      <div className="container-lg width-full">
        <HomeHeader />
      </div>
      <div className="color-bg-subtle mb-5">
        <ShapeDivider placement="bottom" />
        <div
          className="light-text position-relative"
          style={{
            height: '450px',
          }}
        >
          <img
            src={mainbg}
            alt="Background"
            className="position-absolute top-0 left-0 object-fit-cover width-full height-full rounded-3"
          />
          <div className="p-responsive width-full height-full">
            <div className="p-responsive width-full height-full d-flex flex-justify-center flex-column position-relative text-center text-md-left flex-items-center flex-md-items-start">
              <div>
                <h1 className="h1">
                  Compra al precio justo, <br />
                  Olvida las especulaciones
                </h1>
                <p className="pt-2 f3">
                  Nexun reinventa el proceso de compraventa. <br />
                  Ahora es seguro, transparente y adaptado a ti.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-responsive width-full color-bg-default d-flex flex-items-center py-3 py-md-5">
        <Box
          className="container-lg p-responsive width-full d-flex flex-justify-center"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
          }}
        >
          <NexunAI />
        </Box>
      </div>

      <div className="p-responsive width-full color-bg-default d-flex flex-items-center dark-text py-5">
        <Box
          className="container-lg p-responsive width-full d-flex flex-items-center flex-justify-between"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <h3
            className="h1 text-center text-md-left"
            style={{ maxWidth: '500px' }}
          >
            La nueva y mejor manera de comprar y vender una propiedad
          </h3>

          <div className="width-full width-md-auto d-flex flex-row flex-md-column flex-justify-around pt-4">
            <div className="d-flex flex-items-center">
              <img
                src={imgIcons[2].icon}
                alt="Flecha hacia la derecha"
                width={32}
                height={32}
                className="mb-1"
              />
              <p className="f3 text-bold pl-3">Comprar</p>
            </div>
            <div className="d-flex flex-items-center">
              <img
                src={imgIcons[2].icon}
                alt="Flecha hacia la derecha"
                width={32}
                height={32}
                className="mb-1"
              />
              <p className="f3 text-bold pl-3">Vender</p>
            </div>
          </div>
        </Box>
      </div>

      <div className="p-responsive width-full color-bg-default d-flex flex-items-center py-5">
        <Box
          className="container-lg p-responsive width-full d-flex flex-items-center flex-justify-between"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
          }}
        >
          <div className="d-flex flex-column flex-md-row width-full dark-text">
            {textBoxes.map((item) => {
              return (
                <div
                  className="p-4 d-flex flex-column width-full"
                  style={{ gap: 8 }}
                >
                  <img src={item.src} alt="Icono" width={32} height={32} />
                  <h4 className="f4">{item.title}</h4>
                  <p className="f4">{item.description}</p>
                </div>
              );
            })}
          </div>
        </Box>
      </div>

      <div
        className="p-responsive width-full d-flex flex-items-center light-text py-3"
        style={{ backgroundColor: '#23233C' }}
      >
        <Box
          className="container-lg p-responsive width-full d-flex flex-items-center flex-justify-between"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
          }}
        >
          <div
            className="d-flex flex-column flex-justify-center width-full py-2"
            style={{ gap: 12 }}
          >
            <h3 className="h4 text-center text-md-left pl-md-8">
              Nuestro propósito
            </h3>

            <div
              className="d-flex flex-column flex-md-row flex-justify-around flex-items-center flex-md-items-start"
              style={{ gap: 18 }}
            >
              {ourGoals.map((goal) => {
                return (
                  <div
                    className="d-flex flex-column"
                    style={{ gap: 8, width: '260px' }}
                  >
                    <hr />
                    <h4 className="h3">{goal.title}</h4>
                    <p className="f4">{goal.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </Box>
      </div>

      <div className="p-responsive width-full color-bg-default d-flex flex-items-center dark-text py-5">
        <Box
          className="container-lg p-responsive width-full d-flex flex-items-center flex-justify-between"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
          }}
        >
          <h3
            className="h1 text-center text-md-left"
            style={{ maxWidth: '500px' }}
          >
            La nueva y mejor manera de comprar y vender una propiedad
          </h3>

          <div className="width-full width-md-auto d-flex flex-row flex-md-column flex-justify-around pt-4">
            <div className="d-flex flex-items-center">
              <img
                src={imgIcons[2].icon}
                alt="Flecha hacia la derecha"
                width={32}
                height={32}
                className="mb-1"
              />
              <p className="f3 text-bold pl-3">Comprar una propiedad</p>
            </div>
            <div className="d-flex flex-items-center">
              <img
                src={imgIcons[2].icon}
                alt="Flecha hacia la derecha"
                width={32}
                height={32}
                className="mb-1"
              />
              <p className="f3 text-bold pl-3">Vender una propiedad</p>
            </div>
          </div>
        </Box>
      </div>

      <div className="p-responsive width-full d-flex flex-items-center py-5">
        <Box
          className="container-lg p-responsive width-full d-flex flex-items-center flex-justify-between dark-text"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
          }}
        >
          <div className="d-flex flex-column flex-md-row flex-md-wrap flex-justify-around">
            {propertyPlaces.map((place) => {
              return (
                <div
                  className="d-flex flex-items-center flex-justify-center"
                  style={{
                    gap: 18,
                    flexBasis: '50%',
                    maxWidth: '100%',
                    marginBottom: 18,
                  }}
                >
                  <img src={place.img} className="p-4" alt={place.title} />
                  <div style={{ width: '33%' }}>
                    <h3>{place.title}</h3>
                    <p className="f5">
                      Tú decides cuándo visitar la propiedad, ya sea en video o
                      presencial
                    </p>
                    <a href="#">ver propiedades</a>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
      </div>

      <div
        className="p-responsive width-full d-flex flex-items-center py-3"
        style={{ backgroundColor: '#78B8FF' }}
      >
        <Box
          className="container-lg p-responsive width-full d-flex flex-items-center"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
          }}
        >
          <div
            className="d-flex flex-column flex-md-row flex-items-center width-full flex-justify-around py-2"
            style={{ gap: 12 }}
          >
            <h3
              className="h1 text-center text-md-left"
              style={{ maxWidth: '480px' }}
            >
              Ayúdanos a reinventar la forma de transar una propiedad
            </h3>
            <img src={Laptop} alt="Laptop" />
          </div>
        </Box>
      </div>

      <div className="d-flex flex-column py-5">
        <div className="flex">
          <div className="Box Box--spacious col-10 mx-auto text-center d-flex flex-column flex-md-row flex-items-center px-5 py-3 my-5">
            <div className="col-12 col-md-6">
              <h3 className="f3 text-bold text-left pb-4">{cards[0].title}</h3>
              <p
                className="text-left f4 dark-text"
                dangerouslySetInnerHTML={{ __html: cards[0].subtitle }}
              ></p>
            </div>
            <div className="col-12 col-md-6">
              <img src={cards[0].img} alt="feria" className="width-full" />
            </div>
          </div>
          <div className="Box Box--spacious col-10 mx-auto text-center d-flex flex-column flex-md-row flex-items-center px-5 py-3 my-5">
            <div className="col-12 col-md-6">
              <h3 className="f3 text-bold text-left pb-4">{cards[2].title}</h3>
              <p
                className="text-left f4 dark-text"
                dangerouslySetInnerHTML={{ __html: cards[2].subtitle }}
              ></p>
            </div>
            <div className="col-12 col-md-6">
              <img src={cards[2].img} alt="feria" className="width-full" />
            </div>
          </div>
          <div className="Box Box--spacious col-10 mx-auto text-center d-flex flex-column flex-md-row flex-items-center px-5 py-3">
            <div className="col-12 col-md-6">
              <img src={cards[3].img} alt="feria" className="width-full" />
            </div>
            <div className="col-12 col-md-6">
              <h3
                className="f3 text-bold text-left pb-4"
                dangerouslySetInnerHTML={{ __html: cards[3].title }}
              ></h3>
              <p
                className="text-left f4 dark-text"
                dangerouslySetInnerHTML={{ __html: cards[3].subtitle }}
              ></p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center py-5 dark-text">
        <h3 className="h1">Te ayudamos a encontrar el inmueble que buscas</h3>
        <p className="f4 text-bold">Con tecnología que da confianza</p>
        <Link to="/properties" className="btn-large py-2">
          ver propiedades
        </Link>
      </div>

      <div className="color-bg-default py-5">
        <div className="width-full">
          <img src={imagesFeria[9].img} alt="feria" className="col-12" />
        </div>
        <div className="position-relative">
          <img
            src={imagesFeria[10].img}
            alt="feria"
            className="col-12"
            style={{
              minHeight: '400px',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
          <div className="Box Box--spacious col-6 col-md-4 mx-auto text-center d-flex flex-column flex-items-center px-5 py-3 my-5 position-absolute left-5 top-5 left-md-10 top-md-12">
            <h2 className="h2">
              Ayúdanos a reinventar la manera de comprar un inmueble
            </h2>
            <Link to="/agent" className="btn btn-mktg my-3">
              Únete al equipo ->
            </Link>
          </div>
        </div>
      </div>
      <HomeFooter hideDivider />
    </div>
  );
}

const PreviousArrow = ({ className, style, onClick }) => {
  return (
    <div className={className} style={style}>
      <button type="button" className="btn-octicon ml-0" onClick={onClick}>
        <ChevronLeftIcon size="medium" />
      </button>
    </div>
  );
};

const NextArrow = ({ className, style, onClick }) => {
  return (
    <div className={className} style={style}>
      <button type="button" className="btn-octicon ml-0" onClick={onClick}>
        <ChevronRightIcon size="medium" />
      </button>
    </div>
  );
};
