import { useCallback, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import {
  Modal,
  Box,
  Stack,
  Typography,
  FormControl,
  TextField,
  Checkbox,
} from '@mui/material';
import location from '../../images/property/icons/location.svg';
import { Close } from '@mui/icons-material';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import LanguageSelector from 'components/LanguageSelector';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { CREATE_PROPERTY_VISIT_MUTATION } from 'apollo/mutations';
import { useNavigate } from 'react-router-dom';

function newDateTimeEntry() {
  const ahora = new Date();

  // Sumar 8 horas a la hora actual
  ahora.setHours(ahora.getHours() + 8);

  // Verificar si es después de las 24:00
  if (ahora.getHours() >= 24) {
    // Establecer la hora a las 10:00 a.m. del próximo día
    ahora.setHours(10);
    ahora.setMinutes(0);
    ahora.setSeconds(0);
    ahora.setDate(ahora.getDate() + 1);
  }

  return ahora;
}

const useVisitRequestLandings = (property) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [data, setData] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    language: '',
    message: '',
    scheduledAt: '',
  });

  function generateAleatoryPassword() {
    const caracteres =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+';
    let password = '';
    for (let i = 0; i < 12; i++) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      password += caracteres.charAt(indiceAleatorio);
    }

    return password;
  }

  const onVisitCreated = useCallback(
    (data) => {
      addToast(
        'Hemos enviado un correo a tu dirección a con los detalles de la cita',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
      setData({
        name: '',
        email: '',
        phone: '',
        language: '',
        message: '',
        scheduledAt: '',
      });
      navigate('/greetings', { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast],
  );

  const onError = useCallback(
    (error) => {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: false,
      });
    },
    [addToast],
  );

  const [createVisit, { loading }] = useMutation(
    CREATE_PROPERTY_VISIT_MUTATION,
    { onCompleted: onVisitCreated, onError },
  );

  function handleFormSubmit(event) {
    event.preventDefault();
    if (data.language === undefined) {
      addToast('El idioma no se ha seleccionado.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (data.scheduledAt === undefined) {
      addToast('La fecha no se ha seleccionado.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    createVisit({
      variables: {
        record: {
          property: property._id,
          date: data.scheduledAt,
          isConfirmedByBuyer: true,
        },
        buyerRecord: {
          firstName: data.name,
          lastName: data.lastname,
          email: data.email,
          phone: data.phone,
          password: generateAleatoryPassword,
          language: data.language._id,
          isEmailVerificated: true,
        },
      },
    });
  }

  return {
    name: data.name,
    email: data.email,
    phone: data.phone,
    language: data.language,
    message: data.message,
    scheduledAt: data.scheduledAt,
    setData,
    handleFormSubmit,
    loading,
  };
};

export default function VisitModal({
  open,
  handleOpen,
  property,
  numberFormat,
  timeVisit,
  dateVisit,
}) {
  const {
    name,
    lastname,
    email,
    phone,
    language,
    message,
    scheduledAt,
    setData,
    handleFormSubmit,
    loading,
  } = useVisitRequestLandings(property);

  return (
    <Modal
      open={open}
      onClose={handleOpen}
      className="width-full width-lg-auto"
      sx={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff!important',
        borderRadius: '1rem!important',
        boxShadow: 24,
        overflowY: 'auto',
        minHeight: '68vh',
        maxHeight: '100%',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          background: '#FFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#F6F6FA',
            width: '100%',
          }}
        >
          <button
            className="btn btn-octicon position-absolute top-0 right-0 m-2"
            type="button"
            onClick={handleOpen}
          >
            <Close />
          </button>
          <div className="d-flex flex-column py-4" style={{ gap: '1.5rem' }}>
            <Stack
              direction="row"
              spacing={2}
              className="flex-justify-center flex-items-start px-3"
            >
              <div className="d-flex" style={{ gap: '.5rem' }}>
                <img
                  src={location}
                  alt="Location icon"
                  width={20}
                  className="pt-2"
                />
              </div>
              <Stack direction={'column'}>
                <Stack
                  direction="row"
                  gap={2}
                  className="d-flex flex-items-center"
                >
                  <p className="f3 text-bold">{property.address.town}</p>
                  <p className="f1 text-bold">
                    {numberFormat(property.operation.price)}
                  </p>
                </Stack>
                <p className="f5 ">{property.place.formattedAddress}</p>
              </Stack>
            </Stack>
          </div>
          <div
            className="d-flex flex-justify-center flex-items-center flex-row"
            style={{ gap: '3rem' }}
          >
            {property.icons.map((icon, i) => (
              <div
                key={i}
                className="d-flex flex-justify-center flex-column flex-items-center"
              >
                <div
                  className="d-flex flex-column flex-justify-center flex-items-center"
                  style={{ gap: '.6rem' }}
                >
                  <img
                    src={icon.src}
                    alt="Icono"
                    className="img-fluid rounded"
                    style={{
                      width: '35px',
                      height: '35px',
                    }}
                  />
                  <p className="f2 text-center">{icon.description}</p>
                </div>
              </div>
            ))}
          </div>
        </Box>
        <Stack direction="column" align="center" marginTop={3} width={'80%'}>
          <Stack direction="column" gap={2}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontWeight: 'bold' }}
            >
              Seleccione la fecha y hora
            </Typography>
            <div className="form-group text-start">
              <div className="form-group-body">
                <Flatpickr
                  data-enable-time
                  options={{
                    dateFormat: 'F j,Y H:i',
                    minDate: 'today',
                    minTime: newDateTimeEntry(),
                    enableTime: true,
                    time_24hr: true,
                    locale: Spanish,
                    hourIncrement: 1,
                    minuteIncrement: 15,
                    defaultMinute: 0,
                    altFormat: 'F j,Y H:i',
                  }}
                  placeholder="Fecha y hora de la visita"
                  name="scheduledAt"
                  className="form-control width-full"
                  value={scheduledAt}
                  style={{ width: '100%', height: '50px' }}
                  onChange={(date) => {
                    setData((prev) => ({
                      ...prev,
                      scheduledAt: new Date(date),
                    }));
                  }}
                />
              </div>
            </div>
          </Stack>

          <Stack direction="column" gap={2} marginTop={3}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontWeight: 'bold' }}
            >
              Y quién visitará la propiedad
            </Typography>

            <FormControl required>
              <TextField
                size="small"
                id="filled-basic"
                label="Nombre"
                variant="filled"
                value={name}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
              />
            </FormControl>
            <FormControl required>
              <TextField
                size="small"
                id="filled-basic"
                label="Apellidos"
                variant="filled"
                value={lastname}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    lastname: e.target.value,
                  }));
                }}
              />
            </FormControl>
            <FormControl required>
              <TextField
                size="small"
                id="filled-basic"
                label="Email"
                variant="filled"
                value={email}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </FormControl>
            <FormControl required>
              <TextField
                size="small"
                id="filled-basic"
                label="Teléfono"
                variant="filled"
                value={phone}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }));
                }}
              />
            </FormControl>
            <FormControl>
              <div className="form-group-body">
                <LanguageSelector
                  value={language}
                  onSelect={(language) => {
                    setData((prev) => ({
                      ...prev,
                      language,
                    }));
                  }}
                  className="form-control width-full"
                  style={{
                    width: '100%',
                    height: '50px',
                    justifyContent: 'start',
                    alignItems: 'center',
                    display: 'flex',
                    borderLeft: '0px',
                    borderRight: '0px',
                    borderTop: '0px',
                    borderBottom: '0.5px solid #000',
                    borderRadius: '0px',
                    backgroundColor: '',
                  }}
                />
              </div>
            </FormControl>
            <FormControl>
              <TextField
                size="small"
                id="filled-basic"
                label="Mensaje"
                variant="filled"
                multiline
                value={message}
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    message: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Stack>
          <button
            id='Botón "Programar"'
            className="btn btn-primary py-3 mt-2"
            type="button"
            onClick={handleFormSubmit}
            disabled={loading}
          >
            {loading ? 'Confirmando...' : 'Confirmar Visita'}
          </button>

          <div
            className="d-flex flex-row mt-3"
            style={{ gap: '1rem', width: '100%' }}
          >
            <Checkbox id="formCheckbox" required />
            <label htmlFor="formCheckbox" className="text-small text-left">
              Acepto compartir mi email para que me comuniquen si encuentran
              alguna propiedad que cumpla mis expectativas
            </label>
          </div>
        </Stack>
      </Box>
    </Modal>
  );
}
