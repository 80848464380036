import {
  BellIcon,
  ClockIcon,
  MailIcon,
  PersonIcon,
  ShieldLockIcon,
  BriefcaseIcon,
  LawIcon,
} from '@primer/octicons-react';
import { NavLink, Outlet } from 'react-router-dom';

export default function SettingsRoute() {
  return (
    <>
      <div className=""></div>
      <div className="Layout Layout--flowRow-until-md Layout--sidebarPosition-start Layout--sidebarPosition-flowRow-start">
        <div className="Layout-sidebar">
          <div
            className="position-md-sticky color-bg-default rounded-2 border"
            style={{ top: 24 }}
          >
            <ul className="ActionList">
              <li className="ActionList-sectionDivider">
                <h3 className="ActionList-sectionDivider-title">Cuenta</h3>
              </li>
              <NavLink
                to="/settings"
                className={({ isActive }) =>
                  `ActionList-item ActionList-content ActionList-content--visual16 ${
                    isActive ? 'ActionList-item--navActive' : ''
                  }`
                }
                end
              >
                <span className="ActionList-item-visual ActionList-item-visual--leading">
                  <PersonIcon />
                </span>
                <span className="ActionList-item-label">Perfil</span>
              </NavLink>
              <li className="ActionList-item">
                <NavLink
                  to="/settings/available"
                  className={({ isActive }) =>
                    `ActionList-item ActionList-content ActionList-content--visual16 ${
                      isActive ? 'ActionList-item--navActive' : ''
                    }`
                  }
                >
                  <span className="ActionList-item-visual ActionList-item-visual--leading">
                    <ClockIcon />
                  </span>
                  <span className="ActionList-item-label">Disponibilidad</span>
                </NavLink>
              </li>
              <li className="ActionList-item">
                <NavLink
                  to="/settings/notifications"
                  className={({ isActive }) =>
                    `ActionList-item ActionList-content ActionList-content--visual16 ${
                      isActive ? 'ActionList-item--navActive' : ''
                    }`
                  }
                >
                  <span className="ActionList-item-visual ActionList-item-visual--leading">
                    <BellIcon />
                  </span>
                  <span className="ActionList-item-label">Notificaciones</span>
                </NavLink>
              </li>
              <li role="separator" className="ActionList-sectionDivider" />
              <li className="ActionList-sectionDivider">
                <h3 className="ActionList-sectionDivider-title">
                  Documentación
                </h3>
              </li>
              <li className="ActionList-item">
                <NavLink
                  to="/settings/contract"
                  className={({ isActive }) =>
                    `ActionList-item ActionList-content ActionList-content--visual16 ${
                      isActive ? 'ActionList-item--navActive' : ''
                    }`
                  }
                >
                  <span className="ActionList-item-visual ActionList-item-visual--leading">
                    <BriefcaseIcon />
                  </span>
                  <span className="ActionList-item-label">Contrato</span>
                </NavLink>
              </li>
              <li className="ActionList-item">
                <NavLink
                  to="/settings/documents"
                  className={({ isActive }) =>
                    `ActionList-item ActionList-content ActionList-content--visual16 ${
                      isActive ? 'ActionList-item--navActive' : ''
                    }`
                  }
                >
                  <span className="ActionList-item-visual ActionList-item-visual--leading">
                    <LawIcon />
                  </span>
                  <span className="ActionList-item-label">Documentación</span>
                </NavLink>
              </li>
              {/* <li role="separator" className="ActionList-sectionDivider" />
              <li className="ActionList-sectionDivider">
                <h3 className="ActionList-sectionDivider-title">Acceso</h3>
              </li>
              <li className="ActionList-item">
                <NavLink
                  to="/settings/email"
                  className={({ isActive }) =>
                    `ActionList-item ActionList-content ActionList-content--visual16 ${
                      isActive ? 'ActionList-item--navActive' : ''
                    }`
                  }
                >
                  <span className="ActionList-item-visual ActionList-item-visual--leading">
                    <MailIcon />
                  </span>
                  <span className="ActionList-item-label">Email</span>
                </NavLink>
              </li>
              <li className="ActionList-item">
                <NavLink
                  to="/settings/password"
                  className={({ isActive }) =>
                    `ActionList-item ActionList-content ActionList-content--visual16 ${
                      isActive ? 'ActionList-item--navActive' : ''
                    }`
                  }
                >
                  <span className="ActionList-item-visual ActionList-item-visual--leading">
                    <ShieldLockIcon />
                  </span>
                  <span className="ActionList-item-label">Contraseña</span>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="Layout-main flex-1">
          <div className="Layout-main">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
