import { HomeFooter, HomeHeader, ShapeDivider } from 'components';
import { useCallback, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { EyeIcon, EyeClosedIcon } from '@primer/octicons-react';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { SIGNUP_MUTATION } from 'apollo/mutations';
import {
  WhyNexun,
  ownerHowItWorks,
  serviceTypes,
  ownerSteps,
} from 'utils/owner.data';

export default function OwnerRoute() {
  const [view, setView] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { addToast } = useToasts();

  const onCreateOwner = useCallback(
    (data) => {
      addToast(
        'Se ha enviado un correo para confirmar la cuenta a ' +
          data.signup.actor.email,
        { appearance: 'success', autoDismiss: true },
      );
    },
    [addToast],
  );

  const onCreateOwnerFail = useCallback(
    (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    },
    [addToast],
  );

  const [signup, { loading }] = useMutation(SIGNUP_MUTATION, {
    onCompleted: onCreateOwner,
    onError: onCreateOwnerFail,
  });

  const createOwner = (event) => {
    event.preventDefault();
    signup({
      variables: {
        email,
        actorType: 'OWNER',
        password,
      },
    });
  };

  return (
    <div className="d-flex flex-column flex-1 color-bg-default dark-text">
      <div className="container-lg width-full">
        <HomeHeader />
      </div>
      <div className="color-bg-subtle container-lg">
        <ShapeDivider placement="bottom" />
        <div className="color-bg-default">
          <div className="container-lg p-responsive width-full">
            <div
              className="blankslate blankslate-large d-flex flex-column flex-items-center"
              style={{ gap: 14 }}
            >
              <h3 className="h1">Al precio y tiempo que necesitas</h3>
              <p className="f4 text-bold" style={{ maxWidth: '500px' }}>
                Nuestra tecnología permite evaluar con certeza el valor de
                mercado de tu vivienda y el tiempo esperado de venta. Te guiamos
                para que cumplas tus expectativas.
              </p>
              <a href="" className="pt-1 f4">
                comenzar
              </a>
            </div>
          </div>
        </div>
        <ShapeDivider placement="top" />
        <div className="pt-5 pb-5">
          <h3 className="h1 text-center pb-6">Qué ofrece Nexun</h3>
          <div className="container container-xl">
            <div className="container-fluid container-lg">
              <div className="container d-flex flex-wrap flex-justify-around">
                {ownerHowItWorks.map((item) => (
                  <div
                    key={item.title}
                    className="Box Box--spacious p-6 text-center mb-7 anim-fade-in col-12 col-md-5 color-shadow-large d-flex flex-column flex-items-center"
                    style={{ gap: 10 }}
                  >
                    {item.logo}
                    <h4 className="h2">{item.title}</h4>
                    <p className="f4">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ShapeDivider placement="bottom" /> */}
      <div className="pt-6 pb-5 color-bg-default">
        <div className="container-xl p-responsive width-full">
          <div className="d-flex flex-column flex-items-center pb-6">
            <h3 className="h1 text-center">Tipos de Servicios</h3>
            <p className="f4">
              Establecemos comisiones dinámicas según el precio objetivo marcado
              por el mercado y tu expectativas.
            </p>
            <a href="" className="pt-1 f4">
              comenzar
            </a>
          </div>
          <div
            className="container-fluid container-lg d-flex flex-column flex-md-row flex-items-center flex-justify-around"
            style={{ gap: 18 }}
          >
            {serviceTypes.map((service) => {
              return (
                <div
                  className="d-flex flex-column flex-items-center p-4 rounded flex-justify-between text-bold"
                  style={{
                    width: '280px',
                    height: '420px',
                    backgroundColor: '#D0E2D7',
                  }}
                >
                  <h3 className="h2">{service.title}</h3>
                  {service.additional && (
                    <p className="text-center f4">
                      {service.additional} <br />+
                    </p>
                  )}
                  <ul className="f4">
                    <li>{service.firstItem}</li>
                    <li>{service.secondItem}</li>
                    <li>{service.thirdItem}</li>
                    <li>{service.fourthItem}</li>
                    {service.fifthItem && <li>{service.fifthItem}</li>}
                  </ul>

                  <p className="text-center h2">{service.comision}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="container-lg p-responsive width-full py-6">
        <div className="d-flex flex-column flex-items-center">
          <h3 className="blankslate-heading h1 text-bold text-center">
            Cómo funciona
          </h3>

          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
            className="pt-5"
          >
            {ownerSteps.map((step) => {
              return (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    {step.id < 6 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent className="f4">
                    <span className="text-bold">{step.title}</span>{' '}
                    {step.description}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
      </div>
      <HomeFooter hideDivider />
    </div>
  );
}
