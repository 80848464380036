import { NexunLogo } from 'components';
import { Link } from 'react-router-dom';

export default function NotFoundRoute() {
  return (
    <div className="d-flex flex-column flex-1 flex-items-center flex-justify-center">
      <div className="d-flex flex-items-center mb-3">
        <span className="h1-mktg color-fg-subtle">4</span>
        <NexunLogo className="mx-1" size="large" onlyIcon muted />
        <span className="h1-mktg color-fg-subtle">4</span>
      </div>
      <h3 className="h4-mktg mb-2">Página no encontrada</h3>
      <p className="color-fg-subtle">
        La página que está buscando no existe o ha dejado de existir,{' '}
        <Link to="/">volver a la página de inicio</Link>.
      </p>
    </div>
  );
}
