import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { BUYER_QUERY } from 'apollo/queries';
import UserAvatar from 'components/UserAvatar';
import NexunLogo from 'components/NexunLogo';
import { CheckIcon, XIcon } from '@primer/octicons-react';

export default function ClientView() {
  const { id, loading } = useParams();
  const buyer = useQuery(BUYER_QUERY, {
    variables: { id },
  }).data?.buyerById;

  console.log(buyer);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
          <NexunLogo
            className="d-flex flex-justify-center anim-rotate"
            onlyIcon={true}
          />
          <label className="text-center">Cargando...</label>
        </div>
      </div>
    );
  }

  if (buyer) {
    return (
      <div className="py-3">
        <div className="col-12 p-responsive mx-auto text-center">
          <div className="Layout">
            <div className="Layout-main">
              <div className="d-flex flex-justify-center">
                <h3>
                  Aquí aparecerá ultimos movimientos, acciones, pagos, etc.
                </h3>
              </div>
            </div>

            <div className="Layout-sidebar text-center position-relative border-right pr-3">
              <div className="d-flex flex-justify-center ">
                <UserAvatar
                  url={buyer.avatarURL}
                  username={buyer.firstName}
                  size="ultra"
                  hideOnlineIndicator
                />
              </div>
              <h2>{buyer.firstName + ' ' + buyer.lastName}</h2>
              <p>{buyer.email}</p>
              <div>
                {buyer.isEmailVerificated && (
                  <div className="flash p-1 mt-2 flash-success">
                    <CheckIcon className="color-fg-success" size={16} />
                    <span>Email verificado</span>
                  </div>
                )}
                {!buyer.isEmailVerificated && (
                  <div className="flash p-1 mt-2 flash-error">
                    <XIcon className="color-fg-danger" size={16} />
                    <span>Email sin verificar</span>
                  </div>
                )}
              </div>
              <p className="border-top mt-3 pt-3 text-left">
                <label>Teléfono: </label> {buyer.phone}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
