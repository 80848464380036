import ScheduleTimeZone from 'components/ScheduleTimeZone';
import { MapPlatform } from 'components';
import { useAuth } from 'context/auth.context';
import { useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { useMutation } from '@apollo/client';
import { UPDATE_AGENT_MUTATION } from 'apollo/mutations';
import { useToasts } from 'react-toast-notifications';

export default function AvailableSettingsRoute() {
  const { actor } = useAuth();
  const { addToast } = useToasts();
  const [range, setRange] = useState(actor.activityLocation?.kmRange || 1);
  const [place, setPlace] = useState(
    actor.activityLocation?.place
      ? {
          placeId: actor.activityLocation.place.placeId,
          formattedAddress: actor.activityLocation.place.formattedAddress,
          geometry: {
            location: {
              lat: actor.activityLocation.place.geometry.location.lat,
              lng: actor.activityLocation.place.geometry.location.lng,
            },
            viewport: {
              northeast: {
                lat: actor.activityLocation.place.geometry.viewport.northeast
                  .lat,
                lng: actor.activityLocation.place.geometry.viewport.northeast
                  .lng,
              },
              southwest: {
                lat: actor.activityLocation.place.geometry.viewport.southwest
                  .lat,
                lng: actor.activityLocation.place.geometry.viewport.southwest
                  .lng,
              },
            },
          },
          name: actor.activityLocation.place.name,
        }
      : undefined,
  );

  const [updateAgent, { loading }] = useMutation(UPDATE_AGENT_MUTATION, {
    refetchQueries: ['Actor'],
    onError: (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    },
  });

  const handleAddDateRange = (range) => {
    updateAgent({
      variables: {
        filter: { _id: actor._id },
        record: {
          availableDateRanges: [
            ...actor.availableDateRanges
              .filter((r) => {
                if (r.dayOfWeek === range.dayOfWeek) {
                  return (
                    r.hourRange.start !== range.hourRange.start &&
                    r.hourRange.end !== range.hourRange.end
                  );
                }
                return true;
              })
              .map((r) => ({
                dayOfWeek: r.dayOfWeek,
                hourRange: {
                  start: r.hourRange.start,
                  end: r.hourRange.end,
                },
              })),
            range,
          ],
        },
      },
    });
  };

  const handleRemoveDateRange = (range) => {
    updateAgent({
      variables: {
        filter: { _id: actor._id },
        record: {
          availableDateRanges: actor.availableDateRanges
            .filter((r) => {
              if (r.dayOfWeek === range.dayOfWeek) {
                return (
                  r.hourRange.start !== range.hourRange.start &&
                  r.hourRange.end !== range.hourRange.end
                );
              }
              return true;
            })
            .map((r) => ({
              dayOfWeek: r.dayOfWeek,
              hourRange: {
                start: r.hourRange.start,
                end: r.hourRange.end,
              },
            })),
        },
      },
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    updateAgent({
      variables: {
        filter: { _id: actor._id },
        record: {
          activityLocation: {
            kmRange: range,
            place,
          },
        },
      },
    }).then(() => {
      addToast('Zona de trabajo actualizada', {
        appearance: 'success',
        autoDismiss: true,
      });
    });
  };

  return (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead mb-0">
          <h2 className="Subhead-heading">Disponibilidad</h2>
        </div>
        {/* TODO: CREATE FORM REQUEST */}
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <div className="form-group-header">
              <label htmlFor="name">Horario de trabajo</label>
            </div>
            <div className="form-group-body">
              <ScheduleTimeZone
                values={actor.availableDateRanges}
                onSelect={handleAddDateRange}
                onRemove={handleRemoveDateRange}
                disabled={loading}
              />
              <div className="note" style={{ maxWidth: 440 }}>
                Usaremos las franjas horarias que seleccione para enviarle
                oportunidades de trabajo según sus preferencias.
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-group-header">
              <label htmlFor="name">Zona de trabajo</label>
              <div className="form-group-body my-3 p-3">
                <InputRange
                  formatLabel={(value) => `${value} km`}
                  maxValue={15}
                  minValue={0}
                  value={range}
                  onChange={(value) => setRange(value)}
                  step={0.25}
                  style={{ width: '100%' }}
                  disabled={loading}
                />
              </div>
              <MapPlatform
                location={
                  actor.activityLocation
                    ? actor.activityLocation.place.geometry.location
                    : undefined
                }
                range={range}
                zoom={14.7}
                height={"400px"}
                // useCurrentLocation={!actor.activityLocation}
                onChange={setPlace}
              />
            </div>
            <div className="note pb-4" style={{ maxWidth: 440 }}>
              La zona de trabajo determina la distancia máxima que puede
              desplazarse para realizar un trabajo.
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading || !place}
            >
              {loading
                ? 'Actualizando zona de trabajo…'
                : 'Actualizar zona de trabajo'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
