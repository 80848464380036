import location from '../../images/property/icons/location.svg';

export default function VisitSection({
  town,
  place,
  handleOpen,
  timeVisit,
  dateVisit,
}) {
  if (!town || !place) return null;
  if (!handleOpen) return null;
  if (!timeVisit || !dateVisit) return null;

  return (
    <div
      className="d-flex flex-justify-center mt-4 border-y py-4"
      style={{ gap: '1.2rem' }}
    >
      <button type="submit" className="btn btn-primary" onClick={handleOpen}>
        <span className="d-block f2">Quiero visitar</span>
        <span className="d-block f4 mt-2 text-normal">
          A partir de {dateVisit} a las {timeVisit}
        </span>
      </button>

      <div className="d-flex flex-items-center" style={{ gap: '.5rem' }}>
        <img src={location} alt="Location icon" />
        <p className="f2 text-bold">{town}</p>
      </div>
    </div>
  );
}
