import { BellFillIcon } from '@primer/octicons-react';

export default function NotificationsRoute() {
  return (
    <div className="container-md width-full">
      <h1 className="h3 mb-4">Notificaciones</h1>

      <div className="container-md width-full">
        <div className="p-responsive py-3 color-bg-default rounded-2 border">
          <div className="blankslate">
            <BellFillIcon size={35} className="mb-2" />
            <h3 className="blankslate-heading">
              Nada por aquí... nada por allá...
            </h3>
            <p>
              De momento no tienes ninguna notificación. Cuando tengas alguna
              notificación, aparecerá aquí.
            </p>
          </div>
        </div>
      </div>

      {/* EXAMPLE TYPE OF NOTIFICATION */}
      {/* <div className="Box mt-4">
        <div className="Box-row Box-row--gray Box-row--unread">
          <div className="d-flex flex-justify-start flex-align-center">
            <OrganizationIcon size={20} />
            <p className="overflow-hidden pl-3">
              Nueva propiedad disponible en <a href="#">Calle de la Paz 1</a> a
              5 km de tu ubicación.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
