import { NexunLogo } from 'components';
import { NavLink } from 'react-router-dom';
import { useMainImage, useDescriptionText } from 'hooks';

export default function PropertyCard({
  elements,
  loading,
  isAgent = false,
  isPromotions = false,
  individual = false,
  element = null,
  className = 'd-inline-block col-12 color-fg-muted no-underline',
}) {
  const numberFormat = (value) =>
    new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);

  const TakeImage = (images) => {
    const image = useMainImage(images);
    return image.url;
  };

  const TakeDescription = (description, language) => {
    const descriptionText = useDescriptionText(description);
    return descriptionText;
  };

  const TakeMinimunNumberOfBathrooms = (arrayProperties) => {
    const arrayBathrooms = arrayProperties.map((property) => {
      return property.features.bathroomNumber;
    });
    return Math.min(...arrayBathrooms);
  };

  const TakeMinimunNumberOfBedrooms = (arrayProperties) => {
    const arrayBedrooms = arrayProperties.map((property) => {
      return property.features.bedroomNumber;
    });
    return Math.min(...arrayBedrooms);
  };

  const TakeMinimunNumberOfAreaUsable = (arrayProperties) => {
    const arrayAreaUsable = arrayProperties.map((property) => {
      return property.features.areaUsable;
    });
    return Math.min(...arrayAreaUsable);
  };

  const TakeMinimunNumberOfOperationPrice = (arrayProperties) => {
    const arrayOperationPrice = arrayProperties.map((property) => {
      return property.operation.price;
    });
    return Math.min(...arrayOperationPrice);
  };

  const isNovedad = (date) => {
    // Compruebo que la fecha de creacion es menor a 30 dias
    const dateNow = new Date();
    const dateCreated = new Date(date);
    const diffTime = Math.abs(dateNow - dateCreated);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 30) {
      return true;
    } else {
      return false;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
          <NexunLogo
            className="d-flex flex-justify-center anim-rotate"
            onlyIcon={true}
          />
          <label className="text-center">Cargando...</label>
        </div>
      </div>
    );
  }

  if (isPromotions) {
    return elements.map((promotion) => (
      <NavLink
        className="d-inline-block col-12 col-md-6 color-fg-muted no-underline p-3"
        to={'/promotions/' + promotion._id}
        key={promotion._id}
      >
        <nav className="Box Box--spacious text-center m-1 mb-2 position-relative">
          <span className="border color-bg-emphasis rounded-2 mt-1 mr-1 color-fg-on-emphasis p-1 position-absolute top-0 right-0">
            Promoción
          </span>
          <img
            src={TakeImage(promotion.properties[0].images)}
            alt=""
            className="img-responsive"
            style={{
              width: '100%',
              height: '140px',
              objectFit: 'cover',
              objectPosition: 'center',
              borderTopRightRadius: '5px',
              borderTopLeftRadius: '5px',
            }}
          />
          <h2 className="f3">{promotion.name}</h2>
          <h3 className="f3-light">
            Desde{' '}
            {numberFormat(
              TakeMinimunNumberOfOperationPrice(promotion.properties),
            )}
          </h3>
          <div className="text-light h6 text-center pt-0 mt-0">
            Desde {TakeMinimunNumberOfBedrooms(promotion.properties)}{' '}
            habitaciones · Desde{' '}
            {TakeMinimunNumberOfBathrooms(promotion.properties)} baños · Desde{' '}
            {TakeMinimunNumberOfAreaUsable(promotion.properties)} m²
          </div>
          <div className="Box-body">
            <div
              className="text-light h6 color-fg-muted "
              style={{
                maxWidth: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {/* TODO: SEND USER DEFAULT LANGUAJE TO TAKE DESCRIPTION FUNCTION */}
              {TakeDescription(promotion.descriptions)}
            </div>
          </div>
        </nav>
      </NavLink>
    ));
  }

  if (individual) {
    if (element === null) {
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
            <NexunLogo
              className="d-flex flex-justify-center anim-rotate"
              onlyIcon={true}
            />
            <label className="text-center">Cargando...</label>
          </div>
        </div>
      );
    }
    return (
      <NavLink className={className} to={'/properties/' + element?._id}>
        <nav className="text-center m-1 mb-2 position-relative">
          <img
            src={TakeImage(element?.images)}
            alt=""
            className="img-responsive"
            style={{
              width: '100%',
              height: '150px',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
          <h2 className="f3">{element?.title}</h2>
          <h3 className="f3-light">
            {numberFormat(element?.operation?.price)}
          </h3>
          <div className="text-light h6 text-center pt-0 mt-0">
            {element?.features?.bedroomNumber} habitaciones ·{' '}
            {element?.features?.bathroomNumber} baños ·{' '}
            {element?.features?.areaUsable} m²
          </div>
          <div className="Box-body border-0">
            <div
              className="text-light h6 color-fg-muted "
              style={{
                maxWidth: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {/* TODO: SEND USER DEFAULT LANGUAJE TO TAKE DESCRIPTION FUNCTION */}
              {TakeDescription(element?.descriptions)}
            </div>
          </div>
          <p className="Box-footer p-1">{element?.place?.formattedAddress}</p>
        </nav>
      </NavLink>
    );
  }

  return elements.map((property) =>
    isAgent ? (
      <NavLink
        className="anim-fade-in no-underline Link--primary "
        to={'/properties/' + property._id}
      >
        <a
          className="Box-row d-flex menu-item p-3 color-shadow-large color-bg-default mb-3"
          href={'/properties/' + property._id}
        >
          <div className="col-4">
            <img
              src={TakeImage(property.images)}
              alt=""
              className="img-responsive"
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: '5px',
                border: '1px solid #e1e4e8',
              }}
            />
          </div>
          <div className="col-8">
            <div className="d-flex flex-justify-between">
              <div className="pl-2 h4 mb-0">{property.title}</div>
              <div className="pl-2 h6-mktg">
                {numberFormat(property.operation.price)}
              </div>
            </div>
            <div className="pl-2 text-light mb-2">
              {property.addressStreetName}
            </div>
            <div
              className="pl-2 text-light h6 color-fg-muted"
              style={{
                maxWidth: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {TakeDescription(property.descriptions)}
            </div>
            <div className="pl-2 text-light h6 pt-2 d-flex flex-justify-end">
              {property.features.bedroomNumber} habitaciones ·{' '}
              {property.features.bathroomNumber} baños ·{' '}
              {property.features.areaUsable} m²
            </div>
          </div>
        </a>
      </NavLink>
    ) : (
      <NavLink
        className="d-inline-block col-12 col-md-6 dark-text no-underline p-2"
        to={'/properties/' + property._id}
      >
        <nav className="Box Box--spacious text-center m-1 mb-2 position-relative">
          {isNovedad(property.createdAt) && (
            <span className="border color-bg-emphasis rounded-2 mt-1 mr-1 color-fg-on-emphasis p-1 position-absolute top-0 right-0">
              Novedad
            </span>
          )}
          <img
            src={TakeImage(property.images)}
            alt=""
            className="img-responsive"
            style={{
              width: '100%',
              height: '140px',
              objectFit: 'cover',
              objectPosition: 'center',
              borderTopRightRadius: '5px',
              borderTopLeftRadius: '5px',
            }}
          />
          <h3 className="f3 pt-1">{property.title}</h3>
          <h3 className="f3-light py-1">
            {numberFormat(property.operation.price)}
          </h3>
          <div className="text-light h6 text-center">
            {property.features.bedroomNumber} habitaciones ·{' '}
            {property.features.bathroomNumber} baños ·{' '}
            {property.features.areaUsable} m²
          </div>
          <div className="Box-body">
            <div
              className="f5"
              style={{
                maxWidth: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {/* TODO: SEND USER DEFAULT LANGUAJE TO TAKE DESCRIPTION FUNCTION */}
              {TakeDescription(property.descriptions)}
            </div>
          </div>
        </nav>
      </NavLink>
    ),
  );
}
