import { Collapse } from '@mui/material';
import { ChevronDownIcon, ChevronUpIcon } from '@primer/octicons-react';
import { HomeFooter, HomeHeader, ShapeDivider } from 'components';
import { useMemo, useState } from 'react';
import { guidelineData } from 'utils/guideline-data';

export default function GuidelineRoute() {
  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle">
      <div className="container-lg p-responsive width-full">
        <HomeHeader />
      </div>
      <ShapeDivider placement="bottom" />
      <div className="color-bg-default">
        <div className="container-lg p-responsive width-full">
          <div className="blankslate blankslate-large">
            <h2 className="h5-mktg lh-condensed mb-0 color-fg-subtle">Guía</h2>
            <h3 className="blankslate-heading h3-mktg mb-0">
              Cómo trabajar en nexun
            </h3>
            <h4 className="f4-mktg mt-4 color-fg-muted">
              En esta guía de 5 pasos explicamos qué necesitas para convertite
              en agente nexun y comenzar a trabajar
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="container-lg p-responsive width-full">
        <div className="py-6">
          {guidelineData.map((category, index) => (
            <FaqCategory key={category.id} category={category} index={index} />
          ))}
        </div>
      </div>
      <p className="text-center ">
        Si tienes dudas del proceso, lo hacemos juntos. Puedes escribirnos a
        info@nexun.es o llamándonos al teléfono +34 911 97 85 81.
      </p>
      <HomeFooter />
    </div>
  );
}

const FaqCategory = ({ category, index }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  return (
    <div className="TimelineItem pb-5">
      <div className="TimelineItem-badge mt-2">{category.id}</div>
      <div className="TimelineItem-body">
        <div key={category.id}>
          <div className={index !== 0 ? 'Subhead' : 'Subhead'}>
            <h2 className="Subhead-heading">{category.title}</h2>
          </div>
          {category.items.map((item) => (
            <FaqQuestion
              key={item.id}
              item={item}
              expandedId={expandedQuestion}
              onExpand={setExpandedQuestion}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const FaqQuestion = ({ item, expandedId, onExpand }) => {
  const expanded = useMemo(() => expandedId === item.id, [expandedId, item.id]);

  return (
    <div
      role="button"
      className="color-bg-default color-shadow-medium rounded-2 my-3 py-3 px-4 cursor-pointer user-select-none"
      onClick={() => onExpand((prev) => (prev === item.id ? null : item.id))}
    >
      <div className="d-flex flex-items-start flex-justify-between">
        <p className="f4 text-bold flex-1 mb-0">{item.question}</p>
        <button type="button" className="btn-octicon">
          {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </button>
      </div>
      <Collapse in={expanded}>
        <p className="color-fg-muted mt-2">{item.answer}</p>
      </Collapse>
    </div>
  );
};
