export default function AdminHomeRoute() {
  const greeting = () => {
    const hour = new Date().getHours();
    if (hour >= 6 && hour < 12) {
      return 'Buenos días';
    }
    if (hour >= 12 && hour < 20) {
      return 'Buenas tardes';
    }
    return 'Buenas noches';
  };

  return (
    <div>
      <div className="Box rounded-top-0">
        <div className="blankslate">
          <div className="h1 d-flex flex-justify-center pb-2">👋</div>
          <h1 className="h2 blankslate-heading">{greeting()}, Admin</h1>
          <p>
            Bienvenido a tu panel de control. Aquí podras controlar todo lo que
            sucede en la plataforma. Las estadisticas, los usuarios, los
            servicios, los pagos, los reportes, etc. ¡Esperamos que te guste!
          </p>
        </div>
      </div>
    </div>
  );
}
