import { HomeHeader, PropertyCard, Pagination, NexunLogo } from 'components';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PROPERTIES_QUERY } from 'apollo/queries';
import { PROMOTIONS_QUERY } from 'apollo/queries';
import { CheckIcon } from '@primer/octicons-react';
import { newProperties } from 'utils/properties';

export default function PropertiesRoute() {
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [indexOfLastProperty, setIndexOfLastProperty] = useState(0);
  const [indexOfFirstProperty, setIndexOfFirstProperty] = useState(0);
  const [currentProperties, setCurrentProperties] = useState([]);
  const [lengthOfItems, setLengthOfItems] = useState(0);
  const [typeOfItems, setTypeOfItems] = useState('properties');
  const [typeOfPropertyCard, setTypeOfPropertyCard] = useState(false);

  const { data, loading } = useQuery(PROPERTIES_QUERY, {
    variables: {
      sort: '_ID_DESC',
    },
  });
  const promotionsData = useQuery(PROMOTIONS_QUERY).data?.promotions;

  useEffect(() => {
    if (typeOfItems === 'promotions') {
      setTypeOfPropertyCard(true);
      const totalPages = Math.ceil(promotionsData.length / propertiesPerPage);
      setTotalPages(totalPages);
      setIndexOfFirstProperty(currentPage * propertiesPerPage);
      setIndexOfLastProperty(indexOfFirstProperty - propertiesPerPage);
      setLengthOfItems(promotionsData.length);
      setCurrentProperties(
        promotionsData.slice(indexOfLastProperty, indexOfFirstProperty),
      );
    }
  }, [
    promotionsData,
    currentPage,
    propertiesPerPage,
    indexOfFirstProperty,
    indexOfLastProperty,
    typeOfItems,
  ]);

  useEffect(() => {
    if (data?.properties && typeOfItems === 'properties') {
      setTypeOfPropertyCard(false);
      const totalPages = Math.ceil(data.properties.length / propertiesPerPage);
      setTotalPages(totalPages);
      setIndexOfFirstProperty(currentPage * propertiesPerPage);
      setIndexOfLastProperty(indexOfFirstProperty - propertiesPerPage);
      setLengthOfItems(data.properties.length);
      setCurrentProperties(
        data.properties.slice(indexOfLastProperty, indexOfFirstProperty),
      );
    }
  }, [
    data,
    currentPage,
    propertiesPerPage,
    indexOfFirstProperty,
    indexOfLastProperty,
    typeOfItems,
  ]);

  if (loading)
    return (
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-lg p-responsive width-full">
          <HomeHeader />
        </div>
        <div className="container-lg p-responsive width-full">
          <div className="container-lg">
            <div className="Layout-main col-12">
              <div className="Subhead">
                <h2 className="Subhead-heading"> Buscando...</h2>
              </div>
              <div className="flex items-center justify-center h-screen">
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
                  <NexunLogo
                    className="d-flex flex-justify-center anim-rotate"
                    onlyIcon={true}
                  />
                  <label className="text-center">Cargando...</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle ">
      <div className="container-lg p-responsive width-full">
        <HomeHeader />
      </div>
      <div className="container-lg p-responsive Subhead width-full">
        <h2 className="Subhead-heading">{lengthOfItems} resultados</h2>
      </div>
      <div className="container-lg p-responsive width-full Layout">
        {/* TODO: REQUEST SEARCH PARAMS */}
        <div className="Layout-sidebar ">
          <div
            className="position-md-sticky color-bg-default rounded-2 color-shadow-large"
            style={{ top: 24 }}
          >
            <form className="Box">
              <div className="Box-row">
                <h3 className="text-center h2">Búsqueda</h3>
              </div>
              <div className="form-group pl-3 pb-2 Box-row  mt-0">
                <div className="form-group-header">
                  <span className="h5">Tipo de propiedad</span>
                </div>
                <div className="form-group-body">
                  <select
                    className="form-select col-11"
                    aria-label="Preference"
                    onChange={(e) => setTypeOfItems(e.target.value)}
                  >
                    <option value={'properties'} name="properties">
                      Propiedades
                    </option>
                    <option value={'promotions'} name="promotions">
                      Promociones
                    </option>
                  </select>
                </div>
              </div>
              {/* <div className="form-group pb-2">
                <div className="form-group-header">
                  <span className="h5 pl-3">Precio</span>
                </div>
                <div className="form-group-body d-flex flex-justify-around mx-2">
                  <select className="form-select col-5" aria-label="Preference">
                    <option>Min</option>
                    <option value={60000}>60.000</option>
                    <option value={80000}>80.000</option>
                    <option value={100000}>100.000</option>
                    <option value={120000}>120.000</option>
                    <option value={140000}>140.000</option>
                    <option value={160000}>160.000</option>
                    <option value={180000}>180.000</option>
                    <option value={200000}>200.000</option>
                    <option value={220000}>220.000</option>
                    <option value={240000}>240.000</option>
                    <option value={260000}>260.000</option>
                    <option value={280000}>280.000</option>
                    <option value={300000}>300.000</option>
                    <option value={320000}>320.000</option>
                    <option value={340000}>340.000</option>
                    <option value={360000}>360.000</option>
                    <option value={380000}>380.000</option>
                    <option value={400000}>400.000</option>
                    <option value={450000}>450.000</option>
                    <option value={500000}>500.000</option>
                    <option value={550000}>550.000</option>
                    <option value={600000}>600.000</option>
                    <option value={650000}>650.000</option>
                    <option value={700000}>700.000</option>
                    <option value={750000}>750.000</option>
                    <option value={800000}>800.000</option>
                    <option value={850000}>850.000</option>
                    <option value={900000}>900.000</option>
                    <option value={950000}>950.000</option>
                    <option value={1000000}>1 millón</option>
                    <option value={1100000}>1,1 millón</option>
                    <option value={1200000}>1,2 millones</option>
                    <option value={1300000}>1,3 millones</option>
                    <option value={1400000}>1,4 millones</option>
                    <option value={1500000}>1,5 millones</option>
                    <option value={1600000}>1,6 millones</option>
                    <option value={1700000}>1,7 millones</option>
                    <option value={1800000}>1,8 millones</option>
                    <option value={1900000}>1,9 millones</option>
                    <option value={2000000}>2 millones</option>
                    <option value={2100000}>2,1 millón</option>
                    <option value={2200000}>2,2 millones</option>
                    <option value={2300000}>2,3 millones</option>
                    <option value={2400000}>2,4 millones</option>
                    <option value={2500000}>2,5 millones</option>
                    <option value={2600000}>2,6 millones</option>
                    <option value={2700000}>2,7 millones</option>
                    <option value={2800000}>2,8 millones</option>
                    <option value={2900000}>2,9 millones</option>
                    <option value={3000000}>3 millones</option>
                    <option value={3000001}>+ 3 millones</option>
                  </select>
                  <select className="form-select col-5" aria-label="Preference">
                    <option>Max</option>
                    <option value={60000}>60.000</option>
                    <option value={80000}>80.000</option>
                    <option value={100000}>100.000</option>
                    <option value={120000}>120.000</option>
                    <option value={140000}>140.000</option>
                    <option value={160000}>160.000</option>
                    <option value={180000}>180.000</option>
                    <option value={200000}>200.000</option>
                    <option value={220000}>220.000</option>
                    <option value={240000}>240.000</option>
                    <option value={260000}>260.000</option>
                    <option value={280000}>280.000</option>
                    <option value={300000}>300.000</option>
                    <option value={320000}>320.000</option>
                    <option value={340000}>340.000</option>
                    <option value={360000}>360.000</option>
                    <option value={380000}>380.000</option>
                    <option value={400000}>400.000</option>
                    <option value={450000}>450.000</option>
                    <option value={500000}>500.000</option>
                    <option value={550000}>550.000</option>
                    <option value={600000}>600.000</option>
                    <option value={650000}>650.000</option>
                    <option value={700000}>700.000</option>
                    <option value={750000}>750.000</option>
                    <option value={800000}>800.000</option>
                    <option value={850000}>850.000</option>
                    <option value={900000}>900.000</option>
                    <option value={950000}>950.000</option>
                    <option value={1000000}>1 millón</option>
                    <option value={1100000}>1,1 millón</option>
                    <option value={1200000}>1,2 millones</option>
                    <option value={1300000}>1,3 millones</option>
                    <option value={1400000}>1,4 millones</option>
                    <option value={1500000}>1,5 millones</option>
                    <option value={1600000}>1,6 millones</option>
                    <option value={1700000}>1,7 millones</option>
                    <option value={1800000}>1,8 millones</option>
                    <option value={1900000}>1,9 millones</option>
                    <option value={2000000}>2 millones</option>
                    <option value={2100000}>2,1 millón</option>
                    <option value={2200000}>2,2 millones</option>
                    <option value={2300000}>2,3 millones</option>
                    <option value={2400000}>2,4 millones</option>
                    <option value={2500000}>2,5 millones</option>
                    <option value={2600000}>2,6 millones</option>
                    <option value={2700000}>2,7 millones</option>
                    <option value={2800000}>2,8 millones</option>
                    <option value={2900000}>2,9 millones</option>
                    <option value={3000000}>3 millones</option>
                    <option value={3000001}>+ 3 millones</option>
                  </select>
                </div>
              </div>
              <div className="form-group pb-2">
                <div className="form-group-header">
                  <span className="h5 pl-3">Tamaño</span>
                </div>
                <div className="form-group-body d-flex flex-justify-around mx-2">
                  <select
                    className="form-select col-5"
                    aria-label="Preference"
                    name="min-featuresAreaUsable"
                  >
                    <option>Min</option>
                    <option value={40}>40 m2</option>
                    <option value={60}>60 m2</option>
                    <option value={80}>80 m2</option>
                    <option value={100}>100 m2</option>
                    <option value={120}>120 m2</option>
                    <option value={140}>140 m2</option>
                    <option value={160}>160 m2</option>
                    <option value={180}>180 m2</option>
                    <option value={200}>200 m2</option>
                    <option value={250}>250 m2</option>
                    <option value={300}>300 m2</option>
                    <option value={350}>350 m2</option>
                    <option value={400}>400 m2</option>
                    <option value={450}>450 m2</option>
                    <option value={500}>500 m2</option>
                    <option value={600}>600 m2</option>
                    <option value={700}>700 m2</option>
                    <option value={800}>800 m2</option>
                    <option value={900}>900 m2</option>
                    <option value={901}>Sin límite</option>
                  </select>
                  <select
                    className="form-select col-5"
                    aria-label="Preference"
                    name="max-featuresAreaUsable"
                  >
                    <option>Max</option>
                    <option value={40}>40 m2</option>
                    <option value={60}>60 m2</option>
                    <option value={80}>80 m2</option>
                    <option value={100}>100 m2</option>
                    <option value={120}>120 m2</option>
                    <option value={140}>140 m2</option>
                    <option value={160}>160 m2</option>
                    <option value={180}>180 m2</option>
                    <option value={200}>200 m2</option>
                    <option value={250}>250 m2</option>
                    <option value={300}>300 m2</option>
                    <option value={350}>350 m2</option>
                    <option value={400}>400 m2</option>
                    <option value={450}>450 m2</option>
                    <option value={500}>500 m2</option>
                    <option value={600}>600 m2</option>
                    <option value={700}>700 m2</option>
                    <option value={800}>800 m2</option>
                    <option value={900}>900 m2</option>
                    <option value={901}>Sin límite</option>
                  </select>
                </div>
              </div>
              <div className="form-group pb-2">
                <div className="form-group-header">
                  <span className="h5 pl-3">Habitaciones</span>
                </div>
                <div className="form-group-body pl-3">
                  <div className="radio-group d-flex flex-column">
                    <label className="d-flex flex-align-center">
                      <input type="radio" name="featuresBedroomNumber" />
                      <span className="pl-2 text-light">0 (Estudio)</span>
                    </label>
                    <label>
                      <input type="radio" name="featuresBedroomNumber" />
                      <span className="pl-2 text-light">1 </span>
                    </label>
                    <label>
                      <input type="radio" name="featuresBedroomNumber" />
                      <span className="pl-2 text-light">2 </span>
                    </label>
                    <label>
                      <input type="radio" name="featuresBedroomNumber" />
                      <span className="pl-2 text-light">3 </span>
                    </label>
                    <label>
                      <input type="radio" name="featuresBedroomNumber" />
                      <span className="pl-2 text-light">4 o más</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group pb-2">
                <div className="form-group-header">
                  <span className="h5 pl-3">Baños</span>
                </div>
                <div className="form-group-body pl-3">
                  <div className="radio-group d-flex flex-column">
                    <label>
                      <input type="radio" name="featuresBathroomNumber" />
                      <span className="pl-2 text-light">1 </span>
                    </label>
                    <label>
                      <input type="radio" name="featuresBathroomNumber" />
                      <span className="pl-2 text-light">2 </span>
                    </label>
                    <label>
                      <input type="radio" name="featuresBathroomNumber" />
                      <span className="pl-2 text-light">3 o más </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group pb-2">
                <div className="form-group-header">
                  <span className="h5 pl-3">Caracteristicas</span>
                </div>
                <div className="form-group-body pl-3 d-flex flex-column">
                  <label>
                    <input type="radio" name="fet" />
                    <span className="pl-2 text-light">Aire acondicionado</span>
                  </label>
                  <label>
                    <input type="radio" name="featuresLiftAvailable" />
                    <span className="pl-2 text-light">Ascensor</span>
                  </label>
                  <label>
                    <input type="radio" name="featuresParkingAvailable" />
                    <span className="pl-2 text-light">Garaje</span>
                  </label>
                  <label>
                    <input type="radio" name="featuresPool" />
                    <span className="pl-2 text-light">Piscina</span>
                  </label>
                  <label>
                    <input type="radio" name="featuresTerrace" />
                    <span className="pl-2 text-light">Terraza</span>
                  </label>
                  <label>
                    <input type="radio" name="featuresGarden" />
                    <span className="pl-2 text-light">Jardín </span>
                  </label>
                  <label>
                    <input type="radio" name="featuresHandicapAdaptedAccess" />
                    <span className="pl-2 text-light">Vivienda accesible</span>
                  </label>
                  <label>
                    <input type="radio" name="featuresWardrobes" />
                    <span className="pl-2 text-light">Armarios empotrados</span>
                  </label>
                </div>
              </div>
              <div className="Box-row">
                <button
                  type="button"
                  name="Create an organization"
                  className="btn btn-primary btn-block"
                >
                  Aplicar filtros
                </button>
              </div> */}
            </form>
          </div>
        </div>
        <div className="Layout-main ">
          {/* <div className="Subhead">
            <h2 className="Subhead-heading">{lengthOfItems} resultados</h2>
            <div className="Subhead-actions">
              <div className="d-flex flex-justify-end position-relative mr-5">
                <details className="details-reset details-overlay">
                  <summary className="btn" aria-haspopup="false">
                    Ordenar por
                    <span className="dropdown-caret"></span>
                  </summary>
                  <div className="SelectMenu right-0">
                    <div className="SelectMenu-modal">
                      <div className="SelectMenu-list">
                        <button
                          className="SelectMenu-item"
                          role="menuitemcheckbox"
                          aria-checked="true"
                        >
                          <CheckIcon className="mr-2" />
                          Recientes
                        </button>
                        <button className="SelectMenu-item" role="menuitem">
                          Precio más bajo primero
                        </button>
                        <button className="SelectMenu-item" role="menuitem">
                          Precio más alto primero
                        </button>
                        <button className="SelectMenu-item" role="menuitem">
                          Más grande primero
                        </button>
                        <button className="SelectMenu-item" role="menuitem">
                          Más pequeño primero
                        </button>
                        <button className="SelectMenu-item" role="menuitem">
                          Más antiguo primero
                        </button>
                        <button className="SelectMenu-item" role="menuitem">
                          Más nuevo primero
                        </button>
                      </div>
                    </div>
                  </div>
                </details>
              </div>
            </div>
          </div> */}
          <PropertyCard
            elements={currentProperties}
            loading={loading}
            isPromotions={typeOfPropertyCard}
          />
          {/*{newProperties.map((property) => (
            <NavLink
              key={property.id}
              to={`/new-property/${property.id}`}
              className="no-underline"
            >
              Propiedad Testing {property.id}
            </NavLink>
          ))}/*/}
          <Pagination
            pageInfo={{
              currentPage: currentPage,
              pageCount: totalPages,
            }}
            onPageChange={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}
