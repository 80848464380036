import { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import { HomeFooter, HomeHeader, ShapeDivider } from 'components';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { SIGNUP_MUTATION } from 'apollo/mutations';
import { agentHowItWorks, agentSteps } from 'utils/agente-data';

export default function AgentRoute() {
  const [view, setView] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const { addToast } = useToasts();

  const onCreateAgent = useCallback(
    (data) => {
      addToast(
        'Hemos enviado un correo para confirmar la cuenta a ' +
          data.signup.actor.email,
        { appearance: 'success', autoDismiss: true },
      );
    },
    [addToast],
  );

  const onCreateAgentFail = useCallback(
    (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    },
    [addToast],
  );

  const [signup, { loading }] = useMutation(SIGNUP_MUTATION, {
    onCompleted: onCreateAgent,
    onError: onCreateAgentFail,
  });

  const createAgent = (event) => {
    event.preventDefault();
    if (referredBy === '') {
      signup({
        variables: {
          email,
          firstName,
          password,
          actorType: 'AGENT',
        },
      });
    } else {
      signup({
        variables: {
          email,
          firstName,
          actorType: 'AGENT',
          password,
          referredBy,
        },
      });
    }
  };

  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle dark-text">
      <div className="container-lg width-full">
        <HomeHeader />
      </div>
      <div className="color-bg-subtle container-lg">
        <ShapeDivider placement="bottom" />
        <div className="color-bg-default">
          <div className="container-lg p-responsive width-full">
            <div
              className="blankslate blankslate-large d-flex flex-column flex-items-center"
              style={{ gap: 14 }}
            >
              <h3 className="h1">La casa y el precio que necesitas</h3>
              <p className="f4 text-bold" style={{ maxWidth: '500px' }}>
                Nuestra tecnología permite evaluar tu perfil y las propiedades
                disponiibles en el mercado para encontrar la propiedad que se
                ajusta a tus necesidades.
              </p>
              <a href="" className="pt-1 f4">
                comenzar
              </a>
            </div>
          </div>
        </div>
        <ShapeDivider placement="top" />
        <div className="pt-5 pb-5">
          <h3 className="h1 text-center pb-6">Qué ofrece Nexun</h3>
          <div className="container container-xl">
            <div className="container-fluid container-lg">
              <div className="container d-flex flex-wrap flex-justify-around">
                {agentHowItWorks.map((item) => (
                  <div
                    key={item.title}
                    className="Box Box--spacious p-6 text-center mb-7 anim-fade-in col-12 col-md-5 color-shadow-large d-flex flex-column flex-items-center"
                    style={{ gap: 10 }}
                  >
                    {item.logo}
                    <h4 className="h2">{item.title}</h4>
                    <p className="f4">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg p-responsive width-full color-bg-default py-6">
        <div className="d-flex flex-column flex-items-center">
          <h3 className="blankslate-heading h1 text-bold text-center">
            Cómo funciona
          </h3>

          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
            className="pt-5"
          >
            {agentSteps.map((step) => {
              return (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                    {step.id < 6 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent className="f4">
                    <span className="text-bold">{step.title}</span>{' '}
                    {step.description}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
      </div>
      <HomeFooter hideDivider />
    </div>
  );
}
