import { useEffect, useState } from 'react';
import {
  XIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@primer/octicons-react';
import './gallery.css';
import NexunLogo from 'components/NexunLogo';

export default function Gallery({
  galleryImages,
  galleryPlans,
  name,
  address,
  photoButton,
  videos = [],
}) {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(photoButton);
  const [quantity, setQuantity] = useState(4);
  const [showMore, setShowMore] = useState(true);
  const galleryImagesWithVideos = [...galleryImages, ...videos];

  useEffect(() => {
    if (openModal) {
      const handleKeyDown = (event) => {
        switch (event.key) {
          case 'ArrowLeft':
            setSlideNumber((slideNumber) => {
              slideNumber === 0
                ? setSlideNumber(galleryImages.length - 1)
                : setSlideNumber(slideNumber - 1);
            });
            break;
          case 'ArrowRight':
            setSlideNumber((slideNumber) => {
              slideNumber + 1 === galleryImages.length
                ? setSlideNumber(0)
                : setSlideNumber(slideNumber + 1);
            });
            break;
          case 'Escape':
            setOpenModal(false);
            break;
          default:
            break;
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [openModal, galleryImages]);

  useEffect(() => {
    if (photoButton) {
      setOpenModal(true);
    }
  }, [photoButton]);

  const isVideo = (url) => {
    if (url.includes('MOV') || url.includes('mp4') || url.includes('mov')) {
      return true;
    }
    return false;
  };

  // PREVISUALIZATION OF THE IMAGES
  const previsualizeImages = galleryImagesWithVideos
    .slice(0, quantity)
    .map((item, index) => {
      return (
        <div
          className={
            index === 0
              ? 'anim-fade-in col-12 d-inline-block px-2 py-2 single'
              : 'anim-fade-in col-4 d-inline-block px-2 py-2 single'
          }
          key={index}
          onClick={() => handleOpenModal(index)}
        >
          {isVideo(item.url) ? (
            <video
              className="col-12 "
              src={item.url}
              controls
              autoPlay
              loop
              muted
            />
          ) : (
            <img src={item.url} alt="" />
          )}
        </div>
      );
    });

  const handleMorePreview = () => {
    setQuantity(quantity + (galleryImagesWithVideos.length - quantity));
    setShowMore(false);
  };

  const handleLessPreview = () => {
    setQuantity(4);
    setShowMore(true);
  };

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(galleryImagesWithVideos.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === galleryImagesWithVideos.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <div>
      {openModal && (
        <div className="sliderWrap">
          <div className="wrapperTop">
            <button
              onClick={handleCloseModal}
              style={{
                border: 'none',
                background: 'transparent',
              }}
            >
              <h3 className="tittle f2-light">{name}</h3>
              <h3 className="subtittle f3-light">{address}</h3>
              <XIcon size={30} className="btnClose" />
            </button>
          </div>
          <button
            onClick={prevSlide}
            style={{
              border: 'none',
              background: 'transparent',
            }}
          >
            <ChevronLeftIcon size={40} className="btnPrev" />
          </button>
          <button
            onClick={nextSlide}
            style={{
              border: 'none',
              background: 'transparent',
            }}
          >
            <ChevronRightIcon size={40} className="btnNext" />
          </button>
          <div className="wraperBanner">
            <NexunLogo className="logoNexun" />
            <h3 className="photoNumber f3-light">
              {slideNumber + 1} / {galleryImagesWithVideos.length}{' '}
            </h3>
          </div>

          <div className="fullScreenImage">
            {isVideo(galleryImagesWithVideos[slideNumber].url) ? (
              <video
                src={galleryImagesWithVideos[slideNumber].url}
                controls
                muted
                autoPlay
                loop
              />
            ) : (
              <img src={galleryImagesWithVideos[slideNumber].url} alt="" />
            )}
          </div>
        </div>
      )}

      <div className="d-flex flex-wrap">{previsualizeImages}</div>
      <div className="d-flex flex-justify-center pt-3">
        {showMore && (
          <button
            className="btn-octicon"
            type="button"
            onClick={handleMorePreview}
          >
            <ChevronDownIcon />
            Ver más
          </button>
        )}
        {!showMore && (
          <button
            className="btn-octicon"
            type="button"
            onClick={handleLessPreview}
          >
            <ChevronUpIcon />
            Ver menos
          </button>
        )}
      </div>
    </div>
  );
}
