export default function Calculator({ property, numberFormat }) {
  return (
    <div className="pt-5 pb-3" style={{ backgroundColor: '#F4FDF6' }}>
      <h1
        className="p-3 px-4 mb-3 text-center container-md lh-condensed"
        style={{ fontWeight: 'bolder', fontSize: '2rem' }}
      >
        Por mucho menos de lo que te imaginas
      </h1>
      <table className="d-flex flex-column flex-items-center">
        <tbody className="f3">
          <tr>
            <td>Inmueble</td>
            <td className="pl-10 text-right">
              {numberFormat(property.operation.price)}
            </td>
          </tr>
          <tr>
            <td>Gastos de formalización</td>
            <td className="pl-10 text-right">
              {numberFormat(property.operation.formalExpenses)}
            </td>
          </tr>
          <tr>
            <td>Impuestos</td>
            <td className="pl-1 text-right">
              {numberFormat(property.operation.taxes)}
            </td>
          </tr>
          <tr>
            <td>Gastos de hipoteca</td>
            <td className="pl-10 text-right">
              {numberFormat(property.operation.mortgage)}
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="pl-10 pt-4 text-right text-bold">
              {numberFormat(
                property.operation.price +
                  property.operation.formalExpenses +
                  property.operation.taxes +
                  property.operation.mortgage,
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex flex-column flex-items-center mt-8 f4">
        <p>¿Necesitas más detalles?</p>
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href="http://blog.nexun.es/2024/01/10/guia-de-costos-para-la-compra-de-una-vivienda-en-cataluna/"
        >
          ¡Te ayudamos!
        </a>
      </div>
    </div>
  );
}
