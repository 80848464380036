import NexunLogo from 'components/NexunLogo';

export default function ApplicationLoader() {
  return (
    <div className="d-flex flex-column flex-1">
      <div className="d-flex flex-column flex-items-center flex-justify-center flex-1">
        <div className="anim-rotate">
          <NexunLogo onlyIcon size="large" />
        </div>
        {/* <p className="branch-name mt-3">
          Inizializando aplicación
          <span className="AnimatedEllipsis" />
        </p> */}
      </div>
    </div>
  );
}
