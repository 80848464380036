import { NavLink, Outlet } from 'react-router-dom';
import AgentHeader from 'components/AgentHeader';
import { VISITS_COUNT_FILTER_QUERY } from 'apollo/queries';
import { useQuery } from '@apollo/client';
import {
  HomeIcon,
  GraphIcon,
  MortarBoardIcon,
  OrganizationIcon,
  TasklistIcon,
  AlertIcon,
} from '@primer/octicons-react';
import { useEffect, useState } from 'react';
import { PROPERTIES_QUERY } from 'apollo/queries';
import { useAuth } from 'context/auth.context';

export default function AgentLayout() {
  const { data } = useQuery(PROPERTIES_QUERY);
  const [myVisits, setMyVisits] = useState(0);
  const count = data?.properties?.length || 0;
  const {
    actor: {
      isAvailableFulfilled,
      isProfileFulfilled,
      hasAcceptedContract,
      isDocumentFulfilled,
    },
  } = useAuth();
  const { actor } = useAuth();
  const counter = useQuery(VISITS_COUNT_FILTER_QUERY, {
    variables: {
      filter: {
        agent: actor._id,
      },
    },
  });

  useEffect(() => {
    setMyVisits(counter?.data?.propertyVisitsCount);
  }, [counter]);

  return (
    <div className="d-flex flex-column flex-1 color-bg-inset">
      <AgentHeader />
      <main className="d-flex flex-column flex-1">
        <div className="color-bg-default border-bottom">
          <div className="container-lg p-responsive width-full">
            <nav className="UnderlineNav" style={{ marginBottom: -1 }}>
              <div className="UnderlineNav-body flex-1 flex-justify-center py-md-3">
                <NavLink to="/" className="UnderlineNav-item" end>
                  <HomeIcon className="mr-2" /> Home{' '}
                </NavLink>
                <NavLink to="/properties" className="UnderlineNav-item">
                  <OrganizationIcon className="mr-2" /> Propiedades{' '}
                  <span className="Counter">{count}</span>
                </NavLink>
                <NavLink to="/visits" className="UnderlineNav-item">
                  <TasklistIcon className="mr-2" /> Visitas{' '}
                  <span className="Counter">{myVisits}</span>
                </NavLink>
                <NavLink to="/commissions" className="UnderlineNav-item">
                  <GraphIcon className="mr-2" /> Honorarios{' '}
                  {/* <span className="Counter">0</span> */}
                </NavLink>
                <NavLink to="/training" className="UnderlineNav-item">
                  <MortarBoardIcon className="mr-2" /> Formación
                </NavLink>
              </div>
            </nav>
          </div>
        </div>
        {(!isProfileFulfilled ||
          !isAvailableFulfilled ||
          !hasAcceptedContract ||
          !isDocumentFulfilled) && (
          <div className="flash flash-full flash-warn text-center">
            <AlertIcon className="octicon mr-2" />
            {(!isProfileFulfilled || !isAvailableFulfilled) && 'Complete su '}
            {!isProfileFulfilled && <NavLink to="/settings">perfil</NavLink>}
            {!isAvailableFulfilled && (
              <>
                {!isProfileFulfilled && ', '}
                <NavLink to="/settings/available">disponibilidad</NavLink>
              </>
            )}
            {!hasAcceptedContract && (
              <>
                {(!isProfileFulfilled || !isAvailableFulfilled) && ', '}
                <NavLink to="/settings/contract">
                  {!isProfileFulfilled || !isAvailableFulfilled ? 'a' : 'A'}
                  cepte el contrato
                </NavLink>
              </>
            )}
            {!isDocumentFulfilled && (
              <>
                {(!isProfileFulfilled ||
                  !isAvailableFulfilled ||
                  !hasAcceptedContract) &&
                  ' y '}
                <NavLink to="/settings/documents">
                  {!isProfileFulfilled ||
                  !isAvailableFulfilled ||
                  !hasAcceptedContract
                    ? 's'
                    : 'S'}
                  uba sus documentos
                </NavLink>
              </>
            )}{' '}
            para realizar acciones en la plataforma.
          </div>
        )}
        <div className="container-lg p-responsive width-full d-flex flex-column flex-1 py-4 color-bg-subtle">
          <div className="d-flex flex-column flex-1 flex-self-top color-bg-subtle">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}
