import { PropertyCard, Pagination } from 'components';
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { PROPERTIES_QUERY } from 'apollo/queries';

import { NexunLogo } from 'components';

// import { SearchIcon, LocationIcon, CheckIcon } from '@primer/octicons-react';

export default function PropertiesRoute() {
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(9);
  const [totalPages, setTotalPages] = useState(0);
  const [indexOfLastProperty, setIndexOfLastProperty] = useState(0);
  const [indexOfFirstProperty, setIndexOfFirstProperty] = useState(0);
  const [currentProperties, setCurrentProperties] = useState([]);

  const { data, loading } = useQuery(PROPERTIES_QUERY);

  useEffect(() => {
    if (data) {
      const totalPages = Math.ceil(data.properties.length / propertiesPerPage);
      setTotalPages(totalPages);
      setIndexOfFirstProperty(currentPage * propertiesPerPage);
      setIndexOfLastProperty(indexOfFirstProperty - propertiesPerPage);
      setCurrentProperties(
        data.properties.slice(indexOfLastProperty, indexOfFirstProperty),
      );
    }
  }, [
    data,
    currentPage,
    propertiesPerPage,
    indexOfFirstProperty,
    indexOfLastProperty,
  ]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
          <NexunLogo
            className="d-flex flex-justify-center anim-rotate"
            onlyIcon={true}
          />
          <label className="text-center">Cargando...</label>
        </div>
      </div>
    );
  }

  return (
    <div className="color-bg-subtle">
      {/* TODO: MAKE A REQUEST TO SEARCH PROPERTIES */}
      <div className="container-md width-full pb-2 ">
        {/* <div className="p-responsive p-4 color-bg-default rounded-2 border">
          <form className="d-flex flex-column">
            <h3 className="f3 text-light pb-4">
              Busca propiedades para vender
            </h3>
            <div className="d-flex">
              <div className="input-group col-10">
                <span className="input-group-button">
                  <button type="submit" className="btn">
                    <SearchIcon />
                  </button>
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  placeholder="¿Dónde quieres buscar?"
                />
              </div>
              <button type="submit" className="btn ml-2">
                <LocationIcon /> Búscame
              </button>
            </div>
            <div className="d-flex flex-row">
              <select
                className="form-select mt-3 mr-2 col-4"
                aria-label="Preference"
              >
                <option selected>Tipo de inmueble</option>
                <option value="1">Piso</option>
                <option value="2">Casa</option>
                <option value="3">Local</option>
                <option value="4">Oficina</option>
                <option value="5">Garaje</option>
                <option value="6">Trastero</option>
                <option value="7">Terreno</option>
                <option value="8">Nave</option>
              </select>
              <select
                className="form-select mt-3 mr-2 col-4"
                aria-label="Preference"
              >
                <option selected>Preferencia de venta</option>
                <option value="1">Venta exclusiva</option>
                <option value="2">Venta sin exclusividad</option>
              </select>
              <select
                className="form-select mt-3 col-4"
                aria-label="Preference"
              >
                <option selected>Ordenar por</option>
                <option value="1">Recientes</option>
                <option value="2">Precio más bajo</option>
                <option value="3">Precio más alto</option>
                <option value="4">Más cerca primero</option>
                <option value="5">Más lejos primero</option>
                <option value="6">Mayor honorarios</option>
                <option value="7">Menor honorarios</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary mt-3">
              <CheckIcon /> Aplicar
            </button>
          </form>
        </div> */}
      </div>
      <div className="container-md pt-0 mt-0">
        {/* LISTADO DE PROPIEDADES */}
        <PropertyCard elements={currentProperties} loading={loading} isAgent />
        {/* PAGINACION */}
        {!loading && (
          <Pagination
            pageInfo={{ currentPage: currentPage, pageCount: totalPages }}
            onPageChange={setCurrentPage}
            isAgent={true}
          />
        )}
      </div>
    </div>
  );
}
