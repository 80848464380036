import Baños from 'images/logos/Banos.png';
import Dormitorios from 'images/logos/Dormitorios.png';
import Garaje from 'images/logos/Garaje.png';
import Inmueble from 'images/logos/Inmueble.png';
import Metros from 'images/logos/Metros.png';
import Planta from 'images/logos/Planta.png';
import Portero from 'images/logos/Portero.png';
import Terraza from 'images/logos/Terraza.png';
import Ubicacion from 'images/logos/Ubicacion.png';

export default function PropertyDetails({ property }) {
  return (
    <div className="d-flex flex-row border-bottom border-top py-4 color-border-muted">
      <ul className="list-style-none col-12 text-center">
        <li className="col-5 col-md-4 p-2 d-inline-block">
          <p className="d-flex flex-row flex-justify-center flex-self-center">
            <img
              src={Metros}
              alt="fallo"
              width={32}
              className="mt-n1 mr-1 mr-md-2"
            />
            {property.features.areaUsable} m2
          </p>
        </li>
        <li className="d-inline-block col-5 col-md-4 p-2">
          <p className="d-flex flex-row flex-justify-center flex-self-center">
            <img
              src={Terraza}
              alt="fallo"
              width={32}
              className="mt-n1 mr-1  mr-md-2"
            ></img>
            {property.features.terrace ? 'Con' : 'Sin'} terraza
          </p>
        </li>
        <li className="d-inline-block col-5 col-md-4  p-2">
          <p className="d-flex flex-row flex-justify-center flex-self-center">
            <img
              src={Planta}
              alt="fallo"
              width={32}
              className="mt-n1 mr-1  mr-md-2"
            ></img>
            {property.address.floor === 'bj'
              ? 'Planta baja'
              : property.address.floor + ' ª planta'}
          </p>
        </li>
        <li className="d-inline-block col-5 col-md-4  p-2">
          <p className="d-flex flex-row flex-justify-center flex-self-center">
            <img
              src={Dormitorios}
              alt="fallo"
              width={32}
              className="mt-n1 mr-1 mr-md-2"
            ></img>
            {property.features.bedroomNumber} dormitorios
          </p>
        </li>
        <li className="d-inline-block col-5 col-md-4  p-2">
          <p className="d-flex flex-row flex-justify-center flex-self-center">
            <img
              src={Garaje}
              alt="fallo"
              width={32}
              className="mt-n1 mr-1 mr-md-2"
            ></img>
            {property.features.parkingAvailable ? 'Con' : 'Sin'} garaje
          </p>
        </li>
        <li className="d-inline-block col-5 col-md-4  p-2">
          <p className="d-flex flex-row flex-justify-center flex-self-center">
            <img
              src={Portero}
              alt="fallo"
              width={32}
              className="mt-n1 mr-1 mr-md-2"
            ></img>
            {property.features.doorman ? 'Con' : 'Sin'} portero
          </p>
        </li>
        <li className="d-inline-block col-5 col-md-4 p-2 ">
          <div className="d-flex flex-justify-center flex-content-center">
            <p className="d-flex flex-row flex-justify-center flex-self-center">
              <img
                src={Baños}
                alt="fallo"
                width={32}
                className="mt-n1 mr-1 mr-md-2"
              ></img>
              {property.features.bathroomNumber} baños
            </p>
          </div>
        </li>
        <li className="d-inline-block col-5 col-md-4  p-2">
          <p className="d-flex flex-row flex-justify-center flex-self-center">
            <img
              src={Ubicacion}
              alt="fallo"
              width={32}
              className="mt-n1 mr-1 mr-md-2"
            ></img>
            Orientación{' '}
            {property.features.windowsLocation === 'external'
              ? 'exterior'
              : 'interior'}
          </p>
        </li>
        <li className="d-inline-block col-5 col-md-4  p-2">
          <p className="d-flex flex-row flex-justify-center flex-self-center">
            <img
              src={Inmueble}
              alt="fallo"
              width={32}
              className="mt-n1 mr-1 mr-md-2"
            ></img>
            {property.features.conservation === 'new_development_finished'
              ? 'Nueva construcción finalizada'
              : 'Buen estado'}
          </p>
        </li>
      </ul>
    </div>
  );
}
