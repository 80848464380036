import { useQuery } from '@apollo/client';
import AGENTS_QUERY from 'apollo/queries/agents.query';
import { SearchIcon, CheckIcon, AlertIcon } from '@primer/octicons-react';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import escapeRegex from 'utils/escapeRegex';
import { Popper } from '@mui/material';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import UserAvatar from 'components/UserAvatar';
import { useToasts } from 'react-toast-notifications';
import { UPDATE_PROPERTY_VISIT_MUTATION } from 'apollo/mutations';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

export default function AgentSearcher({ value, disabled, visit }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 250);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [agent, setAgent] = useState(null);
  const { addToast } = useToasts();
  const { data } = useQuery(AGENTS_QUERY, {
    variables: {
      filter: {
        _operators: {
          _id: { nin: !!value ? value._id : null },
          firstName:
            debouncedSearch.trim().length > 0
              ? { regex: `/${escapeRegex(debouncedSearch)}/i` }
              : undefined,
        },
      },
    },
  });

  const handleChange = (agent) => {
    setAgent(agent);
  };

  const onCompletedUpdatePropertyVisit = useCallback(
    (data) => {
      setConfirmState(true);
      addToast('Se ha enviado un correo al agente para confirmar la cita.', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    [addToast],
  );

  const onErrorUpdatePropertyVisit = useCallback(
    (data) => {
      addToast('Ha ocurrido un error al guardar el agente.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
    [addToast],
  );

  const [updatePropertyVisit, { loading }] = useMutation(
    UPDATE_PROPERTY_VISIT_MUTATION,
    {
      onCompleted: onCompletedUpdatePropertyVisit,
      onError: onErrorUpdatePropertyVisit,
    },
  );

  const sendForm = (e) => {
    e.preventDefault();
    updatePropertyVisit({
      variables: {
        id: visit.propertyVisit._id,
        record: {
          agent: agent._id,
          isRefusedByAgent: false,
        },
      },
    });
  };

  useEffect(() => {
    if (visit) {
      if (visit.propertyVisit.isConfirmedByAgent) {
        setConfirmState(true);
      } else if (
        visit.propertyVisit.isConfirmedByAgent === false ||
        visit.propertyVisit.isConfirmedByAgent === null ||
        visit.propertyVisit.isConfirmedByAgent === undefined
      ) {
        setConfirmState(false);
      }
    }
  }, [visit]);

  return (
    <>
      {!value && (
        <div className="my-2">
          {!confirmation && (
            <>
              <div className="Box-body">No hay agente seleccionado.</div>

              <div className="Box-footer">
                <div className="input-group">
                  <div className="position-relative">
                    <div className="autocomplete-body">
                      <div
                        ref={anchorRef}
                        className="form-control autocomplete-embedded-icon-wrap"
                      >
                        <SearchIcon className="color-fg-subtle" />
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Buscar agente"
                          aria-label="Buscar agente"
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                          onFocus={() => setOpen(true)}
                          disabled={disabled}
                        />
                      </div>
                      <span />
                      <Popper
                        open={(open || debouncedSearch.length > 0) && !!data}
                        anchorEl={anchorRef.current}
                        placement="bottom-start"
                        className="autocomplete-results my-2"
                        disablePortal
                      >
                        {data?.agents.length === 0 && (
                          <div className="py-2 px-3">No hay coincidencias.</div>
                        )}
                        {data?.agents.map((agent) => (
                          <div
                            key={agent._id}
                            role="button"
                            className="autocomplete-item py-2"
                            onClick={() => {
                              handleChange(agent);
                              setSearch('');
                              setOpen(false);
                              setConfirmation(true);
                            }}
                          >
                            <span className="text-bold">
                              {agent.firstName} ({agent.email})
                            </span>
                          </div>
                        ))}
                      </Popper>
                    </div>
                  </div>
                  <span className="input-group-button input-group-button--autocomplete-embedded-icon">
                    <button
                      type="button"
                      className="btn"
                      disabled={search.length === 0 && !open}
                      onClick={() => {
                        setSearch('');
                        setOpen(false);
                      }}
                    >
                      Cerrar
                    </button>
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {!!value && (
        <NavLink
          className="d-inline-block col-12 color-fg-muted no-underline"
          to={'/users/agents/' + value._id}
        >
          <nav className="text-center position-relative">
            <div className="d-flex flex-justify-center py-3">
              <UserAvatar
                url={value.avatarURL}
                username={value.fullName}
                size="ultra"
                hideOnlineIndicator
              />
            </div>
            <h2 className="f4">{value.fullName}</h2>
            <h2 className="f5">{value.email}</h2>
            <div>
              <div className="border-top mt-3 pt-1 text-left px-2">
                <label>Teléfono: </label> {value.phone}
              </div>
              <div className="text-left px-2">
                <label>Dirección: </label>{' '}
                {value.address ? value.address.name : '-'}
              </div>
              <div className="text-left px-2">
                <label>Nacimiento: </label>{' '}
                {value.birthDate
                  ? new Date(value.birthDate).toISOString().split('T')[0]
                  : '-'}
              </div>
              <div className="text-left px-2">
                <label>NIF: </label> {value.nif ? value.nif : '-'}
              </div>
              <div className="text-left border-bottom pb-1 px-2">
                <label>Ciudad: </label>{' '}
                {value.country
                  ? value.country.flag + ' ' + value.country.name
                  : '-'}
              </div>
            </div>
            <div>
              {visit.propertyVisit.isConfirmedByAgent && (
                <div className="flash flash-full rounded-2  flash-success">
                  <CheckIcon size={20} />
                  Aceptada
                </div>
              )}
              {!visit.propertyVisit.isConfirmedByAgent && (
                <div className="flash flash-full rounded-2 flash-warn">
                  <AlertIcon size={20} />
                  Pendiente
                </div>
              )}
            </div>
          </nav>
        </NavLink>
      )}
      {confirmation && !value && (
        <div className="d-inline-block col-12 color-fg-muted no-underline">
          <div className=" text-center position-relative">
            <div className="d-flex flex-justify-center py-2">
              <UserAvatar
                url={agent.avatarURL}
                username={agent.fullName}
                size="ultra"
                hideOnlineIndicator
              />
            </div>
            <h2 className="f4">{agent.fullName}</h2>
            <h2 className="f5">{agent.email}</h2>
            <div className="">
              <div className="border-top mt-3 pt-1 text-left px-2">
                <label>Teléfono: </label> {agent.phone}
              </div>
              <div className="text-left px-2">
                <label>Dirección: </label>{' '}
                {agent.address ? agent.address.name : '-'}
              </div>
              <div className="text-left px-2">
                <label>Nacimiento: </label>{' '}
                {agent.birthDate
                  ? new Date(agent.birthDate).toISOString().split('T')[0]
                  : '-'}
              </div>
              <div className="text-left px-2">
                <label>NIF: </label> {agent.nif ? agent.nif : '-'}
              </div>
              <div className="text-left border-bottom pb-2 px-2">
                <label>Ciudad: </label>{' '}
                {agent.country
                  ? agent.country.flag + ' ' + agent.country.name
                  : '-'}
              </div>
            </div>
            <div>
              {confirmState && (
                <div className="flash flash-full rounded-2 flash-warn">
                  <AlertIcon size={20} />
                  Pendiente
                </div>
              )}
              {!confirmState && (
                <div className="d-lg-flex flex-lg-justify-between py-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-danger mx-2 mb-2"
                    onClick={() => {
                      setConfirmation(false);
                      setAgent(null);
                    }}
                  >
                    Cambiar agente
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mx-2 mb-2"
                    onClick={sendForm}
                  >
                    {loading && 'Guardando...'}
                    Confirmar Agente
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

AgentSearcher.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      address: PropTypes.shape({
        formattedAddress: PropTypes.string.isRequired,
      }).isRequired,
      birthDate: PropTypes.string.isRequired,
      nif: PropTypes.string.isRequired,
      country: PropTypes.shape({
        name: PropTypes.string.isRequired,
        flag: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  visit: PropTypes.string.isRequired,
};
