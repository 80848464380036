import { gql } from '@apollo/client';

export default gql`
  query AgentById($id: MongoID!) {
    agentById(_id: $id) {
      avatarURL
      firstName
      lastName
      email
      isEmailVerificated
      languages {
        name
        code
        nativeName
        flag
        _id
        createdAt
        updatedAt
      }
      availableDateRanges {
        dayOfWeek
        hourRange {
          start
          end
        }
      }
      activityLocation {
        place {
          placeId
          formattedAddress
          geometry {
            location {
              lat
              lng
            }
            viewport {
              northeast {
                lat
                lng
              }
              southwest {
                lat
                lng
              }
            }
          }
          name
        }
        kmRange
      }
      experienceYears
      nif
      birthDate
      country {
        name
        code
        numberCode
        flag
        _id
        createdAt
        updatedAt
      }
      address {
        placeId
        formattedAddress
        geometry {
          location {
            lat
            lng
          }
          viewport {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        name
      }
      activity
      companyNif
      companyName
      companyFormmatedAddress
      phone
      hasAcceptedContract
      contractSignedAt
      nifFiles
      criminalRecordFiles
      bankOwnershipFiles
      iban
      referredBy {
        _id
        firstName
        lastName
        fullName
      }
      _id
      createdAt
      updatedAt
      fullName
      resourcePath
      isProfileFulfilled
      isAvailableFulfilled
      isDocumentFulfilled
    }
  }
`;
