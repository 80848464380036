import { ClockIcon } from '@primer/octicons-react';

export default function ExploreRoute() {
  return (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="blankslate">
          <ClockIcon size={35} />
          <h3 className="blankslate-heading">Próximamente...</h3>
          <p>
            Estamos trabajando para poder darte los mejores cursos con los que
            formarte y prepararte para la venta de propiedades en nuestra
            plataforma
          </p>
        </div>
      </div>
    </div>
  );
}
