import { Box } from '@mui/material';
import { HomeHeader, NexunAI } from 'components';
import { techData, howItWorks } from 'utils/technology-data';
import { imagesFeria } from 'utils/home-data';
import { HomeFooter, ShapeDivider } from 'components';
import facade from '../images/feria/facade.webp';

export default function Technology() {
  return (
    <div className="d-flex flex-column flex-1">
      <div className="container-lg width-full">
        <HomeHeader />
      </div>
      <div className="container-lg color-bg-subtle">
        <ShapeDivider placement="bottom" />
        <div className="width-full color-bg-default d-flex flex-items-center pt-md-8 pb-8">
          <Box
            className="container-lg p-responsive width-full d-flex flex-justify-center"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <NexunAI />
          </Box>
        </div>
      </div>

      <div
        className="p-responsive width-full pt-2 color-bg-default d-flex flex-items-center py-3 anim-fade-in"
        style={{
          backgroundImage: `linear-gradient(rgba(35, 35, 60, 0.8), rgba(35, 35, 60, 0.8)), url(${imagesFeria[2].img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box
          className="container-lg p-responsive width-full d-flex flex-justify-center flex-md-justify-end"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <div
            className="d-flex flex-column text-center text-md-left pr-md-6"
            style={{ color: '#f2f2f2' }}
          >
            {techData.map((data) => (
              <>
                <hr className="mt-4 mb-2 mt-md-3 mb-md-0" />
                <div className="d-flex flex-column">
                  <h3 className="h2">{data.title}</h3>
                  <p className="f5">{data.description}</p>
                </div>
              </>
            ))}
          </div>
        </Box>
      </div>

      <div className="width-full pt-2 color-bg-default d-flex flex-items-center py-6 py-md-8 px-1 px-md-0">
        <Box
          className="container-lg width-full d-flex flex-justify-center"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <div className="d-flex flex-column dark-text flex-items-center">
            <h3 className="h1 text-center text-md-left">Cómo funciona</h3>

            <div className="pt-4 d-flex flex-column">
              {howItWorks.map((data) => (
                <div
                  key={data.title}
                  className="d-flex flex-column py-2 text-center flex-items-center flex-md-items-start text-md-left"
                  style={{ gap: '1rem' }}
                >
                  <h3 className="h2">{data.title}</h3>
                  <p className="f4" style={{ width: '65%' }}>
                    {data.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </div>

      <div className="p-responsive width-full pt-2 color-bg-default d-flex flex-items-center py-2 py-md-1">
        <Box
          className="container-lg p-responsive width-full d-flex flex-justify-center"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <div
            className="d-flex flex-column flex-md-row flex-items-center flex-justify-center"
            style={{ gap: '2rem' }}
          >
            <h3
              className="h1 text-center text-md-left"
              style={{ maxWidth: '450px' }}
            >
              Comienza a disfrutar lo que Nexun puede hacer por tí
            </h3>
            <img
              src={facade}
              alt="Imagen de una fachada"
              className="circle"
              width={300}
              height={300}
            />
          </div>
        </Box>
      </div>
      <HomeFooter />
    </div>
  );
}
