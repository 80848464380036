import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { client } from 'apollo';
import { ToastProvider } from 'react-toast-notifications';
import { ApplicationLoader, Toast, ToastContainer } from 'components';
import { BrowserRouter } from 'react-router-dom';
import ApplicationRoutes from 'routes';
import GlobalStyles from 'utils/global-styles';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'context/auth.context';
import { LoadScriptNext } from '@react-google-maps/api';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadScriptNext
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={['places', 'geometry']}
      language="es"
      version="weekly"
      loadingElement={<ApplicationLoader />}
    >
      <ApolloProvider client={client}>
        <ToastProvider components={{ Toast, ToastContainer }}>
          <BrowserRouter>
            <AuthProvider>
              <ApplicationRoutes />
            </AuthProvider>
          </BrowserRouter>
        </ToastProvider>
      </ApolloProvider>
    </LoadScriptNext>
    <GlobalStyles />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
