import PropTypes from 'prop-types';

const fonts = {
  small: 'f6',
  medium: 'f3',
  large: 'f1',
};

const pixels = {
  small: 24,
  medium: 38,
  large: 60,
};

export default function NexunLogo({
  className,
  onlyIcon = false,
  size = 'medium',
  muted = false,
  inverted = false,
  invertedText = false,
  children,
}) {
  return (
    <div className={`d-flex flex-items-center ${className}`}>
      <div
        className={`CircleBadge ${
          inverted
            ? muted
              ? 'color-bg-muted color-fg-on-emphasis'
              : 'color-bg-default color-fg-default'
            : muted
            ? 'color-bg-muted color-fg-on-emphasis'
            : 'color-bg-emphasis color-fg-on-emphasis'
        } ${onlyIcon ? 'mr-0' : 'mr-2'} user-select-none position-relative`}
        style={{ width: pixels[size], height: pixels[size] }}
      >
        <div className="nexun-divider" />
        <span className={`text-emphasized ${fonts[size]} lh-condensed-ultra`}>
          X
        </span>
      </div>
      {!onlyIcon && (
        <div className="user-select-none">
          <h1
            className={
              invertedText
                ? 'h3 lh-condensed-ultra color-fg-subtle'
                : ' h3 lh-condensed-ultra color-fg-on-default'
            }
          >
            nexun
          </h1>
          {children}
        </div>
      )}
    </div>
  );
}

NexunLogo.propTypes = {
  className: PropTypes.string,
  onlyIcon: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  muted: PropTypes.bool,
};
