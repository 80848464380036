import {
  SearchIcon,
  BookIcon,
  StarIcon,
  ChecklistIcon,
} from '@primer/octicons-react';
import { NavLink, Outlet } from 'react-router-dom';

export default function TrainingRoutes() {
  //TODO: TAKE COUNTER OF MYCOURSES AND FAVORITES, AND COMPLETED
  const mycourses = 0;
  const favorites = 0;
  const completed = 0;

  return (
    <>
      <div className=""></div>
      <div className="Layout Layout--flowRow-until-md Layout--sidebarPosition-start Layout--sidebarPosition-flowRow-start">
        <div className="Layout-sidebar">
          <div
            className="position-md-sticky color-bg-default rounded-2 border"
            style={{ top: 24 }}
          >
            <ul className="ActionList">
              <NavLink
                to="/training"
                className={({ isActive }) =>
                  `ActionList-item ActionList-content ActionList-content--visual16 ${
                    isActive ? 'ActionList-item--navActive' : ''
                  }`
                }
                end
              >
                <span className="ActionList-item-visual ActionList-item-visual--leading">
                  <SearchIcon />
                </span>
                <span className="ActionList-item-label">Explorar</span>
              </NavLink>
              <NavLink
                to="/training/my-formation"
                className={({ isActive }) =>
                  `ActionList-item ActionList-content ActionList-content--visual16 ${
                    isActive ? 'ActionList-item--navActive' : ''
                  }`
                }
                end
              >
                <span className="ActionList-item-visual ActionList-item-visual--leading">
                  <BookIcon />
                </span>
                <span className="ActionList-item-label">
                  Mis cursos<span className="Counter ml-1">{mycourses}</span>
                </span>
              </NavLink>
              <NavLink
                to="/training/favorites"
                className={({ isActive }) =>
                  `ActionList-item ActionList-content ActionList-content--visual16 ${
                    isActive ? 'ActionList-item--navActive' : ''
                  }`
                }
                end
              >
                <span className="ActionList-item-visual ActionList-item-visual--leading">
                  <StarIcon />
                </span>
                <span className="ActionList-item-label">
                  Favoritos<span className="Counter ml-1">{favorites}</span>
                </span>
              </NavLink>
              <NavLink
                to="/training/completed"
                className={({ isActive }) =>
                  `ActionList-item ActionList-content ActionList-content--visual16 ${
                    isActive ? 'ActionList-item--navActive' : ''
                  }`
                }
                end
              >
                <span className="ActionList-item-visual ActionList-item-visual--leading">
                  <ChecklistIcon />
                </span>
                <span className="ActionList-item-label">
                  Finalizados<span className="Counter ml-1">{completed}</span>
                </span>
              </NavLink>
            </ul>
          </div>
        </div>
        <div className="Layout-main flex-1">
          <div className="Layout-main">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
