import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { AGENT_QUERY } from 'apollo/queries';
import UserAvatar from 'components/UserAvatar';
import NexunLogo from 'components/NexunLogo';
import { CheckIcon, XIcon } from '@primer/octicons-react';
import ModalNexun from 'components/ModalNexun';
import { days } from 'utils/schedule';
import { useState } from 'react';

export default function AgentView() {
  const [map, setMap] = useState(false);
  const { id, loading } = useParams();
  const agent = useQuery(AGENT_QUERY, {
    variables: { id },
  }).data?.agentById;

  console.log(agent);

  const lat = agent?.activityLocation
    ? agent?.activityLocation.place.geometry?.location?.lat
    : 0;
  const lng = agent?.activityLocation
    ? agent?.activityLocation.place.geometry?.location?.lng
    : 0;
  const range = agent?.activityLocation?.kmRange
    ? agent?.activityLocation?.kmRange
    : 0;

  const getNameOfDay = (code) => {
    const day = days.find((d) => d.code === code);
    return day.name;
  };

  const handleMap = () => {
    setMap(!map);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
          <NexunLogo
            className="d-flex flex-justify-center anim-rotate"
            onlyIcon={true}
          />
          <label className="text-center">Cargando...</label>
        </div>
      </div>
    );
  }

  if (agent) {
    return (
      <div className="py-3">
        <div className="col-12 p-responsive mx-auto">
          <div className="Layout col-12">
            <div className="Layout-sidebar text-center position-relative border-md-right pr-3">
              <div className="d-flex flex-justify-center">
                <UserAvatar
                  url={agent.avatarURL}
                  username={agent.fullName}
                  size="ultra"
                  hideOnlineIndicator
                />
              </div>
              <h2>{agent.fullName}</h2>
              <p>{agent.email}</p>
              <div>
                {agent.isEmailVerificated && (
                  <div className="flash p-1 mt-2 flash-success">
                    <CheckIcon className="color-fg-success" size={16} />
                    <span>Email verificado</span>
                  </div>
                )}

                {!agent.isEmailVerificated && (
                  <div className="flash p-1 mt-2 flash-error">
                    <XIcon className="color-fg-danger" size={16} />
                    <span>Email sin verificar</span>
                  </div>
                )}
              </div>
              <div>
                {agent.isProfileFulfilled && (
                  <div className="flash p-1 mt-2 flash-success">
                    <CheckIcon className="color-fg-success" size={16} />
                    <span>Perfil completo</span>
                  </div>
                )}

                {!agent.isProfileFulfilled && (
                  <div className="flash p-1 mt-2 flash-error">
                    <XIcon className="color-fg-danger" size={16} />
                    <span>Perfil sin rellenar</span>
                  </div>
                )}
              </div>
              <div>
                {agent.isAvailableFulfilled && (
                  <div className="flash p-1 mt-2 flash-success">
                    <CheckIcon className="color-fg-success" size={16} />
                    <span>Disponibilidad rellena</span>
                  </div>
                )}

                {!agent.isAvailableFulfilled && (
                  <div className="flash p-1 mt-2 flash-error">
                    <XIcon className="color-fg-danger" size={16} />
                    <span>Disponibilidad sin rellenar</span>
                  </div>
                )}
              </div>
              <div>
                {agent.isDocumentFulfilled && (
                  <div className="flash p-1 mt-2 flash-success">
                    <CheckIcon className="color-fg-success" size={16} />
                    <span>Documentos enviados</span>
                  </div>
                )}

                {!agent.isDocumentFulfilled && (
                  <div className="flash p-1 mt-2 flash-error">
                    <XIcon className="color-fg-danger" size={16} />
                    <span>Documentos sin rellenar</span>
                  </div>
                )}
              </div>
              <div>
                {agent.hasAcceptedContract && (
                  <div className="flash p-1 mt-2 flash-success">
                    <CheckIcon className="color-fg-success" size={16} />
                    <span>Contrato firmado</span>
                  </div>
                )}

                {!agent.hasAcceptedContract && (
                  <div className="flash p-1 mt-2 flash-error">
                    <XIcon className="color-fg-danger" size={16} />
                    <span>Contrato sin firmar</span>
                  </div>
                )}
              </div>
              <div className="border-top mt-3 pt-3 text-left">
                <label>Referenciado: </label>
                {agent.referredBy && (
                  <a
                    href={`/users/agents/${agent.referredBy._id}`}
                    className="text-bold"
                  >
                    {agent.referredBy.firstName} {agent.referredBy.lastName}
                  </a>
                )}
                {!agent.referredBy && <strong>No</strong>}
              </div>
              <div className="border-top mt-3 pt-3 text-left">
                <label>Teléfono: </label> {agent.phone}
              </div>
              <div className="text-left">
                <label>Dirección: </label>{' '}
                {agent.address ? agent.address.name : '-'}
              </div>
              <div className="text-left">
                <label>Nacimiento: </label>{' '}
                {agent.birthDate
                  ? new Date(agent.birthDate).toISOString().split('T')[0]
                  : '-'}
              </div>
              <div className="text-left">
                <label>NIF: </label> {agent.nif ? agent.nif : '-'}
              </div>
              <div className="text-left border-bottom pb-3">
                <label>Ciudad: </label>{' '}
                {agent.country
                  ? agent.country.flag + ' ' + agent.country.name
                  : '-'}
              </div>
              <div className="pb-3 border-bottom text-left pt-3">
                <h3>Horario</h3>
                <div className="">
                  {agent.availableDateRanges.length === 0 &&
                    'No hay franjas horarias seleccionadas.'}
                  {agent.availableDateRanges.map((schedule) => (
                    <div
                      key={
                        getNameOfDay(schedule.dayOfWeek) +
                        schedule.hourRange.start +
                        schedule.hourRange.end
                      }
                      className="Label d-inline-flex flex-items-center m-1"
                    >
                      <span className="px-2">
                        {getNameOfDay(schedule.dayOfWeek)}:{' '}
                        {schedule.hourRange.start}:00 - {schedule.hourRange.end}
                        :00
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pb-3 border-bottom text-left pt-3">
                {agent.activityLocation === null &&
                  'No hay localización disponible'}
                {agent.activityLocation && (
                  <>
                    <h3>Zona de trabajo</h3>
                    <p>{agent.activityLocation.place.formattedAddress}</p>
                    <p>
                      Rango de trabajo:
                      {agent.activityLocation.kmRange} km
                    </p>
                    <button
                      className="btn btn-secondary mt-2"
                      onClick={handleMap}
                    >
                      Ver zona de trabajo
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="Layout-main">
              <div className="d-sm-flex ">
                <div className="col-12 pl-4">
                  {map && (
                    <ModalNexun
                      lat={lat}
                      lng={lng}
                      name={agent.activityLocation.place.formattedAddress}
                      address={
                        'Rango de trabajo: ' +
                        agent.activityLocation.kmRange +
                        ' km'
                      }
                      setMap={setMap}
                      range={agent.activityLocation.kmRange}
                    />
                  )}
                </div>
              </div>
              <div className="Subhead">
                <h2 className="Subhead-heading">Actividad reciente</h2>
                <div className="Subhead-description">
                  Aquí aparecerá ultimos movimientos, acciones, pagos, etc.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
