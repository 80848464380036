import { MailIcon, VerifiedIcon } from '@primer/octicons-react';
import { NexunLogo } from 'components';
import { useAuth } from 'context/auth.context';
import { gql, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

const SEND_VERIFICATION_EMAIL_MUTATION = gql`
  mutation SendVerificationEmail {
    sendVerificationEmail
  }
`;

const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;

export default function VerifyEmailRoute() {
  const auth = useAuth();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onError = useCallback(
    (err) => {
      addToast(err.message, { appearance: 'error' });
    },
    [addToast],
  );

  const [sendVerificationEmail, { loading: sendingVerificationEmail }] =
    useMutation(SEND_VERIFICATION_EMAIL_MUTATION, {
      onCompleted: () => {
        addToast(
          'Se ha enviado un nuevo enlace de verificación a su cuenta de correo',
          {
            appearance: 'success',
            autoDismiss: true,
          },
        );
      },
      onError,
    });

  const onVerifyEmail = useCallback(() => {
    addToast('Su correo ha sido verificado', {
      appearance: 'success',
      autoDismiss: true,
    });
    navigate('/', { replace: true });
  }, [addToast, navigate]);

  const [verifyEmail, { loading: verificatingEmail }] = useMutation(
    VERIFY_EMAIL_MUTATION,
    {
      refetchQueries: ['Actor'],
      onCompleted: onVerifyEmail,
      onError,
    },
  );

  useEffect(() => {
    if (auth.actor?.isEmailVerificated) {
      return navigate('/', { replace: true });
    }
    if (searchParams && searchParams.get('token')) {
      verifyEmail({ variables: { token: searchParams.get('token') } });
    }
  }, [searchParams, verifyEmail, auth.actor?.isEmailVerificated, navigate]);

  if (verificatingEmail) {
    return (
      <div className="d-flex flex-column flex-1 flex-items-center flex-justify-center color-bg-inset py-4">
        <div className="container-md p-responsive">
          <div className="d-flex flex-justify-center flex-items-center mb-4">
            <NexunLogo />
          </div>
          <p className="branch-name mt-3">
            Verificando correo electrónico
            <span className="AnimatedEllipsis" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column flex-1 flex-items-center flex-justify-center color-bg-inset py-4">
      <div className="container-sm p-responsive">
        <div className="d-flex flex-justify-center flex-items-center mb-4">
          <NexunLogo />
        </div>
        <div className="Box color-bg-default color-shadow-medium">
          <div className="blankslate">
            <div className="position-relative d-inline-block">
              <MailIcon className="blankslate-icon" size="large" />
              <div className="position-absolute top-0 right-0">
                <div
                  className="position-absolute top-0 right-0 color-fg-success color-bg-default circle d-flex flex-items-center flex-justify-center"
                  style={{
                    width: 22,
                    height: 22,
                    transform: 'translateX(11px) translateY(-11px)',
                  }}
                >
                  <VerifiedIcon />
                </div>
              </div>
            </div>
            <h3 className="blankslate-heading">
              Verificación de correo necesaria
            </h3>
            <p>
              Necesitamos validar su cuenta de correo electrónico{' '}
              <span className="text-bold color-fg-default">
                {auth.actor.email}
              </span>{' '}
              para completar el proceso de registro. Revise su bandeja de
              entrada y sigua las instrucciones que le hemos enviado.
            </p>
            <div className="blankslate-action">
              <button
                className="btn-link"
                type="button"
                onClick={() => sendVerificationEmail()}
                disabled={sendingVerificationEmail}
              >
                {sendingVerificationEmail
                  ? 'Reenviando enlace de verificación…'
                  : 'Reenviar enlace de verificación'}
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex flex-items-center flex-justify-center my-4">
          <Link to="/" className="Link--secondary px-3 border-right">
            Contacto
          </Link>
          <Link to="/" className="Link--secondary px-3 border-right">
            Privacidad
          </Link>
          <button
            type="button"
            className="btn-link px-3"
            onClick={auth.signout}
          >
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
  );
}
