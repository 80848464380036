import { useMutation } from '@apollo/client';
import { PencilIcon } from '@primer/octicons-react';
import {
  UPDATE_AGENT_MUTATION,
  UPLOAD_PROFILE_PICTURE_MUTATION,
} from 'apollo/mutations';
import {
  AddressInput,
  CountrySelector,
  MultipleLanguageSelector,
  UserAvatar,
} from 'components';
import { useAuth } from 'context/auth.context';
import { useCallback } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

export default function ProfileSettingsRoute() {
  const { actor } = useAuth();
  console.log(actor);
  const { addToast } = useToasts();
  const [firstName, setFirstName] = useState(actor.firstName || '');
  const [lastName, setLastName] = useState(actor.lastName || '');
  const [dni, setDni] = useState(actor.nif || '');
  const [birthDate, setBirthDate] = useState(
    actor.birthDate
      ? new Date(actor.birthDate).toISOString().split('T')[0]
      : '',
  );
  const [country, setCountry] = useState(actor.country);
  const [address, setAddress] = useState(actor.address);
  const [mobilePhone, setMobilePhone] = useState(actor.phone || '');
  const [activity, setActivity] = useState(actor.activity);
  const [companyCIF, setCompanyCIF] = useState(actor.companyNif || '');
  const [companyName, setCompanyName] = useState(actor.companyName || '');
  const [companyFormmatedAddress, setCompanyFormmatedAddress] = useState(
    actor.companyFormmatedAddress || '',
  );
  const [experienceYears, setExperienceYears] = useState(
    actor.experienceYears || 0,
  );
  const [languages, setLanguages] = useState(actor.languages || []);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [hover, setHover] = useState(false);

  const onError = useCallback(
    (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    },
    [addToast],
  );

  const [generateUploadUrl] = useMutation(UPLOAD_PROFILE_PICTURE_MUTATION, {
    onError,
  });
  const [updateAgent, { loading }] = useMutation(UPDATE_AGENT_MUTATION, {
    refetchQueries: ['Actor'],
    onError,
    onCompleted: () => {
      addToast('Perfil actualizado', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
  });

  const handleProfilePictureChange = async (event) => {
    event.preventDefault();
    setUploadingFile(true);
    const file = event.target.files[0];
    const signedPost = await generateUploadUrl({
      variables: { contentType: file.type },
    });
    const { url, fields: jsonFiles } = signedPost.data.uploadProfilePicture;
    const fields = JSON.parse(jsonFiles);
    const formData = new FormData();
    formData.append('Content-Type', file.type);
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const location = res.headers.get('Location');
      updateAgent({
        variables: {
          filter: { _id: actor._id },
          record: { avatarURL: decodeURIComponent(location) },
        },
      });
    } catch (err) {
      addToast('Error al subir el archivo', {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      setUploadingFile(false);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    updateAgent({
      variables: {
        filter: { _id: actor._id },
        record: {
          firstName: firstName,
          lastName: lastName?.trim().length > 0 ? lastName : null,
          nif: dni?.trim().length > 0 ? dni : null,
          birthDate: birthDate ? new Date(birthDate) : null,
          country: country?._id || null,
          address: address
            ? {
                placeId: address.placeId,
                formattedAddress: address.formattedAddress,
                geometry: {
                  location: {
                    lat: address.geometry.location.lat,
                    lng: address.geometry.location.lng,
                  },
                  viewport: {
                    northeast: {
                      lat: address.geometry.viewport.northeast.lat,
                      lng: address.geometry.viewport.northeast.lng,
                    },
                    southwest: {
                      lat: address.geometry.viewport.southwest.lat,
                      lng: address.geometry.viewport.southwest.lng,
                    },
                  },
                },
                name: address.name,
              }
            : null,
          languages: languages.map(({ _id }) => _id),
          experienceYears:
            isNaN(experienceYears) || experienceYears < 0
              ? null
              : parseInt(experienceYears, 10),
          activity: activity?.trim().length > 0 ? activity : null,
          companyNif: companyCIF?.trim().length > 0 ? companyCIF : null,
          companyName: companyName?.trim().length > 0 ? companyName : null,
          companyFormmatedAddress:
            companyFormmatedAddress?.trim().length > 0
              ? companyFormmatedAddress
              : null,
          phone: mobilePhone?.trim().length > 0 ? mobilePhone : null,
        },
      },
    });
  };

  return (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead mb-0">
          <h2 className="Subhead-heading">Perfil</h2>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="clearfix gutter-condensed d-flex flex-shrink-0 flex-column-reverse flex-md-row">
            <div className="col-12 col-md-8">
              <div className="form-group">
                <div className="form-group-header">
                  <label htmlFor="name">Nombre y apellidos</label>
                </div>
                <div className="form-group-body">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Nombre"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    disabled={loading}
                    autoFocus
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-body">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Apellidos"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    disabled={loading}
                    required
                  />
                  <div className="note" style={{ maxWidth: 440 }}>
                    Su nombre y apellidos pueden aparecer en nexun en las
                    visitas programadas o como información de contacto. Puede
                    cambiarlos en cualquier momento.
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-header">
                  <label htmlFor="dni">DNI / NIE</label>
                </div>
                <div className="form-group-body">
                  <input
                    type="text"
                    className="form-control"
                    id="dni"
                    placeholder="DNI o NIE"
                    value={dni}
                    onChange={(event) => setDni(event.target.value)}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-header">
                  <label htmlFor="birthDate">Fecha de nacimiento</label>
                </div>
                <div className="form-group-body">
                  <input
                    type="date"
                    className="form-control"
                    id="birthDate"
                    placeholder="Fecha de nacimiento"
                    value={birthDate}
                    onChange={(event) => setBirthDate(event.target.value)}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-header">
                  <label>País</label>
                </div>
                <div className="form-group-body">
                  <CountrySelector
                    value={country}
                    onSelect={setCountry}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-header">
                  <label htmlFor="address">Dirección</label>
                </div>
                <div className="form-group-body">
                  <AddressInput
                    className="form-control"
                    placeholder="Buscar dirección"
                    value={address}
                    onChange={(address) => setAddress(address)}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-header">
                  <label htmlFor="mobilePhone">Teléfono móvil</label>
                </div>
                <div className="form-group-body">
                  <input
                    type="text"
                    className="form-control"
                    id="mobilePhone"
                    placeholder="Teléfono móvil"
                    value={mobilePhone}
                    onChange={(event) => setMobilePhone(event.target.value)}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  className="radio-input"
                  id="self-employed"
                  name="activity"
                  value="SELF_EMPLOYED"
                  checked={activity === 'SELF_EMPLOYED'}
                  onChange={(event) => setActivity(event.target.value)}
                  disabled={loading}
                />
                <label htmlFor="self-employed" className="radio-label">
                  Autónomo
                </label>
                <input
                  type="radio"
                  className="radio-input"
                  id="individual"
                  name="activity"
                  value="INDIVIDUAL"
                  checked={activity === 'INDIVIDUAL'}
                  onChange={(event) => setActivity(event.target.value)}
                  disabled={loading}
                />
                <label htmlFor="individual" className="radio-label">
                  Particular
                </label>
                <input
                  type="radio"
                  className="radio-input"
                  id="company"
                  name="activity"
                  value="COMPANY"
                  checked={activity === 'COMPANY'}
                  onChange={(event) => setActivity(event.target.value)}
                  disabled={loading}
                />
                <label htmlFor="company" className="radio-label">
                  Empresa
                </label>
              </div>
              {activity === 'COMPANY' && (
                <>
                  <div className="form-group">
                    <div className="form-group-header">
                      <label htmlFor="companyCIF">Nombre de la empresa</label>
                    </div>
                    <div className="form-group-body">
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        placeholder="Nombre de la empresa"
                        value={companyName}
                        onChange={(event) => setCompanyName(event.target.value)}
                        disabled={loading}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-header">
                      <label htmlFor="companyCIF">CIF de la empresa</label>
                    </div>
                    <div className="form-group-body">
                      <input
                        type="text"
                        className="form-control"
                        id="companyCIF"
                        placeholder="CIF de la empresa"
                        value={companyCIF}
                        onChange={(event) => setCompanyCIF(event.target.value)}
                        disabled={loading}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-header">
                      <label htmlFor="companyFormmatedAddress">
                        Dirección fiscal
                      </label>
                    </div>
                    <div className="form-group-body">
                      <input
                        type="text"
                        className="form-control"
                        id="companyFormmatedAddress"
                        placeholder="Dirección Fiscal"
                        value={companyFormmatedAddress}
                        onChange={(event) =>
                          setCompanyFormmatedAddress(event.target.value)
                        }
                        disabled={loading}
                        required
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="form-group">
                <div className="form-group-header">
                  <label htmlFor="experience-years">
                    Experiencia laboral (años)
                  </label>
                </div>
                <div className="form-group-body">
                  <input
                    type="number"
                    className="form-control"
                    id="experience-years"
                    placeholder="Experiencia laboral (años)"
                    min={0}
                    max={200}
                    value={experienceYears}
                    onChange={(event) => setExperienceYears(event.target.value)}
                    disabled={loading}
                  />
                </div>
              </div>
              <MultipleLanguageSelector
                values={languages}
                onSelect={(language) =>
                  setLanguages((prev) =>
                    [...prev, language].sort((a, b) =>
                      a.name.localeCompare(b.name),
                    ),
                  )
                }
                onRemove={(language) =>
                  setLanguages((prev) =>
                    prev.filter((l) => l.name !== language.name),
                  )
                }
                disabled={loading}
              />
              <p className="note mt-3 mb-2">
                Todos los campos de esta página son opcionales y pueden ser
                eliminados en cualquier momento, y al rellenarlos, nos está
                dando su consentimiento para compartir estos datos dondequiera
                que aparezca su perfil de usuario. Consulte nuestra{' '}
                <Link
                  to="/privacy"
                  className="Link"
                  target={'_blank'}
                  rel="nofollow noopener noreferrer"
                >
                  declaración de privacidad
                </Link>{' '}
                para saber más sobre el uso que hacemos de esta información.
              </p>
              <button type="submit" className="btn btn-primary">
                Actualizar perfil
              </button>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <div className="form-group-header">
                  <label htmlFor="avatar">Foto de perfil</label>
                </div>
                <div className="form-group-body">
                  <div className="avatar-upload">
                    <details className="dropdown details-reset details-overlay">
                      <summary role="button" aria-haspopup="menu">
                        <UserAvatar
                          url={actor.avatarURL}
                          username={actor.fullName}
                          size="settings"
                          hideOnlineIndicator
                        />
                        <div className="position-absolute color-bg-default rounded-2 color-fg-default px-2 py-1 left-0 bottom-0 ml-2 mb-2 border">
                          <PencilIcon /> Editar
                        </div>
                      </summary>
                      <div
                        role="menu"
                        className="dropdown-menu dropdown-menu-se"
                      >
                        <label
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                          role="menuitem"
                          htmlFor="avatar_upload"
                          className="dropdown-item text-normal"
                          style={{ cursor: 'pointer' }}
                        >
                          {uploadingFile || loading
                            ? 'Subiendo foto…'
                            : 'Subir foto de perfil'}
                        </label>
                        {actor.avatarURL && (
                          <button
                            type="button"
                            className="btn-link dropdown-item"
                            disabled={uploadingFile || loading}
                            onClick={() =>
                              updateAgent({
                                variables: {
                                  filter: { _id: actor._id },
                                  record: { avatarURL: null },
                                },
                              })
                            }
                          >
                            {uploadingFile
                              ? 'Eliminando foto…'
                              : 'Eliminar foto'}
                          </button>
                        )}
                      </div>
                    </details>
                  </div>
                </div>
                <div className="pt-4 form-group-header">
                  <label className="">Código de Referencia:</label>
                  <button
                    className={
                      hover
                        ? 'Label Label--success py-2 px-3 my-2 color-bg-success color-fg-default ml-2 ml-md-0'
                        : 'Label Label--success py-2 px-3 my-2 color-bg-transparent ml-2 ml-md-0 '
                    }
                    onMouseEnter={() => {
                      setHover(!hover);
                    }}
                    onMouseLeave={() => {
                      setHover(!hover);
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(actor._id);
                      addToast('Código copiado al portapapeles', {
                        appearance: 'success',
                        autoDismiss: true,
                      });
                    }}
                  >
                    {actor._id}
                  </button>
                  <p className="note">
                    Cuando invites a un agente a registrarse en nexun, pídele
                    que incorpore este código en el formulario de registro. En
                    cuanto este haga su primera venta, recibirás 250€
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
        <input
          type="file"
          id="avatar_upload"
          className="manual-file-chooser width-full ml-0"
          accept="image/*"
          onChange={handleProfilePictureChange}
          disabled={uploadingFile}
          hidden
        />
      </div>
    </div>
  );
}
