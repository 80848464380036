import { Policy, HomeHeader, HomeFooter, ShapeDivider } from 'components';

export default function PolicyRoute() {
  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle">
      <div className="container-lg p-responsive width-full">
        <HomeHeader />
      </div>
      <ShapeDivider placement="bottom" />
      <Policy />
      <ShapeDivider placement="top" />
      <HomeFooter />
    </div>
  );
}
