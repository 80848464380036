import { HomeHeader, HomeFooter, ShapeDivider } from 'components';
import { MegaphoneIcon, MailIcon } from '@primer/octicons-react';
import { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';

export default function ContactRoute() {
  const { addToast } = useToasts();

  const [firstName, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isMessageValid, setIsMessageValid] = useState(false);
  const [isNameValid, setIsNameValid] = useState(false);

  useEffect(() => {
    if (firstName.length > 0) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  }, [firstName]);

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsEmailValid(emailRegex.test(email));
  }, [email]);

  useEffect(() => {
    if (message.length > 0) {
      setIsMessageValid(true);
    } else {
      setIsMessageValid(false);
    }
  }, [message]);

  const onSuccesSubmit = useCallback(
    (data) => {
      addToast(
        'Mensaje enviado correctamente. Te responderemos lo antes posible. ',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
      setName('');
      setEmail('');
      setMessage('');
    },
    [addToast],
  );

  const onErrorSubmit = useCallback(
    (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: false });
    },
    [addToast],
  );

  // DELETE THIS LINES JUST TO TEST THE FORM ON THE FRONTEND
  const loading = false;
  function submitForm() {}

  // TODO: MUTATION TO SEND EMAIL

  // const [submitForm, { loading }] = useMutation(
  // MUTATION_NAME,
  //   {
  //     onCompleted: onSuccesSubmit,
  //     onError: onErrorSubmit,
  //   },
  // );

  const handleSubmitContactForm = (e) => {
    e.preventDefault();
    submitForm({
      variables: {
        firstName,
        email,
        message,
      },
    });
  };

  return (
    <div className="d-flex flex-column flex-1 color-bg-default dark-text container-lg width-full">
      <div className="container-lg width-full">
        <HomeHeader />
      </div>
      <div className="color-bg-subtle">
        <ShapeDivider placement="bottom" />
        <div className="color-bg-default">
          <div className="container-lg p-responsive width-full">
            <div className="blankslate blankslate-large">
              <h3 className="blankslate-heading h1 mb-3">
                ¡Te estamos esperando!
              </h3>
              <p className="f4 text-bold">
                Mantente en contacto con nosotros para obtener más información
              </p>
              <p className="f4 mt-2">
                Queremos escucharte. Envíanos tus comentarios, dudas o
                sugerencias.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg p-responsive py-5 width-full width-md-auto">
        <div className="Box Box--spacious p-lg-5 pb-2 color-shadow-large p-responsive">
          {/* TODO: MAKE A REQUEST FORM */}
          <form onSubmit={handleSubmitContactForm}>
            <div className="form-group ">
              <div className="form-group-header">
                <label>Nombre</label>
              </div>
              <div className="form-group-body">
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Example"
                  id="example-text"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-header">
                <label>Correo eléctronico</label>
              </div>
              <div className="form-group-body">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@mail.com"
                  id="example-text"
                />
              </div>
            </div>

            <div className="form-group">
              <div className="form-group-header">
                <label>Mensaje</label>
              </div>
              <div className="form-group-body">
                <textarea
                  className="form-control"
                  id="example-textarea"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="d-flex flex-justify-center">
              <button
                className="btn-mktg btn-small-mktg mr-3"
                type="submit"
                disabled={!isEmailValid || !isMessageValid || !isNameValid}
              >
                {loading ? 'Enviando...' : 'Enviar'}
              </button>
            </div>
          </form>
        </div>

        <p className="f4 text-center pt-8 pb-2 note">
          También puedes contactarnos a través de otros canales:
        </p>
        <div className="d-flex flex-row">
          <div className="col-6 d-flex flex-column">
            <div className="CircleBadge CircleBadge--small color-bg-done-muted color-fg-default mx-auto">
              <MegaphoneIcon size={24} />
            </div>
            <p className="f3 text-center pt-2">(+34) 911 978 581</p>
          </div>
          <div className="col-6 d-flex flex-justify-center flex-column">
            <div className="CircleBadge CircleBadge--small color-bg-done-muted color-fg-default mx-auto">
              <MailIcon size={24} />
            </div>
            <p className="f3 text-center pt-2">info@nexun.es</p>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
}
