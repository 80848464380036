import {
  StarIcon,
  SearchIcon,
  FileDirectoryIcon,
} from '@primer/octicons-react';
import { NavLink, Outlet } from 'react-router-dom';

export default function PropertiesLayout() {
  // TODO: TAKE COUNTER OF MYPROPERTIS AND FAVORITES
  const myProperties = 0;
  const favorites = 0;

  return (
    <div>
      <div className="Layout Layout--flowRow-until-md Layout--sidebarPosition-start Layout--sidebarPosition-flowRow-start">
        <div className="Layout-sidebar">
          <div
            className="position-md-sticky color-bg-default rounded-2 border"
            style={{ top: 24 }}
          >
            <ul className="ActionList">
              <NavLink
                to="/properties"
                className={({ isActive }) =>
                  `ActionList-item ActionList-content ActionList-content--visual16 ${
                    isActive ? 'ActionList-item--navActive' : ''
                  }`
                }
                end
              >
                <span className="ActionList-item-visual ActionList-item-visual--leading">
                  <SearchIcon />
                </span>
                <span className="ActionList-item-label">Explorar</span>
              </NavLink>
              <li className="ActionList-item">
                <NavLink
                  to="/properties/my-properties"
                  className={({ isActive }) =>
                    `ActionList-item ActionList-content ActionList-content--visual16 ${
                      isActive ? 'ActionList-item--navActive' : ''
                    }`
                  }
                >
                  <span className="ActionList-item-visual ActionList-item-visual--leading">
                    <FileDirectoryIcon />
                  </span>
                  <span className="ActionList-item-label">
                    Asignadas{' '}
                    <span className="Counter ml-1">{myProperties}</span>
                  </span>
                </NavLink>
              </li>
              <li className="ActionList-item">
                <NavLink
                  to="/properties/favorites"
                  className={({ isActive }) =>
                    `ActionList-item ActionList-content ActionList-content--visual16 ${
                      isActive ? 'ActionList-item--navActive' : ''
                    }`
                  }
                >
                  <span className="ActionList-item-visual ActionList-item-visual--leading">
                    <StarIcon />
                  </span>
                  <span className="ActionList-item-label">
                    Favoritos<span className="Counter ml-1">{favorites}</span>
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="Layout-main flex-1">
          <div className="Layout-main">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
