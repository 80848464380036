import { useCallback, useEffect, useState } from 'react';
import {
  GoogleMap,
  MarkerF,
  CircleF,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { AlertIcon, InfoIcon, LocationIcon } from '@primer/octicons-react';

export default function MapPlatform({
  location = { lat: 0, lng: 0 },
  range = 0,
  zoom = 30,
  useCurrentLocation = false,
  onChange,
  disableInput = true,
  height = '90%',
  width = '100%',
}) {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [unableToGetCurrentLocation, setUnableToGetCurrentLocation] =
    useState(false);

  const getPlace = useCallback(
    (placeId) => {
      // Get place details from Google Maps API using place_id
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
      service.getDetails({ placeId }, (place, status) => {
        if (status === 'OK') {
          onChange({
            placeId: place.place_id,
            formattedAddress: place.formatted_address,
            geometry: {
              location: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              },
              viewport: {
                northeast: {
                  lat: place.geometry.viewport.getNorthEast().lat(),
                  lng: place.geometry.viewport.getNorthEast().lng(),
                },
                southwest: {
                  lat: place.geometry.viewport.getSouthWest().lat(),
                  lng: place.geometry.viewport.getSouthWest().lng(),
                },
              },
            },
            name: place.name,
          });
        }
      });
    },
    [onChange],
  );

  useEffect(() => {
    if (useCurrentLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (p) => {
          const position = {
            lat: p.coords.latitude,
            lng: p.coords.longitude,
          };
          setCurrentLocation(position);
          // Get place_id from Google Maps API using lat and lng
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: position }, (results, status) => {
            if (status === 'OK') {
              getPlace(results[0].place_id);
            }
          });
        },
        (error) => {
          console.error(error);
          setUnableToGetCurrentLocation(true);
        },
      );
    }
  }, [useCurrentLocation, getPlace]);

  if (useCurrentLocation && !currentLocation) {
    return (
      <div
        className={unableToGetCurrentLocation ? 'flash flash-warn' : 'flash'}
      >
        <div className="d-flex flex-items-center">
          {unableToGetCurrentLocation ? <AlertIcon /> : <InfoIcon />}
          <p>
            {unableToGetCurrentLocation
              ? 'No hemos podido obtener su ubicación actual. Por favor, verifique que tenga activada la ubicación en su dispositivo.'
              : 'Obteniendo ubicación actual...'}
          </p>
        </div>
      </div>
    );
  }

  const handlePlacesChanged = () => {
    if (!autocomplete) return;
    const place = autocomplete.getPlaces()[0];
    if (!place) return;
    setCurrentLocation({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    getPlace(place.place_id);
  };

  return (
    <GoogleMap
      center={currentLocation || location}
      zoom={zoom}
      mapContainerClassName="with-full"
      mapContainerStyle={{
        height: height,
        width: width,
        borderRadius: '6px',
        borderColor: '#e1e4e8',
        borderStyle: 'solid',
        borderWidth: '1.4px',
        zIndex: 0,
      }}
      options={{
        mapTypeControl: true,
        fullscreenControl: false,
        panControl: false,
        rotateControl: false,
        scaleControl: true,
        streetViewControl: false,
        controlSize: 24,
      }}
    >
      <MarkerF position={currentLocation || location} />
    </GoogleMap>
  );
}

MapPlatform.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  range: PropTypes.number,
  zoom: PropTypes.number,
  useCurrentLocation: PropTypes.bool,
  // onChange: PropTypes.func.isRequired,
  disableInput: PropTypes.bool,
};
