import { useQuery } from '@apollo/client';
import { VISITS_QUERY } from 'apollo/queries';

import ReactDataGrid from '@inovua/reactdatagrid-community';

import { CheckIcon, XIcon } from '@primer/octicons-react';

export default function AllVisitsRoute() {
  const defaultColumns = [
    {
      name: 'date',
      defaultFlex: 1,
      header: 'Fecha Visita',
    },
    {
      name: 'agent',
      defaultFlex: 1,
      header: 'Agente',
      render: ({ value }) => {
        if (value === null) {
          return <span className="p-2 color-fg-danger">Sin asignar</span>;
        } else {
          var url = '/users/agents/' + value._id;
          return (
            <a href={url} className="Link--primary p-2">
              <span>{value.fullName ? value.fullName : 'Desconocido'}</span>
            </a>
          );
        }
      },
    },
    {
      name: 'isConfirmedByAgent',
      defaultFLex: 1,
      header: '',
      defaultWidth: 50,
      render: ({ value }) => {
        return (
          <>
            {value && (
              <span className="p-2 color-fg-success">
                <CheckIcon size={16} />
              </span>
            )}
            {!value && (
              <span className="p-2 color-fg-danger">
                <XIcon size={16} />
              </span>
            )}
          </>
        );
      },
    },
    {
      name: 'property',
      defaultFlex: 1,
      header: 'Propiedad',
      render: ({ value }) => {
        if (value === null) {
          return <span>Desconocido</span>;
        }
        var url = '/properties/' + value._id;
        return (
          <a href={url} className="Link--primary">
            <span>{value.title ? value.title : 'Desconocido'}</span>
          </a>
        );
      },
    },
    {
      name: 'buyer',
      defaultFlex: 1,
      header: 'Cliente',
      render: ({ value }) => {
        if (value === null) {
          return <span>Desconocido</span>;
        }
        var url = '/users/clients/' + value._id;
        return (
          <a href={url} className="Link--primary">
            {' '}
            <span>
              {value.firstName
                ? value.firstName + ' ' + (value.lastName ? value.lastName : '')
                : 'Desconocido'}
            </span>{' '}
          </a>
        );
      },
    },
    {
      name: 'isConfirmedByBuyer',
      defaultFLex: 1,
      header: '',
      defaultWidth: 50,
      render: ({ value }) => {
        return (
          <>
            {value && (
              <span className="p-2 color-fg-success">
                <CheckIcon size={16} />
              </span>
            )}
            {!value && (
              <span className="p-2 color-fg-danger">
                <XIcon size={16} />
              </span>
            )}
          </>
        );
      },
    },
    {
      name: '_id',
      defaultFlex: 1,
      header: 'Acciones',
      render: ({ value }) => {
        if (value === undefined) {
          return <span>Desconocido</span>;
        }
        var url = '/visits/' + value;
        return (
          <div className="text-center">
            <a href={url}>Gestionar</a>
          </div>
        );
      },
    },
  ];
  const gridStyle = { minHeight: 1100 };

  const { data, loading } = useQuery(VISITS_QUERY);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <ReactDataGrid
          idVisit="_id"
          dataSource={data.propertyVisits}
          columns={defaultColumns}
          style={gridStyle}
          multiSelect={false}
        />
      </div>
    </div>
  );
}
