import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../context/auth.context';

export default function RequireVerificatedEmail({
  children,
  isRequired = true,
}) {
  const { actor } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  /**
   * If the user email is not verificated and the route is protected,
   * redirect to the verify-email page.
   */
  if (isRequired && !actor.isEmailVerificated) {
    return (
      <Navigate
        to={{
          pathname: '/verify-email',
          search: searchParams.toString(),
        }}
        state={{ from: location }}
        replace
      />
    );
  }

  /**
   * If the user email is verificated and the route is not protected,
   * redirect to the home page.
   */
  if (!isRequired && actor.isEmailVerificated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
