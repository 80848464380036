import { useAuth } from 'context/auth.context';
import { NavLink } from 'react-router-dom';
import { ArrowRightIcon } from '@primer/octicons-react';
import { useQuery } from '@apollo/client';
import { VISITS_FILTER_QUERY } from 'apollo/queries';
import { useEffect, useState } from 'react';

export default function MyVisitsRoute() {
  const { actor } = useAuth();
  const [myVisits, setMyVisits] = useState([]);

  const { data, loading } = useQuery(VISITS_FILTER_QUERY, {
    variables: {
      filter: {
        owner: actor._id,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setMyVisits(data?.propertyVisits);
    } else {
      setMyVisits([]);
    }
  }, [data]);

  const stateVisit = (visit) => {
    let state = '';
    if (
      visit.isConfirmedByAgent === true &&
      visit.isConfirmedByBuyer === false
    ) {
      state = 'Confirma tu cita';
    } else if (
      visit.isConfirmedByAgent === true &&
      visit.isConfirmedByBuyer === false
    ) {
      state = 'Confirma tu cita';
    } else if (
      visit.isConfirmedByAgent === false &&
      visit.isConfirmedByBuyer === true &&
      visit.isRefusedByAgent === false
    ) {
      state = 'Esperando confirmación del agente';
    } else if (
      visit.isRefusedByAgent === true &&
      visit.isConfirmedByBuyer === false &&
      visit.isRefusedByBuyer === false
    ) {
      state = 'Cita cancelada por el agente.';
    } else if (
      visit.isConfirmedByAgent === false &&
      visit.isConfirmedByBuyer === false &&
      visit.isRefusedByBuyer === true
    ) {
      state = 'Cita cancelada por ti';
    }
    return state;
  };

  if (loading) return <div>Cargando...</div>;

  return (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead">
          <h2 className="Subhead-heading">Mis visitas</h2>
          <div className="Subhead-description">
            Gestiona tus visitas en Nexun Inmobiliaria
          </div>
        </div>
        <ul className="ActionList p-0 m-0 ActionList--divided">
          {myVisits.length > 0 &&
            myVisits.map((visit) => (
              <NavLink
                className="ActionList-item d-flex p-2 flex-items-center no-underline"
                to={'/visits/' + visit._id}
              >
                <div className="flex-auto min-width-0">
                  <div className="d-flex flex-items-center">
                    <div className="flex-auto min-width-0">
                      <a
                        className="Link--primary"
                        href={`/visits/${visit._id}`}
                        style={{ fontWeight: 600 }}
                      >
                        {visit.property.title} -{' '}
                        {new Date(visit.date).toLocaleDateString('es-ES')}{' '}
                      </a>
                      <div className="text-small text-gray">
                        {visit.property.place.formattedAddress}{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Link--primary">{stateVisit(visit)}</div>
                <div className="ml-3">
                  <div className="d-flex flex-items-center">
                    <div className="flex-auto min-width-0">
                      <div className="text-small text-gray">
                        <ArrowRightIcon size={16} />
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
        </ul>
        {myVisits.length === 0 && (
          <p className="color-fg-muted text-center">
            En el momento en el soliciten una visita, la recibirás en esta
            sección.
          </p>
        )}
      </div>
    </div>
  );
}
