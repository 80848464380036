import { HomeHeader, HomeFooter, ShapeDivider } from 'components';

export default function BenefitsRoute() {
  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle">
      <div className="container-lg p-responsive width-full ">
        <HomeHeader />
      </div>
      <ShapeDivider placement="bottom" />
      <div className="color-bg-default">
        <div className="container-lg p-responsive width-full">
          <div className="blankslate blankslate-large">
            <h2 className="h5-mktg lh-condensed mb-0 color-fg-subtle">
              Beneficios
            </h2>
            <h3 className="blankslate-heading h3-mktg mb-0">
              ¿Qué obtienes al ser parte de nexun?
            </h3>
            <h4 className="f4-mktg mt-4 color-fg-muted">
              En esta guía explicamos qué necesitas para convertirte en agente
              nexun y los beneficios que recibirás
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="container-lg p-responsive width-full">
        <div className="py-8 px-4">
          <h2 className="f1-light lh-condensed mb-0 text-center">
            Multiplica las oportunidades de ganar honorarios
          </h2>
          <h4 className="f4-mktg mt-4 color-fg-muted text-center">
            Somos una empresa de economía colaborativa por lo que cada agente
            tiene la posibilidad de aportar valor en todo el proceso de venta o
            en una parte. Puedes especializarte en lo que mejor se te da o ir
            cambiando tu participación dependiendo de la operación. Tenemos tres
            roles diferenciados:
          </h4>
          <div className="d-flex flex-column flex-md-row flex-wrap flex-md-nowrap flex-md-items-center flex-md-justify-center mt-6">
            <div className="Box col-12 mb-2 mb-md-0 col-md-4 text-center p-4 mx-2 color-shadow-large">
              <h3 className="f1-light pb-3">Captador</h3>
              <p className="color-fg-muted">
                Trae viviendas a la plataforma y obtén honorarios por cada
                propiedad que vendamos.
              </p>
            </div>
            <div className="Box col-12 mb-2 mb-md-0 col-md-4 text-center p-4 mx-2 color-shadow-large">
              <h3 className="f1-light pb-3">Prescriptor</h3>
              <p className="color-fg-muted">
                Trae clientes a la plataforma y obtén honorarios por cada
                propiedad que vendamos.
              </p>
            </div>
            <div className="Box col-12 mb-2 mb-md-0 col-md-4 text-center p-4 mx-2 color-shadow-large">
              <h3 className="f1-light pb-3">Vendedor</h3>
              <p className="color-fg-muted">
                Vende propiedades y obtén honorarios por cada propiedad que
                vendamos.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ShapeDivider placement="bottom" />
      <div className="color-bg-default">
        <div className="container-xl p-responsive color-bg-default">
          <div className="py-8 px-4">
            <h2 className="f0-light lh-condensed mb-0  text-center">
              Flexibilidad horaria de trabajo
            </h2>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Dinos qué días y en qué horarios estás disponible, así la
              herramienta solo te contactará cuando tú decidas. Podrás adaptar
              tu horario a tu vida y decidir cuánto dinero quieres ganar. Puedes
              dedicarte profesionalmente o simplemente darte de alta para
              conseguir unos ingresos extra.
            </h4>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Y recuerda que cuanto más tiempo le dediques más posibilidades
              tienes de participar en operaciones que acaben cerrándose y
              supongan importantes honorarios.
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="width-full">
        <div className="container-xl p-responsive width-full">
          <div className="py-8 px-4">
            <h2 className="f0-light lh-condensed mb-0 text-center">
              Eliges en qué zona quieres trabajar
            </h2>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Con nuestra herramienta puedes elegir en qué zona quieres trabajar
              e incluso delimitar el área en el que te gustaría moverte, te
              pedirá cuántos kilómetros estás dispuesto a desplazarte para
              enseñar una vivienda.
            </h4>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              En nexun entendemos que la cercanía a tu domicilio te hace la vida
              más cómoda. Aunque depende de ti: ¿al lado de casa, en tu barrio o
              en todo el municipio?
            </h4>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Concilia tu vida y tu profesión sin perder oportunidades.
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="bottom" />
      <div className="color-bg-default">
        <div className="container-xl p-responsive width-full color-bg-default">
          <div className="py-8 px-4">
            <h2 className="f0-light lh-condensed mb-0 text-center">
              Ingresos de los más altos del mercado
            </h2>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Te ofrecemos una de las honorarios más altas del sector. Puedes
              ganar hasta el 80% de honorarios por una venta, solo va a depender
              que en qué grado participas. Además, te dejamos elegir cómo vas a
              participar en la operación. Premiamos la fidelidad, la formación
              continua y que nos ayudes a crecer. La vinculación a nexun te
              aumenta aún más la honorarios.
            </h4>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Todos los detalles y ejemplos los encontrarás en tu site una vez
              te hayas registrado.
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="width-full">
        <div className="container-xl p-responsive width-full">
          <div className="py-8 px-4">
            <h2 className="f0-light lh-condensed mb-0  text-center">
              Pagos en menos de 24 horas
            </h2>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Una vez haya cobrado nexun la honorarios, preséntanos la factura y
              en menos de 24 horas te haremos el ingreso.
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="bottom" />
      <div className="color-bg-default">
        <div className="container-xl p-responsive width-full color-bg-default">
          <div className="py-8 px-4">
            <h2 className="f0-light lh-condensed mb-0 text-center">
              Desarrollo profesional ad hoc
            </h2>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Crece con nosotros a tu propio ritmo. Tú marcas tu propio
              desarrollo personal. Puedes impulsar tu carrera y si lo necesitas,
              durante un tiempo, ralentizar la colaboración. En nexun entendemos
              que nuestros agentes necesitan ajustar sus ritmos a sus momentos
              vitales.
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="width-full">
        <div className="container-xl p-responsive width-full">
          <div className="py-8 px-4">
            <h2 className="f0-light lh-condensed mb-0 text-center">
              Súmate a la Innovación
            </h2>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Nexun pone la tecnología de tu lado y lleva la economía
              colaborativa a la venta de viviendas. Tienes la oportunidad de
              formar parte de una nueva manera de participar y mejorar tus
              resultados, más ventas en menos tiempo.
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="bottom" />
      <div className="color-bg-default">
        <div className="container-xl p-responsive width-full color-bg-default">
          <div className="py-8 px-4">
            <h2 className="f0-light lh-condensed mb-0 text-center">
              Ganar invitando a agentes
            </h2>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Queremos tenerte a ti y que nos recomiendes personas como tú.
              Ganaras dinero extra por cada agente que traigas.
            </h4>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="width-full">
        <div className="container-xl p-responsive width-full">
          <div className="py-8 px-4">
            <h2 className="f0-light lh-condensed mb-0 text-center">
              Un procedimiento sencillo
            </h2>
            <h4 className="f4-mktg mt-4 color-fg-muted text-center">
              Es muy fácil ser agente de nexun. Con solo darte de alta podrás
            </h4>
            <ul className="list-style-none">
              <li className="py-2">
                <h4 className="f4-mktg mt-4 mx-5 color-fg-muted text-center">
                  <span className="text-bold">Recibir asignaciones </span>
                  para enseñar una casa a un cliente, te adjudicaremos una
                  visita para que enseñes una vivienda y cierres una venta.
                </h4>
              </li>
              <li className="py-2">
                <h4 className="f4-mktg mt-4 mx-5 color-fg-muted text-center">
                  <span className="text-bold">Captar viviendas </span>y
                  subirlas: Publica en la plataforma una vivienda en venta y si
                  se cierra la operación a través de nexun, te llevas una
                  honorarios. Ten en cuenta que multiplicarías las opciones de
                  venta al permitir que otros agentes pudieran cerrar la
                  operación.
                </h4>
              </li>
              <li className="py-2">
                <h4 className="f4-mktg mt-4 mx-5 color-fg-muted text-center">
                  <span className="text-bold">Traer compradores </span>a la
                  plataforma: Si nos presentas a un cliente interesado en un
                  inmueble de nexun, solo por traerlo y haberle informado
                  previamente, te llevas honorarios si compra.
                </h4>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <HomeFooter />
    </div>
  );
}
