import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Popper } from '@mui/material';
import { useDebounce } from 'use-debounce';

export default function AddressInput({
  className,
  value,
  onChange,
  placeholder,
  required,
  autoFocus,
  disabled,
}) {
  const [predictions, setPredictions] = useState([]);
  const [query, setQuery] = useState(value?.formattedAddress || '');
  const [debouncedQuery] = useDebounce(query, 250);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placesService, autoCompleteService] = useMemo(
    () => [
      new window.google.maps.places.PlacesService(
        document.createElement('div'),
      ),
      new window.google.maps.places.AutocompleteService(),
    ],
    [],
  );

  useEffect(() => {
    if (!debouncedQuery.trim().length) {
      setPredictions([]);
      return;
    }

    const fetchPredictions = async () => {
      try {
        const predictions = await new Promise((resolve, reject) => {
          autoCompleteService.getPlacePredictions(
            {
              input: debouncedQuery,
              types: ['address'],
            },
            (predictions, status) => {
              if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                reject(status);
              }
              resolve(predictions);
            },
          );
        });
        setPredictions(
          predictions.map((prediction) => ({
            placeId: prediction.place_id,
            name: prediction.structured_formatting.main_text,
            formattedAddress: prediction.description,
          })),
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchPredictions();
  }, [autoCompleteService, debouncedQuery]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setQuery(value);
    setAnchorEl(value.trim().length > 0 ? event.target : null);
  };

  const handlePredictionClick = async (prediction) => {
    setQuery(prediction.formattedAddress);
    setAnchorEl(null);
    // Get place details
    const place = await new Promise((resolve, reject) => {
      placesService.getDetails(
        { placeId: prediction.placeId },
        (place, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            reject(status);
          }
          resolve(place);
        },
      );
    });
    onChange({
      placeId: place.place_id,
      formattedAddress: place.formatted_address,
      geometry: {
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        viewport: {
          northeast: {
            lat: place.geometry.viewport.getNorthEast().lat(),
            lng: place.geometry.viewport.getNorthEast().lng(),
          },
          southwest: {
            lat: place.geometry.viewport.getSouthWest().lat(),
            lng: place.geometry.viewport.getSouthWest().lng(),
          },
        },
      },
      name: place.name,
    });
  };

  return (
    <div className="position-relative">
      <input
        type="text"
        className={className}
        value={query}
        onChange={handleInputChange}
        placeholder={placeholder}
        required={required}
        autoFocus={autoFocus}
        disabled={disabled}
      />
      <Popper
        open={Boolean(anchorEl) && debouncedQuery.trim().length > 0}
        anchorEl={anchorEl}
        placement="bottom-start"
        className="autocomplete-results"
        disablePortal
      >
        {predictions.map((prediction, index) => (
          <div
            key={prediction.placeId}
            role="button"
            className="autocomplete-item px-3 py-2"
            tabIndex={index}
            onClick={() => handlePredictionClick(prediction)}
          >
            {prediction.formattedAddress}
          </div>
        ))}
        {predictions.length === 0 && (
          <div className="autocomplete-item px-3 py-2">
            No hay coincidencias
          </div>
        )}
      </Popper>
    </div>
  );
}

AddressInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    formattedAddress: PropTypes.string,
    geometry: PropTypes.shape({
      location: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      viewport: PropTypes.shape({
        northeast: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
        southwest: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      }),
    }),
    name: PropTypes.string,
    placeId: PropTypes.string,
  }),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};
