export default function NotificationRoute() {
  return (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead">
          <h2 className="Subhead-heading">Notificaciones</h2>
          <div className="Subhead-description">
            <p>Configura las notificaciones que quieres recibir</p>
          </div>
        </div>
        <form className="mt-3">
          <div className="col-12 col-md-12">
            <ul className="pb-4">
              <li className="ActionList-sectionDivider">
                <h3 className="ActionList-sectionDivider-title" id="group-id">
                  Web
                </h3>
                <span className="ActionList-item-description">
                  Las notificaciones web se muestran en la parte superior de tu
                  navegador. Pueden incluir sonidos y vibraciones.
                </span>
              </li>
              <ul
                className="ActionList ActionList--divided"
                aria-labelledby="group-id"
              >
                <li className="ActionList-item">
                  <span className="ActionList-content">
                    <span className="ActionList-item-descriptionWrap ActionList-item-descriptionWrap--inline">
                      <input
                        className="mr-2"
                        type="checkbox"
                        aria-describedby="help-text-for-checkbox"
                      />
                      <span className="ActionList-item-label">Propiedades</span>
                      <span className="ActionList-item-description">
                        Te avisaremos cuando haya nuevas propiedades en tu zona
                        de trabajo.
                      </span>
                    </span>
                  </span>
                </li>
                <li className="ActionList-item">
                  <span className="ActionList-content">
                    <span className="ActionList-item-descriptionWrap ActionList-item-descriptionWrap--inline">
                      <input
                        className="mr-2"
                        type="checkbox"
                        aria-describedby="help-text-for-checkbox"
                      />
                      <span className="ActionList-item-label">Dossiers</span>
                      <span className="ActionList-item-description">
                        Te avisaremos cuando haya nuevos documentos de
                        propiedades que hayas guardado.
                      </span>
                    </span>
                  </span>
                </li>
                <li className="ActionList-item">
                  <span className="ActionList-content">
                    <span className="ActionList-item-descriptionWrap ActionList-item-descriptionWrap--inline">
                      <input
                        className="mr-2"
                        type="checkbox"
                        aria-describedby="help-text-for-checkbox"
                      />
                      <span className="ActionList-item-label">Contacto</span>
                      <span className="ActionList-item-description">
                        Te avisaremos cuando haya una solicitud de contacto de
                        un cliente en ti.
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
            </ul>
            <ul>
              <li className="ActionList-sectionDivider">
                <h3 className="ActionList-sectionDivider-title" id="group-id">
                  Correo
                </h3>
                <span className="ActionList-item-description">
                  Es posible que recibas notificaciones por correo electrónico
                  en la bandeja de spam. Asegúrate de que no estén bloqueadas.
                </span>
              </li>
              <ul
                className="ActionList ActionList--divided"
                aria-labelledby="group-id"
              >
                <li className="ActionList-item">
                  <span className="ActionList-content">
                    <span className="ActionList-item-descriptionWrap ActionList-item-descriptionWrap--inline">
                      <input
                        className="mr-2"
                        type="checkbox"
                        aria-describedby="help-text-for-checkbox"
                      />
                      <span className="ActionList-item-label">Propiedades</span>
                      <span className="ActionList-item-description">
                        Te avisaremos cuando haya nuevas propiedades en tu zona
                        de trabajo.
                      </span>
                    </span>
                  </span>
                </li>
                <li className="ActionList-item">
                  <span className="ActionList-content">
                    <span className="ActionList-item-descriptionWrap ActionList-item-descriptionWrap--inline">
                      <input
                        className="mr-2"
                        type="checkbox"
                        aria-describedby="help-text-for-checkbox"
                      />
                      <span className="ActionList-item-label">Dossiers</span>
                      <span className="ActionList-item-description">
                        Te avisaremos cuando haya nuevos documentos de
                        propiedades que hayas guardado.
                      </span>
                    </span>
                  </span>
                </li>
                <li className="ActionList-item">
                  <span className="ActionList-content">
                    <span className="ActionList-item-descriptionWrap ActionList-item-descriptionWrap--inline">
                      <input
                        className="mr-2"
                        type="checkbox"
                        aria-describedby="help-text-for-checkbox"
                      />
                      <span className="ActionList-item-label">Contacto</span>
                      <span className="ActionList-item-description">
                        Te avisaremos cuando haya una solicitud de contacto de
                        un cliente en ti.
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
            </ul>
            <div className="text-center">
              <button className="btn btn-primary mt-3">Actualizar</button>
              <div className="note pt-3">
                Sólo recibirás las notificaciones que hayas activado. Puedes
                cambiar esta configuración en cualquier momento.
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
